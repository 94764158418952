import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import axios from "axios";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { formatScore } from '../HelperFunctions'
import { loadStore } from "./AdminStoresPage";

function ProjectScores({ projectScore: projectScoreProp, storeId }) {
	const backendUrl = useSelector((state) => state.backendUrl);
	const [globalAverage, setGlobalAverage] = useState(0);
	const [projectRegion, setProjectRegion] = useState("");
	const [projectScore, setProjectScore] = useState(0);
	const [projectDesigner, setProjectDesigner] = useState(0);
	const [regionalAverage, setRegionalAverage] = useState("");
	const [designerAverage, setDesignerAverage] = useState("");

	useEffect(() => {
		//get region for 'this' store
		loadStore(storeId, store => {
			setProjectRegion(store.region);
			setProjectScore(Math.round(store.totalScore));
			setProjectDesigner(store.architectId);

			var gotRegion = store.region;
			var gotDesignerId = store.architectId;
			// console.log("gotRegion");
			// console.log(gotRegion);
			// console.log("gotDesignerId");
			// console.log(gotDesignerId);

			if (gotRegion) {
				//get global scores
				axios.get(`${backendUrl}/globalStoreScores`)
					.then(function (response) {
						//console.log("globalStoreScores");
						// console.log(response.data.regionalScores)

						setGlobalAverage(Math.round((response.data.globalAverage) * 100) / 100);

						var regionalOnly = response.data.regionalScores;
						var regionalCheck = regionalOnly.filter((regional) => regional.name === gotRegion)
						setRegionalAverage(Math.round((regionalCheck[0].averageScore) * 100) / 100);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
			else {
				setRegionalAverage("NA");
			}

			//get designer scores
			if (gotDesignerId) {
				axios.get(`${backendUrl}/scoresByArchitect/${gotDesignerId}`)
					.then(function (response) {
						//console.log("designerscores");
						//console.log(response.data);

						setDesignerAverage(Math.round((response.data.averageStoreScore) * 100) / 100);
					})
					.catch(function (error) {
						console.log(error);
					});
			} else {
				setDesignerAverage("NA");
			}
		}, error => {
			console.log(error)
		})

	}, []);


	return (
		<>
			<Container>
				<Row className="bg-rejuvenate-100 d-flex justify-content-center">
					<Col md={{ span: 10, offset: 1 }} className="m-0 py-3">
						<Table borderless className="m-0">
							<tbody>
								<tr>
									<td className="col-3 text-muted">Global average</td>
									<td className="col-3">{formatScore(globalAverage)}</td>
									<td className="col-3 text-muted">Regional average</td>
									<td className="col-3">{formatScore(regionalAverage)}</td>
								</tr>
								<tr>
									<td className="col-3 text-muted">Designer average</td>
									<td className="col-3">{formatScore(designerAverage)}</td>
									<td className="col-3 text-muted" title="Total store score">Store Circularity Score</td>
									{/* <td className="col-3" title="Total store score">{projectScore}</td> */}
									<td className="col-3" title="Total store score">{formatScore(projectScoreProp)}</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default ProjectScores;