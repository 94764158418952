import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import '../../index.css';

function ProjectsNewMaterialModal(props) {
	const [validated, setValidated] = useState(false);

	const userId = useSelector((state) => state.userId);
	const userLoggedIn = useSelector((state) => state.userLoggedIn);
	const backendUrl = useSelector((state) => state.backendUrl);
	const storeId = useSelector((state) => state.storeId);

	const storeIdRead = storeId;

	const [lookups, setLookups] = useState(false);
	const [lookupParent, setLookupParent] = useState(false);
	const [lookupParents, setLookupParents] = useState([]);
	const [lookupParentsSelected, setLookupParentsSelected] = useState([]);

	// const [epicDataList, setEpicDataList] = useState([]);
	const [epicData, setEpicData] = useState([]);
	const [epicCategoryList, setEpicCategoryList] = useState([]);
	const [epicCategorySelected, setEpicCategorySelected] = useState([]);
	const [epicTypeList, setEpicTypeList] = useState([]);
	const [epicTypeSelected, setEpicTypeSelected] = useState([]);
	const [epicMaterialList, setEpicMaterialList] = useState([]);
	const [epicMaterialSelected, setEpicMaterialSelected] = useState([]);
	const [epicUnitList, setEpicUnitList] = useState([]);
	const [epicUnitSelected, setEpicUnitSelected] = useState([]);

	const [useEffectCount, setUseEffectCount] = useState(1);


	
	useEffect(() => {
		//setLookups({ ...storeDetailsState, [e.target.name]: inputValue });
		setLookups(lookupTable);

		if (useEffectCount > 1) {
			resetMaterialsData();
		} else {
			axios.get(`${backendUrl}/epicMaterials`)
			.then(function (response) {

                console.log(response.data)
				setEpicData(response.data)
				console.log(epicData)
				
                
			})
			.catch(function (error) {
				console.log(error);
			})
		}

		setUseEffectCount(useEffectCount + 1)

		console.log("epicUnitList")
		console.log(epicUnitList);

	}, [epicData]);


	const lookupTable = [
		{ name: "Flooring", parent: "Core structure & finish" },
		{ name: "Wall", parent: "Core structure & finish" },
		{ name: "Ceiling", parent: "Core structure & finish" },
		{ name: "Facade", parent: "Core structure & finish" },
		{ name: "POS", parent: "Module units" },
		{ name: "Product shelving", parent: "Module units" },
		{ name: "BOH", parent: "Module units" },
		{ name: "Basin", parent: "Module units" },
		{ name: "Furniture", parent: "Furniture & fittings" },
		{ name: "Lighting", parent: "Furniture & fittings" },
		{ name: "Fixtures", parent: "Furniture & fittings" },
	]


	const categories = [
		{ name: "Core structure & finish" },
		{ name: "Module units" },
		{ name: "Furniture & fittings" },
	]

	const categoryLookup = [
		{ name: "Flooring", parent: "Core structure & finish" },
		{ name: "Wall", parent: "Core structure & finish" },
		{ name: "Ceiling", parent: "Core structure & finish" },
		{ name: "Facade", parent: "Core structure & finish" },
		{ name: "POS", parent: "Module units" },
		{ name: "Product shelving", parent: "Module units" },
		{ name: "BOH", parent: "Module units" },
		{ name: "Basin", parent: "Module units" },
		{ name: "Furniture", parent: "Furniture & fittings" },
		{ name: "Lighting", parent: "Furniture & fittings" },
		{ name: "Fixtures", parent: "Furniture & fittings" },
	]

	let materialData = {
		materialTypeId: null,
		materialStatus: null,
		materialQuantity: null,
		functionalUnit : null,
		createdDate: null,
		supplierLocation: null,
		manufacturerLocation: null,
		supplierName: null,
		manufacturerName: null,
		originId: null,
		storeId: storeIdRead,
		rawMaterial: null,
		endOfLifeAssessment: null,
		category: null,
		subCategory: null,
		itemType: null,
		productCertification: null,
		roadTransportKms: null,
		railTransportKms: null,
		airTransportKms: null,
		oceanTransportKms: null,
		materialDescription: null,
		creatorId: parseInt(userId),
		primaryTransportMethod: null
	}

	const resetMaterialsData = (e) => {
		//set epic category list
		var tempCatList = [...new Set(epicData.map(item => item.category))]
		//setEpicCategoryList([...new Set(epicData.map(item => item.category))])
		setEpicCategoryList(tempCatList)

		console.log("epicCategoryList")
		console.log(epicCategoryList);

		//set epic type list
		var tempTypeList = [...new Set(epicData.map(item => item.type))]
		//setEpicCategoryList([...new Set(epicData.map(item => item.category))])
		setEpicTypeList(tempTypeList)

		console.log("epicTypeList")
		console.log(epicTypeList);

		//set epic type list
		var tempMaterialList = [...new Set(epicData.map(item => item.material))]
		//setEpicCategoryList([...new Set(epicData.map(item => item.category))])
		setEpicMaterialList(tempMaterialList)

		console.log("epicMaterialList")
		console.log(epicMaterialList);

		//set epic type list
		var tempUnitList = [...new Set(epicData.map(item => item.functionalUnit))]
		//setEpicCategoryList([...new Set(epicData.map(item => item.category))])
		setEpicUnitList(tempUnitList)
	}

	const filterCategory = (event) => {
		console.log("epicCategory selection");
		console.log([event]);

		if (event === "allItems") {
			console.log("all everything");
			setEpicCategorySelected(event);
			setEpicTypeSelected(null);
			setEpicMaterialSelected(null);
			setEpicUnitSelected(null);
		}
		else {
			//filter type list from category
			var checkTypes = epicData.filter((item) => item.category === event).map(data => data);
			console.log("checkTypes")
			console.log(checkTypes)
			//console.log(categoryLookup.filter((category) => category.name === e.target.value).map(data => data));

			//filter types
			var tempTypeList = [...new Set(checkTypes.map(item => item.type))];
			console.log(tempTypeList);
			//setEpicCategoryList([...new Set(epicData.map(item => item.category))])
			setEpicTypeList(tempTypeList)

			console.log("epicTypeList");
			console.log(epicTypeList);

			//filter materials
			var checkMaterials = epicData.filter((item) => item.category === event).map(data => data);
			console.log("checkMaterials")
			console.log(checkMaterials)
			var tempMaterialList = [...new Set(checkTypes.map(item => item.material))];

			//setEpicCategoryList([...new Set(epicData.map(item => item.category))])
			setEpicMaterialList(tempMaterialList);

			console.log("epicMaterialList");
			console.log(epicMaterialList);

			setEpicCategorySelected(event);
			setEpicTypeSelected(null);
			setEpicMaterialSelected(null);
			setEpicUnitSelected(null);
		}
	}

	const filterType = (event) => {
		console.log("epicType selection");
		console.log([event]);

		//filter type list from category
		var checkMaterials = epicData.filter((item) => item.type === event).map(data => data);
		console.log("checkMaterials")
		console.log(checkMaterials)
		//console.log(categoryLookup.filter((category) => category.name === e.target.value).map(data => data));

		//filter types
		var tempMaterialList = [...new Set(checkMaterials.map(item => item.material))];
		console.log(tempMaterialList);
		//setEpicCategoryList([...new Set(epicData.map(item => item.category))])
		setEpicMaterialList(tempMaterialList)

		console.log("epicMaterialList")
		console.log(epicMaterialList);

		setEpicTypeSelected(event);
		setEpicMaterialSelected(null);
		setEpicUnitSelected(null);
	}

	const filterMaterials = (event) => {
		console.log("epicMaterial selection");
		console.log([event]);


		//filter material item from  list
		var checkMaterial = epicData.filter((item) => item.material === event).map(data => data);

		console.log("checkMaterial")
		console.log(checkMaterial)
		//console.log(categoryLookup.filter((category) => category.name === e.target.value).map(data => data));

		//filter units
		var tempUnitList = [...new Set(checkMaterial.map(item => item.functionalUnit))];
		console.log(tempUnitList);
		setEpicUnitList(tempUnitList);

		console.log("epicUnitList")
		console.log(epicUnitList);

		setEpicMaterialSelected(event);
		setEpicCategorySelected(checkMaterial[0].category);
		setEpicTypeSelected(checkMaterial[0].type);
		setEpicUnitSelected(checkMaterial[0].functionalUnit);
	}

	const materialsLookups = (e) => {
		var inputName = [e.target.name];
		console.log(epicCategoryList)
		//console.log("epicCategoryList")

		if (e.target.id == "epicCategory") {
			filterCategory(e.target.value);
		}
		if (e.target.id == "epicType") {
			filterType(e.target.value)
		}
		if (e.target.id == "epicMaterial") {
			filterMaterials(e.target.value)
		}

	}

	const onChange = (e) => {
		console.log(lookups);
		//var inputName;
		var inputValue;
		var inputName = [e.target.name];
		inputValue = e.target.value;
		if (e.target.type == "number") {
			inputValue = parseInt(e.target.value);
			console.log(inputValue)
		}
		if (e.target.type == "checkbox") {
			console.log(e.target.type);
			console.log(e.target.checked);
			inputValue = e.target.checked;
			console.log(inputValue)
		}
		if (e.target.type == "select") {
			console.log(e.target.type);
			e.target.id = e.target.id;
		}
		if (e.target.id == "architectId") {
			console.log("i am archId");
			inputValue = parseInt(e.target.value);
			console.log(inputValue)
		}

		var checks = categoryLookup.filter((category) => category.name === e.target.value).map(data => data);
		console.log(categoryLookup.filter((category) => category.name === e.target.value).map(data => data));
		//setLookupParent(checks[0].parent)

		console.log(lookupParent);
		console.log("lookupParent");

		console.log("setting");
		console.log([...new Set(categoryLookup.map(item => item.parent))]);
		//console.log([...new Set(categoryLookup.map(item => item.parent))]);

		setLookupParents([...new Set(categoryLookup.map(item => item.parent))])
		setLookupParentsSelected(checks[0].parent)
		console.log(lookupParentsSelected)
	}

	const saveMaterialData = () => {
		console.log(saveMaterialData);
		axios.post(`${backendUrl}/materialInstance`, materialData)
			.then(function (response) {
				console.log(response);
				props.onHide();
			})
			.catch(function (error) {
				console.log(error);
			})
			.finally(function () {
				//props.onHide();
			})
	}

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			console.log("false");
			event.preventDefault();
			event.stopPropagation();
		}
		else if (form.checkValidity() === true) {
			event.preventDefault();
			event.stopPropagation();
			console.log("true");
			saveMaterialData();
		}

		setValidated(true);
	};

	console.log(materialData);

	return (
		<Form className="col-12 pt-5" autoComplete="off" validated={validated} noValidate onSubmit={handleSubmit}>
			<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
				<Form.Label column md={2}>
					Category lookup
				</Form.Label>
				<Col md={10}>
					<Form.Select
						required
						id="subCategory"
						name="subCategory"
						onChange={onChange}>
						<option selected value="" disabled="{true}">select</option>
						{lookupParents.map(item => {
							return item === lookupParentsSelected ?
								<option selected value={item}>{item}</option>
								:
								<option value={item}>{item}</option>
						}
						)}


					</Form.Select>
				</Col>
			</Form.Group>

			<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
				<Form.Label column md={2}>
					Category lookup
				</Form.Label>
				<Col md={10}>
					<Form.Select
						required
						id="subCategory"
						name="subCategory"
						onChange={onChange}>
						<option selected value="" disabled="{true}">Please Select</option>

						{categoryLookup.map((m) => (
							<option value={m.name}>{m.name}</option>
						))}
					</Form.Select>
				</Col>
			</Form.Group>

			<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
				<Form.Label column md={2}>
					epicCategory
				</Form.Label>
				<Col md={4}>
					<Form.Select
						required
						id="epicCategory"
						name="epicCategory"
						onChange={materialsLookups}>
						<option selected value="" disabled="{true}">Please Select</option>
						<option value="allItems">All items</option>
						{epicCategoryList.map(item => {
							return item === epicCategorySelected ?
								<option selected value={item}>{item}</option>
								:
								<option value={item}>{item}</option>
						}
						)}
					</Form.Select>
				</Col>

				<Form.Label column md={2}>
					epicType
				</Form.Label>
				<Col md={4}>
					<Form.Select
						required
						id="epicType"
						name="epicType"
						onChange={materialsLookups}>
						<option
							selected={`${epicTypeSelected ? "{true}" : "{false}"}`}
							value=""
							disabled="{true}">Please Select</option>
						{epicTypeList.map(item => {
							return item === epicTypeSelected ?
								<option selected value={item}>{item}</option>
								:
								<option value={item}>{item}</option>
						}
						)}
					</Form.Select>
				</Col>
			</Form.Group>


			<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
				<Form.Label column md={2}>
					epicMaterial
				</Form.Label>
				<Col md={10}>
					<Form.Select
						required
						id="epicMaterial"
						name="epicMaterial"
						onChange={materialsLookups}>
						<option value="" selected={`${epicMaterialSelected ? "{true}" : "{false}"}`} disabled="{true}">Please Select</option>
						{epicMaterialList.map(item => {
							return item === epicMaterialSelected ?
								<option selected value={item}>{item}</option>
								:
								<option value={item}>{item}</option>
						}
						)}
					</Form.Select>
				</Col>
			</Form.Group>

			<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
				<Form.Label column md={2}>
					quantity
				</Form.Label>
				<Col md={4}>
					<Form.Control
						required
						id="epicQuantity"
						name="epicQuantity"
					//onChange={ }
					>

					</Form.Control>
				</Col>
				<Form.Label column md={2}>
					epicUnits
				</Form.Label>
				<Col md={4}>
					<Form.Select
						required
						disabled
						id="epicUnits"
						name="epicUnits"
						onChange={materialsLookups}>
						{epicUnitList.map(item => {
							return item === epicUnitSelected ?
								<option selected value={item}>{item}</option>
								:
								<option value={item}>{item}</option>
						}
						)}
					</Form.Select>
				</Col>

			</Form.Group>



			<Container className="pt-5">
				<Row className="d-flex align-items-center justify-content-center">
					<Button
						type="submit"
						variant="outline-dark"
						className="ps-2 w-50"
					>
						<i className="bi bi-plus mx-2"></i>Add material
					</Button>
				</Row>
				<Row className="d-flex align-items-center justify-content-center pt-4">
					<Button
						size="sm"
						type="button"
						variant="outline-danger"
						onClick={props.onHide}
						className="pe-2 w-50"
					>
						Cancel
					</Button>
				</Row>
			</Container>
		</Form>
	);
}

export default ProjectsNewMaterialModal;