import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown'
import Header from "./Header";
import ProjectInformationBrief from "./ProjectInformationBrief";
import ProjectScores from "./ProjectScores";
import ProjectsNewMaterialModal from "./ProjectsNewMaterialModal";
//import ProjectsNewMaterialModal from "./ProjectsNewMaterialModalWizard";
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import './../index.css';
import { StoreNavBar } from './StoreNavBar'
import { deleteMaterialInstance, loadAllMaterials } from './AdminMaterialsPage'
import { config } from '../config'
import { loadStore } from "./AdminStoresPage";
import { calcTotalGhge, formatGhge, formatScore, formatUnitForDisplay } from "../HelperFunctions";
import { AccessControl } from "../AccessControl";

const CategoryMaterialsList = ({ title, user, store, materialInstances, handleMaterialInstActionClick }) => {
	return (
		<Accordion>
			<Accordion.Item>
				<Accordion.Header>
					<Col className="col-8">{title}</Col>
					{/* <Col className="col-4">14 Materials</Col> */}
				</Accordion.Header>
				<Accordion.Body>
					<Table id={title+"Table"}>
						<thead>
							<tr>
								<th>Raw Material</th>
								<th>Name</th>
								<th>Quantity</th>
								<th>Embodied GHGE<span style={{ marginLeft: 6, color: 'grey', fontWeight: 300 }}>(kgCO2e)</span></th>
								<th>Score</th>
								{AccessControl.storeCanEdit(user, store) && <th>Actions</th>}
							</tr>
						</thead>
						<tbody>
							{materialInstances ? (materialInstances.map((matInst) => {
								const totGhgeInfo = calcTotalGhge(matInst)

								return (
									<tr key={matInst.id}>
										<td className="col-3">{matInst.rawMaterial}</td>
										<td className="col-3">{matInst.itemType}</td>
										<td className="col-3">{matInst.materialQuantity} {formatUnitForDisplay(matInst.unit)}</td>
										{/* <td className="col-3">{matInst.embodiedGHGE + " -- " + matInst.A1A3CarbonFactor}</td> */}
										<td className="col-3" title={totGhgeInfo.calcInfo}>{totGhgeInfo.formatted}</td>
										<td className="col-3">{formatScore(matInst.totalScore)}</td>
										{AccessControl.storeCanEdit(user, store) && (
											<td>
												<Dropdown>
													<Dropdown.Toggle size="sm" variant="outline-dark" id={matInst.id+"Drop"} />
													<Dropdown.Menu>
														{/* <Dropdown.Item onClick={() => handleMaterialInstActionClick(matInst, "VIEW")}>View</Dropdown.Item> */}
														<Dropdown.Item onClick={() => handleMaterialInstActionClick(matInst, "EDIT")}>Edit</Dropdown.Item>
														<Dropdown.Item onClick={() => handleMaterialInstActionClick(matInst, "DELETE")}>Delete</Dropdown.Item>
														{ config.isDebugMode && <Dropdown.Item onClick={() => handleMaterialInstActionClick(matInst, "LOG")}>Log It</Dropdown.Item> }
													</Dropdown.Menu>
												</Dropdown>
											</td>
										)}
									</tr>
								)
							})) : ""}
						</tbody>
					</Table>

				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	)
}


function ProjectsAddMaterial({ store, setStore, user }) {
	const storeId = store.id
	const userId = user.id

	const backendUrl = useSelector((state) => state.backendUrl)

	const [materialInstToEdit, setMaterialInstToEdit] = useState(false)
	const [selectMaterialModal, setSelectMaterialModal] = useState(false)
	const [currentStoreMaterials, setCurrentStoreMaterials] = useState([])
	const [projectScore, setProjectScore] = useState(0)
	const [epicMaterials, setEpicMaterials] = useState([])

	// const history = useHistory()
	function handleClickSubmit() {
		//history.push('/designer/project/summary');
	}

	const materialSubCategories = [
		{ id: 1, name: "Flooring" },
		{ id: 2, name: "Wall" },
		{ id: 3, name: "Ceiling" },
		{ id: 4, name: "Facade" },
		{ id: 5, name: "POS" },
		{ id: 6, name: "Product shelving" },
		{ id: 7, name: "BOH" },
		{ id: 8, name: "Basin" },
		{ id: 9, name: "Furniture" },
		{ id: 10, name: "Lighting" },
		{ id: 11, name: "Fixtures" },
	];

	const getStoreScore = () => {
		// loadStore(storeId, store => {
		setStore(store)
		setProjectScore(Math.round(store.totalScore))
		// })
	}

	useEffect(() => {
		console.log('ProjectsAddMaterial.useEffect')
		getStoreScore() // just run once
	}, [ storeId ])

	function loadMaterialInstances() {
		axios.get(`${backendUrl}/materialInstancesByStore/${storeId}`)
		.then(function (response) {
			setCurrentStoreMaterials(response.data || [])
		});
	}

	useEffect(() => {
			loadMaterialInstances()
	}, []);

	// load the epic materials for the add material modal
	useEffect(() => loadAllMaterials(setEpicMaterials), [backendUrl])

	const showMaterialModal = (materialInst) => {
		// console.log('materialInst', materialInst)
		setMaterialInstToEdit(materialInst)
		setSelectMaterialModal(true)
	}

	

	const handleMaterialInstActionClick = (matInst, actionName) => {
		if (actionName === "EDIT") {
			showMaterialModal(matInst)
		} else if (actionName === "DELETE") {
			deleteMaterialInstance(matInst.id, () => {
				// showToastMsg("materialInstance deleted (" + materialInstance.material + ")")
				loadMaterialInstances()
			}, err => {
				// showToastMsg("Error deleting materialInstance (" + epicMaterial.material + "). Please try again.")
			})
		} else if (actionName === "LOG") {
			console.log('material instance', matInst)
		}
	}

	return (
		<>
			<Row className="py-3">
				<h4 className="border-bottom border-dark">Add Store Materials</h4>
			</Row>
			<Row>
				<ProjectInformationBrief
					storeId={storeId}
					/>
				<ProjectScores
					projectScore={projectScore}
					userId={userId}
					storeId={storeId}
				/>
				{/* <div>Project Score: {projectScore}</div> */}
			</Row>
			{AccessControl.storeCanAddMaterial(user, store) &&
				<Row>
					<Col md={12} className="d-flex align-items-center justify-content-center mt-5">
						<Button className="" variant="outline-dark" onClick={() => showMaterialModal()} > <i className="bi bi-plus mx-2"></i>Add new material</Button>
					</Col>
				</Row>
			}

			{materialSubCategories.map(subCat => {
					const materialInstances = currentStoreMaterials.filter((materialInst) => materialInst.subCategory === subCat.name)
					return (
						<Row key={subCat.id} className="py-3">
							<Col>
								<CategoryMaterialsList title={subCat.name} user={user} store={store} materialInstances={materialInstances} handleMaterialInstActionClick={handleMaterialInstActionClick} />
							</Col>
						</Row>
					)
				}
			)}

			{selectMaterialModal == false &&
				<Row>
					<Col md={12} className="d-flex align-items-end justify-content-end mt-2">
						<Button className="d-flex align-items-center justify-content-center px-5" variant="outline-dark" size="lg" onClick={handleClickSubmit}><h6 className="m-0 p-0">Submit Project</h6><i className="bi bi-chevron-right mx-2"></i></Button>
					</Col>
				</Row>
			}
			<ProjectsNewMaterialModal
				show={selectMaterialModal}
				materialInstance={materialInstToEdit}
				onHide={() => {setSelectMaterialModal(false); loadMaterialInstances(); getStoreScore()} }
				store={store}
				epicMaterials={epicMaterials}
			/>
			{/* <ProjectsSelectMaterialModal
			//show={selectMaterialModal}
			//onHide={() => setSelectMaterialModal(false)}
			//handleChange={(e) => setChecked([...checked, e.target.value])}
			/> */}
		</>
	);
}

export default ProjectsAddMaterial;