import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Store, getStoreScoreForScorecardType, loadStore, scorecardTypes } from "./AdminStoresPage";
import Table from 'react-bootstrap/Table'
import { loadUsers } from "./AdminUsersPage";

function ProjectReviewPage({ store, setStore, user }) {

	const [users, setUsers] = useState([])

	const storeId = store.id

	useEffect(() => {
        loadUsers(setUsers)
    }, [ store ])

	const displayNameFromUserId = (userId) => {
		let u = users.find(u => u.id === userId)
		return u && u.displayName
	}

	return (
		<>
			<Row className="py-3">
				<h4 className="border-bottom border-dark">Review Details</h4>
			</Row>
			<Container className="py-3">
				<Row>
					<Table responsive>
						<tbody>
							<tr>
								<td className="text-muted">Status</td>
								<td>{Store.getStatusTitle(store.storeStatus)}</td>
							</tr>
							<tr>
								<td className="text-muted">Scorecard Type</td>
								<td>{Store.getScorecardTypeTitle(store.scorecardType)}</td>
							</tr>
							<tr>
								<td className="text-muted">Store Creator</td>
								<td>{displayNameFromUserId(store.creatorId)}</td>
							</tr>
							<tr>
								<td className="text-muted">Review Required</td>
								<td>{store.reviewRequired ? "Yes" : "No"}</td>
							</tr>
							<tr>
								<td className="text-muted">Review Comment</td>
								<td>{store.reviewComment}</td>
							</tr>
						</tbody>
					</Table>
				</Row>
			</Container>

			<Row className="py-3">
				<h4 className="border-bottom border-dark">Designers</h4>
			</Row>
			<Container className="py-3">
				<Row>
					<Table responsive>
						<tbody>
							<tr>
								<td className="text-muted">Store Creator</td>
								<td>{displayNameFromUserId(store.creatorId)}</td>
							</tr>
							<tr>
								<td className="text-muted">Concept</td>
								<td style={{ fontWeight: store.scorecardType === Store.ScorecardType.CONCEPT ? 800 : undefined }}>{displayNameFromUserId(store.designerIdConcept)}</td>
							</tr>
							<tr>
								<td className="text-muted">As-Built</td>
								<td style={{ fontWeight: store.scorecardType === Store.ScorecardType.AS_BUILT ? 800 : undefined }}>{displayNameFromUserId(store.designerIdAsBuilt)}</td>
							</tr>
							<tr>
								<td className="text-muted">Decommissioned</td>
								<td style={{ fontWeight: store.scorecardType === Store.ScorecardType.DECOMMISSIONED ? 800 : undefined }}>{displayNameFromUserId(store.designerIdDecom)}</td>
							</tr>
						</tbody>
					</Table>
				</Row>
			</Container>
		</>
	)
}

export default ProjectReviewPage;