import React, { useEffect, useState } from "react";
import { Router, Switch, Route } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import { history } from "../helpers";
import { useSelector, useDispatch } from 'react-redux';
import { setBackendUrl } from '../store/actions';
import { config } from "../config"
import HomePage from "./HomePage";
import UserOverview from "./UserOverview";
import ProjectCreateNew from "./ProjectCreateNew";
import ProjectsAddMaterial from "./ProjectsAddMaterial";
import ProjectReviewPage from "./ProjectReviewPage";
import AllProjectsPage from "./AllProjectsPage";
import ProjectSummaryPage from "./ProjectSummaryPage";
import TableCertifications from './TableCertifications';
import TableGetMaterialsByStore from './TableGetMaterialsByStore';
import TableGetAllStores from './TableGetAllStores';
import DynamicForm from './tests/dynamicForm-useEffectLock';
import { AdminStoresPage } from './AdminStoresPage'
import { AdminMaterialsPage } from './AdminMaterialsPage'
import { AdminCalcsPage } from './AdminCalcsPage'
import { AdminUsersPage } from './AdminUsersPage'
import { PageNotFound } from './PageNotFound'
import { StoreContainerPage } from './StoreContainerPage'
import LoginForm from './Login'

const App = (props) => {

	const userId = useSelector((state) => state.userId)

	const dispatch = useDispatch();
	dispatch(setBackendUrl(config.backendUrl));

	const [title, setTitle] = useState("Aesop | Artemis | Sustainability Scorecard");

	useEffect(() => {
		// This will run when the page first loads and whenever the title changes
		document.title = title;
	}, [title]);

	// const changeTitle = (event) => {
	// 	setTitle(event.target.value);
	// };

	if (!userId) {
		return <LoginForm />
	}

	return (
		<Container className="col-sm-10 col-md-8 pb-5">
			<Router history={history}>
				<Switch>
					<Route exact path="/" component={HomePage} />
					<Route exact path="/login" component={LoginForm} />
					
					<Route exact path="/admin/stores" component={AdminStoresPage} />
					<Route exact path="/admin/users" component={AdminUsersPage} />
					<Route exact path="/admin/materials" component={AdminMaterialsPage} />
					{/* Dev route: */}
					<Route exact path="/admin/materials/:materialId/edit" component={AdminMaterialsPage} />
					<Route exact path="/admin/calcs" component={AdminCalcsPage} />

					<Route exact path="/overview" component={UserOverview} />
					<Route exact path="/certifications" component={TableCertifications} />
					<Route exact path="/designer/create" component={ProjectCreateNew} />
					<Route exact path="/designer/projects" component={AllProjectsPage} />
					<Route exact path="/designer/project/material" component={ProjectsAddMaterial} />
					<Route exact path="/designer/project/summary" component={ProjectSummaryPage} />
					<Route exact path="/reviewer/projects" component={AllProjectsPage} />
					<Route exact path="/reviewer/project/summary" component={ProjectSummaryPage} />
					<Route exact path="/contractor/project" component={ProjectSummaryPage} />
					<Route exact path="/contractor/project/summary" component={ProjectSummaryPage} />
					<Route exact path="/dynamic" component={DynamicForm} />
					<Route exact path="/TableGetAllStores" component={TableGetAllStores} />
					<Route exact path="/TableGetMaterialsByStore" component={TableGetMaterialsByStore} />

					{/* New routes for actual routing */}
					<Route exact path="/stores" component={AllProjectsPage} />
					<Route exact path="/stores/new" component={ProjectCreateNew} />
					<Route path="/stores/:storeId/view" component={StoreContainerPage} />
					<Route path="/stores/:storeId/edit" component={StoreContainerPage} />
					<Route path="/stores/:storeId/materials" component={StoreContainerPage} />
					<Route path="/stores/:storeId/review" component={StoreContainerPage} />
					<Route path="/stores/:storeId/lifecycle" component={StoreContainerPage} />

					<Route path="*">
						<PageNotFound />
					</Route>
				</Switch>
			</Router>
		</Container>
	);
};

export default App;
