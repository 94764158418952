import { configureStore, createReducer } from '@reduxjs/toolkit';
import logger from "redux-logger";

import {
    setUser,
    setStoreId,
    setMaterialId,
    setBackendUrl,
    setUserDisplayName,
    setUserId,
    setUserLoggedIn,
    setEditMode,
    setStoreLifecycleModalInfo,
} from './actions';

const initialState = {
    user: null,
    storeId: null,
    materialId: null,
    backendUrl: "",
    editMode: false,

    userDisplayName: "",
    userId: 0,
    userLoggedIn: "",

    storeLifecycleModalInfo: {
        show: false,
        title: "Title",
        textLines: ["aaa", "bbb"],
        buttonTitleOk: "Ok",
        buttonTitleCancel: "Cancel",
    }

};

const reducer = createReducer(initialState, (builder) => {
    builder
    .addCase(setUserDisplayName, (state, action) => {
        state.userDisplayName = action.payload;
    })
    .addCase(setUserId, (state, action) => {
        state.userId = action.payload;
    })
    .addCase(setUserLoggedIn, (state, action) => {
        state.userLoggedIn = action.payload;
    })
    .addCase(setUser, (state, action) => {
        state.user = action.payload;
    })
    .addCase(setStoreId, (state, action) => {
        state.storeId = action.payload;
    })
    .addCase(setEditMode, (state, action) => {
        state.editMode = action.payload;
    })
    .addCase(setMaterialId, (state, action) => {
        state.materialId = action.payload;
    })
    .addCase(setBackendUrl, (state, action) => {
        state.backendUrl = action.payload;
    })
    .addCase(setStoreLifecycleModalInfo, (state, action) => {
        state.storeLifecycleModalInfo = action.payload;
    })
});

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export default store;