import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import { history } from "../helpers";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const PageNotFound = () => {

	return (
		<Container>
			<Row style={{ minHeight: "90vh" }} className="h-100 d-flex justify-content-center align-items-center">
				<Col md={6}>
					<Row><h2>Page Not Found</h2></Row>
					<Row><Link to="/">Back to Home page</Link></Row>
				</Col>
			</Row>
		</Container>
	)
}
