import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from 'react-chartjs-2';
import { scaleColours, scaleValues } from './GlobalVars'

ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);

const ChartFilledSingleCircle = ({ percentage, label, delay }) => {
	console.log("percentage check")
	console.log(percentage)
	if (typeof percentage === "string") {
		// console.log("chart getting string")
		percentage = 1;
	}
	else {
		percentage = parseInt(percentage)
	}

	let colour = "#dddddd";

	if (percentage <= scaleValues.low) {
		console.log("percentage is low")
		colour = scaleColours.low;
		console.log(colour)
	}
	else if (percentage > scaleValues.low && percentage < scaleValues.medium) {
		colour = scaleColours.medium;
		console.log(colour)
	}
	else if (percentage >= scaleValues.medium) {
		colour = scaleColours.high;
		console.log(colour)
	}
	else {

	}

	const data = {
		labels: [
			label,
			label
		],
		datasets: [
			{
				label: '%',
				data: [percentage],
				backgroundColor: [
					colour,
					"#FFFFFF"
				],

				borderWidth: 0,
			},
		],
	};

	const options = {
		height: 150,
		width: 150,
		circumference: 360,
		responsive: false,
		maintainAspectRatio: true,
		cutout: 0,
		animation: {
			easing: "easeInCubic",
			duration: 3000,
			delay: delay
		},
		hover: {
			mode: null
		},
		plugins: {
			title: {
				display: false,
				text: label,
				position: 'bottom'
			},
			legend: {
				display: false,
			},
			tooltip: {
				enabled: false
			},
			datalabels: false

		},
	};

	return (
		<Doughnut options={options} data={data} />
	);
};

export default ChartFilledSingleCircle;