import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import axios from 'axios';
import Header from "./Header";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import { config } from "../config";

import Col from 'react-bootstrap/Col';

import ChartFilledSingleCircleGHG from "./ChartFilledSingleCircleGHG";

export const loadAllCalcParams = (setCalcParamList, setCalcParams) => {
    axios
        .get(`${config.backendUrl}/calcParams`)
        .then(response => response.data)
        .then(calcParams => {
            setCalcParamList(calcParams)

            // Set the initial values for the new value fields
            // const updatedCalcParamsNewValue = {...calcParamsNewValue}
            const updatedCalcParamsNewValue = {}
            calcParams.forEach(c => updatedCalcParamsNewValue[c.id] = c.currentValue)
            setCalcParams && setCalcParams(updatedCalcParamsNewValue)
        })
}

/**
 * Get calc lookup params from the server so that the
 * client and server can use the same values
 */
export const getCalcLookupData = (lookupName, onSuccess, onError) => {
    axios
        .get(`${config.backendUrl}//calcParams/lookups/${lookupName}`)
        .then(response => {
            onSuccess && onSuccess(response.data)
        })
        .catch(response => {
            console.error('Error getting calc lookup data', response)
            onError && onError(response && response.data)
        })
}

export const AdminCalcsPage = (props) => {

    const [calcParamList, setCalcParamList] = useState([])
    const [calcParamsNewValue, setCalcParamsNewValue] = useState({})

    const backendUrl = useSelector((state) => state.backendUrl);

    const updateCalcParam = (calcParam) => {
		axios.put(`${backendUrl}/calcParams/${calcParam.id}`, calcParam)
			.then(function (response) {
				console.log('calcParam saved', response)
                loadAllCalcParams(setCalcParamList, setCalcParamsNewValue)
			})
			.catch(function (error) {
				console.log('Error saving calcParam', error);
			})
	}

    useEffect(() => {
        console.log('AdminCalcsPage.useEffect')
        loadAllCalcParams(setCalcParamList, setCalcParamsNewValue)
    }, [backendUrl])

    const onChangeCalcParamNewValue = (calcParam, e) => {
        const updatedVal = Number(e.target.value)
        // console.log('Updating calc param new value', calcParam.name, "=>", updatedVal)

        const updatedCalcParamsNewValue = { ...calcParamsNewValue }
        updatedCalcParamsNewValue[calcParam.id] = updatedVal

        setCalcParamsNewValue(updatedCalcParamsNewValue)
    }

    const onClickSavedCalcParamValue = (calcParam, e) => {
        const updatedVal = calcParamsNewValue[calcParam.id]
        console.log('Updating calc param', calcParam.name, "=>", updatedVal)

        const updatedCalcParam = { ...calcParam }
        updatedCalcParam.currentValue = updatedVal
        updateCalcParam(updatedCalcParam)
    }

    return (
        <Container>
            <Row className="row h-10">
                <Header />
            </Row>
            <Row className="row h-90 p-2">
                <h3>Calculations</h3>
            </Row>

            <Row className="row h-90 p-2">
                <Table striped hover size="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            {/* <th>Description</th> */}
                            <th>Default Value</th>
                            <th>Current Value</th>
                            <th>New Value</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {calcParamList.map((calcParam, index) =>
                            (
                                <tr key={calcParam.id}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <span style={{}}>{calcParam.name}</span>
                                        <br/>
                                        <small style={{ color: 'grey' }}>{calcParam.description}</small>
                                    </td>
                                    {/* <td>{calcParam.description}</td> */}
                                    <td>
                                        <span>{calcParam.defaultValue}</span>
                                        <br/>
                                        <small style={{ color: 'grey' }}>{calcParam.units}</small>
                                    </td>
                                    <td>
                                        <span>{calcParam.currentValue}</span>
                                        <br/>
                                        <small style={{ color: 'grey' }}>{calcParam.units}</small>
                                    </td>
                                    <td>
                                        <Form>
                                            <Form.Control
                                                // required
                                                id="newValue"
                                                name="newValue"
                                                type="number"
                                                placeholder="Set value"
                                                defaultValue={calcParam.currentValue}
                                                // value={calcParamsNewValue[calcParam.id]}
                                                onChange={e => onChangeCalcParamNewValue(calcParam, e)}>
                                            </Form.Control>
                                        </Form>
                                    </td>
                                    {/* <td>{renderMaterialRowActions(mat)}</td> */}
                                    <td>
                                        {calcParam.currentValue !== calcParamsNewValue[calcParam.id] ?
                                        (
                                            <Button
                                            size="sm"
                                            variant="primary"
                                            disabled={calcParam.currentValue === calcParamsNewValue[calcParam.id]}
                                            onClick={e => onClickSavedCalcParamValue(calcParam, e)}>Save</Button>
                                        )
                                        : undefined}
                                    </td>
                                    {/* <td>{calcParam.currentValue}</td> */}
                                    {/* <td>{calcParamsNewValue[calcParam.id]}</td> */}
                                </tr>
                            )
                        )
                        }
                    </tbody>
                </Table>
            </Row>
        </Container>
    );
}