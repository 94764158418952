import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Store, copyStoreMaterialsFromScorecardType, getStoreScoreForScorecardType, loadStore, scorecardTypes } from "./AdminStoresPage";
import Table from 'react-bootstrap/Table'
import { createMaterialInstance, deleteMaterialInstance, getMaterialInstanceCount, getMaterialInstancesForStore, updateMaterialInstance } from "./AdminMaterialsPage";
import { formatGhge, formatRelImprov, formatScore } from "../HelperFunctions";
import { getCalcLookupData } from "./AdminCalcsPage";
import { config } from "../config";
import Form from 'react-bootstrap/Form'
import { endOfLifeAssessment } from "./ProjectsNewMaterialModal";
import { Col } from "react-bootstrap";
import { StoreLifecycleInfo } from "./StoreLifecycleInfo"
import { scaleColoursBG } from './GlobalVars'
import ChartDonutSingleVal from "./ChartDonutSingleVal";

const getEndOLifeScenarioScore = (endOLifeScenarioCalcParams, scorecardType) => {
	if (!endOLifeScenarioCalcParams || Object.keys(endOLifeScenarioCalcParams).length === 0) {
		return undefined
	}

	return endOLifeScenarioCalcParams[scorecardType]
}

const calcRelImprov = (matInfo, endOLifeScenarioCalcParams) => {
	let eolScenarioAsBuilt = matInfo.endOfLifeAssessment[Store.ScorecardType.AS_BUILT]
	let eolScenarioDecom = matInfo.endOfLifeAssessment[Store.ScorecardType.DECOMMISSIONED]

	if (!eolScenarioAsBuilt || !eolScenarioDecom) {
		return undefined
	}

	let scoreAsBuilt = getEndOLifeScenarioScore(endOLifeScenarioCalcParams, eolScenarioAsBuilt)
	let scoreDecom = getEndOLifeScenarioScore(endOLifeScenarioCalcParams, eolScenarioDecom)

	// console.log('calcRelImprov', scoreAsBuilt, scoreDecom, scoreAsBuilt - scoreDecom, endOLifeScenarioCalcParams)

	return (scoreDecom - scoreAsBuilt) // / 100 // make it a ratio
}

export const loadMaterialInstancesForScorecardType = (storeId, scorecardType, setMaterialInstancesByScorecard) => {
	getMaterialInstancesForStore(storeId, scorecardType, matList => {
		setMaterialInstancesByScorecard(mibsc => {
			const updatedMibsc = { ...mibsc }
			updatedMibsc[scorecardType] = matList
			return updatedMibsc
		})
	})
}

function ProjectPhasePage({ store, setStore, user }) {

	const [materialInstancesByScorecard, setMaterialInstancesByScorecard] = useState({})
	const [materialInfoDecomList, setMaterialInfoDecomList] = useState([])
	const [endOLifeScenarioCalcParams, setEndOLifeScenarioCalcParams] = useState({})
	const [isTableEditable, setIsTableEditable] = useState(true)

	const storeId = store.id

	// Run once
	useEffect(() => {
		// console.log('ProjectPhasePage.getCalcLookupData', storeId)
		getCalcLookupData("EndOfLifeSustainability", setEndOLifeScenarioCalcParams)
	}, [])

	useEffect(() => {
		// console.log('ProjectPhasePage.useEffect for materialInfoDecomList', Object.keys(materialInstancesByScorecard).length,  Object.keys(endOLifeScenarioCalcParams).length)
		setMaterialInfoDecomList(generateMaterialInfoDecomList())
	}, [endOLifeScenarioCalcParams, materialInstancesByScorecard])

	const generateMaterialInfoDecomList = () => {
		// console.log("generateMaterialInfoDecomList", Object.keys(materialInstancesByScorecard).length)

		const matInfoMap = {}

		/** Read the material instances into the local state for the given scorecardType */
		const readMatInst = (scorecardType, matInstForSt, infoId) => {
			// console.log("generateMaterialInfoDecomList st", st, matInstForSt.length)
			// const matInfo = matInstList.find(m => m.materialTypeId === matInstForSt.materialTypeId)

			let matInfo = matInfoMap[infoId]
			// TODO: MATCH BY parentMaterialInstanceId???

			if (!matInfo) {
				matInfo =
				{
					id: infoId,
					instance: {}, // materialInst per scorecardType
					// new material info object
					materialTypeId: matInstForSt.materialTypeId,
					itemType: matInstForSt.itemType,
					endOfLifeAssessment: {}
				}
			}

			matInfo.instance[scorecardType] = matInstForSt
			matInfo.endOfLifeAssessment[scorecardType] = matInstForSt.endOfLifeAssessment
			matInfo.relativeImprovement = calcRelImprov(matInfo, endOLifeScenarioCalcParams)

			matInfoMap[infoId] = matInfo
		}

		/// Read for all scorecardTypes in turn and match by given id
		// for (const st in materialInstancesByScorecard) {
		// 	const matInstForStList = materialInstancesByScorecard[st]

		// 	for (var i in matInstForStList) {
		// 		const matInstForSt = matInstForStList[i]

		// 		const infoId = matInstForSt.materialTypeId
		// 		// !matInstForSt.parentMaterialInstanceId || matInstForSt.parentMaterialInstanceId === 0
		// 		// ? matInstForSt.id // this is the top level parent
		// 		// : matInstForSt.parentMaterialInstanceId // this is a child

		// 		readMatInst(st, matInstForSt, infoId)
		// 	}
		// }

		materialInstancesByScorecard[Store.ScorecardType.AS_BUILT] && materialInstancesByScorecard[Store.ScorecardType.AS_BUILT].forEach(mat => readMatInst(Store.ScorecardType.AS_BUILT, mat, mat.id))
		materialInstancesByScorecard[Store.ScorecardType.DECOMMISSIONED] && materialInstancesByScorecard[Store.ScorecardType.DECOMMISSIONED].forEach(mat => readMatInst(Store.ScorecardType.DECOMMISSIONED, mat, mat.parentMaterialInstanceId))

		// console.log("matInfoMap", matInfoMap)

		return Object.values(matInfoMap)
	}

	const arraySum = (arr) => {
		if (arr.length === 0) {
			return undefined
		} else if (arr.length === 1) {
			return arr[0]
		} else {
			return arr.reduce((a, b) => a + b)
		}
	}

	const arrayAvg = (arr) => {
		if (arr.length === 0) {
			return undefined
		} else if (arr.length === 1) {
			return arr[0]
		} else {
			return arraySum(arr) / arr.length
		}
	}

	const calcAverageTotalScore = (materialInfoDecomList, scorecardType) => {
		const scores =
			materialInfoDecomList
				.map(matInfo => matInfo.endOfLifeAssessment[scorecardType])
				.filter(eol => !!eol)
				.map(eolScenario => getEndOLifeScenarioScore(endOLifeScenarioCalcParams, eolScenario))

		// console.log('calcAverageTotalScore', scorecardType, materialInfoDecomList.length, scores.length)

		return arrayAvg(scores)
	}

	// Average relative improvement
	const calcAverageRelImprovScore = (materialInfoDecomList) => {
		let listWithScores = materialInfoDecomList.filter(matInfo => matInfo.relativeImprovement)
		// console.log("calcAverageRelImprovScore", listWithScores.length)
		return arrayAvg(listWithScores.map(matInfo => matInfo.relativeImprovement))
	}

	const avgTotalScoreAsBuilt = calcAverageTotalScore(materialInfoDecomList, Store.ScorecardType.AS_BUILT)
	const avgTotalScoreDecom = calcAverageTotalScore(materialInfoDecomList, Store.ScorecardType.DECOMMISSIONED)
	const aveRelImprov = calcAverageRelImprovScore(materialInfoDecomList)

	// console.log("materialInfoDecomList", materialInfoDecomList)

	// update the local data first, then fire a DB update request
	const updateMatInstEolField = (scorecardType, matInstId, newEol) => {
		setMaterialInfoDecomList(matInfoDecomList => {
			const matInfoDecomListUpdated = [...matInfoDecomList]

			matInfoDecomListUpdated.map(matInfoItem => {
				// console.log("matInfoItem", matInfoItem)
				const matInstItem = matInfoItem.instance[scorecardType]

				if (matInstItem && matInstItem.id === matInstId) {
					// console.log("found mat inst", matInstItem)

					// just update it immediately
					const matInstItemUpdated = { ...matInstItem }
					matInstItemUpdated.endOfLifeAssessment = newEol
					updateMaterialInstance(matInstItemUpdated, scorecardType, () => {
						loadMaterialInstancesForScorecardType(storeId, scorecardType, setMaterialInstancesByScorecard)
					})

					const matInfoItemUpdated = { ...matInfoItem }
					matInfoItemUpdated.endOfLifeAssessment[scorecardType] = newEol

					return matInfoItemUpdated
				} else {
					return matInfoItem
				}
			})

			return matInfoDecomListUpdated
		})
	}

	const renderEolCell = (matInfo, scorecardType) => {

		if (isTableEditable && scorecardType === Store.ScorecardType.DECOMMISSIONED) {

			const onChangeEolDecom = (e) => {
				const newEol = e.target.value
				// console.log("onChangeEolDecom", newEol, scorecardType, matInfo)
				let matInst = matInfo.instance[scorecardType]

				if (!matInst) {
					// There's no material instance for this scorecardType,
					// so create a new material
					const matInstConcept = matInfo.instance[Store.ScorecardType.AS_BUILT]

					if (!matInstConcept) {
						// This should never happen as we always have an As-built material 
						// in this table that we are working in
						console.error("Could not find CONCEPT scorecardType material instance")
					} else {
						const parentId = matInstConcept.id
						matInst = { ...matInstConcept }
						matInst.scorecardType = scorecardType // Store.ScorecardType.DECOMMISSIONED
						matInst.parentMaterialInstanceId = matInst.id
						delete matInst.id // we want to create a new instance
						matInst.endOfLifeAssessment = newEol

						// console.log("Creating missing material instance", matInst, scorecardType)

						// add it to the local state so that the next update will cause it to be used
						// and write to the DB
						setMaterialInfoDecomList(matInfoDecomList => {
							const matInfoDecomListUpdated = [...matInfoDecomList]

							matInfoDecomListUpdated.map(matInfoItem => {
								if (matInfoItem.id === parentId) {
									const matInfoItemUpdated = { ...matInfoItem }
									matInfoItemUpdated.instance[scorecardType] = matInst
									matInfoItemUpdated.endOfLifeAssessment[scorecardType] = newEol

									return matInfoItemUpdated
								} else {
									return matInfoItem
								}
							})

							return matInfoDecomListUpdated
						})

						// Create the instance in the DB
						createMaterialInstance(matInst, scorecardType, () => {
							loadMaterialInstancesForScorecardType(storeId, scorecardType, setMaterialInstancesByScorecard)
						})
					}
				} else {
					const matInstId = matInst.id
					updateMatInstEolField(scorecardType, matInstId, newEol)
				}
			}

			return (
				<td>
					<Form.Select
						required
						// id="endOfLifeAssessment"
						name="endOfLifeAssessment"
						value={matInfo.endOfLifeAssessment[scorecardType] || ""}
						onChange={e => onChangeEolDecom(e)}>
						<option key="undefined" value="" disabled="{true}">Please Select</option>
						{endOfLifeAssessment.map((m) => (
							<option key={m.id} value={m.name}>{m.name}</option>
						))}
					</Form.Select>
					{/* <span>{matInfo.endOfLifeAssessment[scorecardType]}</span> */}
				</td>
			)
		}

		const scenario = getEndOLifeScenarioScore(endOLifeScenarioCalcParams, matInfo.endOfLifeAssessment[scorecardType])
		const val = matInfo.endOfLifeAssessment[scorecardType]

		return (
			<td title={`Score = ${scenario}`}>
				{val}
			</td>
		)
	}

	const renderRelImprovCell = (matInfo) => {
		return (
			<td className={`
			${matInfo.relativeImprovement <= -33 ? 'text-center ' + scaleColoursBG.high : ""}
			${matInfo.relativeImprovement > -33 && matInfo.relativeImprovement < 33 ? 'text-center ' + scaleColoursBG.medium : ""}
			${matInfo.relativeImprovement >= 33 ? 'text-center ' + scaleColoursBG.low : ""}
			`}>
				<small style={{cursor: 'pointer'}} data-toggle="tooltip" data-placement="left" title={formatRelImprov(matInfo.relativeImprovement) + "%"}>
					{/* {
					matInfo.relativeImprovement && formatRelImprov(matInfo.relativeImprovement)
				} */}

{/* 					{matInfo.relativeImprovement <= -33 ? <i class='bi bi-arrow-down-square-fill'></i> : ""}
					{matInfo.relativeImprovement > -33 && matInfo.relativeImprovement < 33 ? <i class='bi bi-dash-square-fill'></i> : ""}
					{matInfo.relativeImprovement >= 33 ? <i class='bi bi-arrow-up-square-fill'></i> : ""} */}
					{matInfo.relativeImprovement <= -33 ? "Worse" : ""}
					{matInfo.relativeImprovement > -33 && matInfo.relativeImprovement < 33 ? "About the same" : ""}
					{matInfo.relativeImprovement >= 33 ? "Better" : ""}
				</small>
			</td>
		)
	}

	const renderDecommissioningInfo = () => {

		if (materialInfoDecomList.length === 0) {
			return undefined
		}

		return (
			<>
				<Row className="py-3">
					<h4 className="border-bottom border-dark">Decommissioning Scorecard</h4>
				</Row>

				{/* <Row className="py-3 border-bottom border-dark">
					<Col md={8}>
						<h4 style={{display: 'inline-block'}}>Decommissioning Scorecard</h4>
					</Col>
					
					<Col md={4} className="align-self-end">
						<Form.Group as={Row}>
							<Form.Label column md={6}>Edit Mode</Form.Label>
							<Form.Check
								className="align-self-center col-md-6"
								type="switch"
								name="subCategories"
								checked={isTableEditable}
								onChange={e => setIsTableEditable(e.target.checked === true)}
								/>
						</Form.Group>
					</Col>
				</Row> */}


				<Row className="pb-4">
					<Col xs={6} md={4} className="m-0 p-1">
						<Row>
							<Col className="d-flex justify-content-center align-items-center">
								<h4 className="fadeText2s" style={{ position: "absolute" }}>{formatRelImprov(avgTotalScoreAsBuilt)}%</h4>
								<ChartDonutSingleVal
									percentage={formatRelImprov(avgTotalScoreAsBuilt)}
									//label={formatRelImprov(avgTotalScoreAsBuilt)}
									delay={1000 + (1 * 100)}
								/>
							</Col>
						</Row>
						<Row className="pt-2">
							<Col>
								<h6 className="text-center">Average total score As-Built</h6>
							</Col>
						</Row>
					</Col>
					<Col xs={6} md={4} className="m-0 p-1">
						<Row>
							<Col className="d-flex justify-content-center align-items-center">
								<h4 className="fadeText2s" style={{ position: "absolute" }}>{formatRelImprov(avgTotalScoreDecom)}%</h4>
								<ChartDonutSingleVal
									percentage={formatRelImprov(avgTotalScoreDecom)}
									//label={formatRelImprov(avgTotalScoreAsBuilt)}
									delay={1000 + (1 * 100)}
								/>
							</Col>
						</Row>
						<Row className="pt-2">
							<Col>
								<h6 className="text-center">Average total score Decommissioned</h6>
							</Col>
						</Row>
					</Col>
					<Col xs={6} md={4} className="m-0 p-1">
						<Row>
							<Col className="d-flex justify-content-center align-items-center">
								<h4 className="fadeText2s" style={{ position: "absolute" }}>{formatRelImprov(aveRelImprov)}%</h4>
								<ChartDonutSingleVal
									percentage={formatRelImprov(avgTotalScoreAsBuilt)}
									label={formatRelImprov(avgTotalScoreAsBuilt)}
									delay={1000 + (1 * 100)}
								/>
							</Col>
						</Row>
						<Row className="pt-2">
							<Col>
								<h6 className="text-center">Average relative improvement</h6>
							</Col>
						</Row>
					</Col>
				</Row>

				<Container className="py-3">
					<Row>
						<Table responsive>
							<thead className="text-center">
								<th>Material</th>
								{/* <th>Concept</th> */}
								<th>
									<span>As-Built</span>
									<br />
									<small>End of life design scenario</small>
								</th>
								<th>
									<span>Decommissioned</span>
									<br />
									<small>Actual end of life scenario</small>
								</th>
								<th>Relative Improvement</th>
								{/* <th>Actions</th> */}
							</thead>
							<tbody>
								{materialInfoDecomList.map(matInfo => {
									return (
										<tr className="align-middle" key={matInfo.id}>
											<td>{matInfo.itemType}</td>
											{/* <td>{matInfo.endOfLifeAssessment[Store.ScorecardType.CONCEPT]}</td> */}
											{renderEolCell(matInfo, Store.ScorecardType.AS_BUILT)}
											{renderEolCell(matInfo, Store.ScorecardType.DECOMMISSIONED)}
											{renderRelImprovCell(matInfo)}
										</tr>
									)
								})}
							</tbody>
						</Table>
					</Row>

				</Container>

				{/* 				<h4>Average total score As-Built = {formatRelImprov(avgTotalScoreAsBuilt)}%</h4>
				<h4>Average total score Decommissioned = {formatRelImprov(avgTotalScoreDecom)}%</h4>
				<h4>Average relative improvement = {formatRelImprov(aveRelImprov)}%</h4> */}
			</>
		)
	}

	return (
		<>
			{/* {config.isDebugMode ? ( */}
			<StoreLifecycleInfo
				storeId={storeId}
				setStore={setStore}
				materialInstancesByScorecard={materialInstancesByScorecard}
				setMaterialInstancesByScorecard={setMaterialInstancesByScorecard} />
			{/* ) : undefined } */}
			{renderDecommissioningInfo()}
		</>
	)
}

export default ProjectPhasePage;