import React from "react";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
//import Nav from 'react-bootstrap/Navbar';
//import Navbar from 'react-bootstrap/Navbar';
import { Navbar, Nav } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas'
import Container from 'react-bootstrap/Container';
import HelpModal from "./HelpModal";
import logo from './../style/images/aesop-logo.svg';
import { config } from "../config";
import { clearLocalStorage, clearSessionStorage } from "./Login";
import { AccessControl } from "../AccessControl";

function Header(props) {
	const user = useSelector((state) => state.user);
	const userId = useSelector((state) => state.userId);
	const [modalShow, setModalShow] = React.useState(false);
	const history = useHistory();

	// We have to do this because the router is ... weird
	const onClickLink = (path) => history.push(path)

	function handleLogout() {
		console.log("logging out");
		clearLocalStorage()
		clearSessionStorage()
		history.push('/');
		window.location.reload(false);
	}

	var adminPageLinks = undefined

	if (AccessControl.isAdmin(user)) {

		adminPageLinks = (
			<>
				<Offcanvas.Title>Admin</Offcanvas.Title>
				<Nav.Link onClick={() => onClickLink("/admin/users")}>Users</Nav.Link>
				<Nav.Link onClick={() => onClickLink("/admin/stores")}>Stores</Nav.Link>
				<Nav.Link onClick={() => onClickLink("/admin/materials")}>Materials</Nav.Link>
				<Nav.Link onClick={() => onClickLink("/admin/calcs")}>Calculations</Nav.Link>
				<hr/>
			</>
		)
	}

	return (
		<>
			<Container className="pt-3 pb-5" >
				<Navbar id="mainNav" bg="light" className="border-bottom border-black" expand={false}>
					<Container fluid>
						<Navbar.Brand
							style={{ cursor: 'pointer' }}
							onClick={() => onClickLink("/")}
						>
							<img
								src={logo}
								alt="Aesop"
								height="30"

							/>
						</Navbar.Brand>
						<Nav className="justify-content-center" activeKey="/">
							<Nav.Item className="d-none d-md-block">
								<Nav.Link onClick={() => onClickLink("/")}>Artemis | Sustainability Scorecard{config.isDebugMode && <span style={{ backgroundColor: 'blue', color: 'white', padding: 5, marginLeft: 10 }}>{user.role}</span>}</Nav.Link>
							</Nav.Item>
							<Nav.Item className="d-xs-block d-sm-block d-md-none">
								<Nav.Link onClick={() => onClickLink("/")}>Sustainability Scorecard</Nav.Link>
							</Nav.Item>
						</Nav>
						<Navbar.Toggle aria-controls="offcanvasNavbar" />
						<Navbar.Offcanvas
							id="offcanvasNavbar"
							aria-labelledby="offcanvasNavbarLabel"
							placement="end"
						>
							<Offcanvas.Header closeButton>
								<Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
							</Offcanvas.Header>
							<Offcanvas.Body>
								<Nav className="justify-content-end flex-grow-1 pe-3">
									<Nav.Link onClick={() => onClickLink("/")}>Home</Nav.Link>
									<Nav.Link onClick={() => onClickLink("/overview")}>Your Overview</Nav.Link>
									<Nav.Link onClick={() => onClickLink("/stores")}>All Projects</Nav.Link>
									<Nav.Link onClick={() => setModalShow(true)}>Help</Nav.Link>

									<hr/>

									{adminPageLinks}

									{(userId) ?
										<>
											<Navbar.Text className="header-text">
												<span className="text-muted">User: </span><span>{user.displayName}</span>
											</Navbar.Text>
											<Nav.Link onClick={handleLogout}>Logout</Nav.Link>
										</>
										: ""

									}

									{/* 		<Nav.Item><h5 className="mt-1 mb-3 text-end">Designer</h5></Nav.Item>
									<Nav.Link href="/designer">/designer</Nav.Link>
									<Nav.Link href="/designer/create">/designer/create</Nav.Link>
									<Nav.Link href="/designer/projects">/designer/projects</Nav.Link>
									<Nav.Link href="/designer/project/material">/designer/project/material</Nav.Link>
									<Nav.Link href="/designer/project/summary">/designer/project/summary</Nav.Link>
									<Nav.Item><h5 className="mt-1 mb-3 text-end">Reviewer</h5></Nav.Item>
									<Nav.Link href="/reviewer">/reviewer</Nav.Link>
									<Nav.Link href="/reviewer/project">/reviewer/project</Nav.Link>
									<Nav.Link href="/reviewer/project/summary">/reviewer/project/summary</Nav.Link>
									<Nav.Item><h5 className="mt-1 mb-3 text-end">Leadership</h5></Nav.Item>
									<Nav.Link href="/leadership">leadership</Nav.Link>
									<Nav.Item><h5 className="mt-1 mb-3 text-end">Contractor</h5></Nav.Item>
									<Nav.Link href="/contractor">contractor</Nav.Link>
									<Nav.Item><h5 className="mt-1 mb-3 text-end">Tests</h5></Nav.Item>
									<Nav.Link href="/certifications">TableCertifications</Nav.Link>
									<Nav.Link href="/TableGetAllStores">TableGetAllStores</Nav.Link>
									<Nav.Link href="/TableGetMaterialsByStore">TableGetMaterialsByStore</Nav.Link>
									<Nav.Link href="/login">Login</Nav.Link>
									<Nav.Link href="/progressBar">progressBar</Nav.Link> */}

									<p style={{color: 'lightgray'}}>{config.version}</p>
									{
										config.isDebugMode ? (
											<p style={{color: 'lightgray'}}>User Role: {user.role}</p>
										) : undefined
									}


								</Nav>
							</Offcanvas.Body>
						</Navbar.Offcanvas>
					</Container>
				</Navbar>
				<HelpModal
					show={modalShow}
					onHide={() => setModalShow(false)}
				/>
			</Container>
		</>
	);
}

export default Header;