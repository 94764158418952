import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import axios from 'axios';
import Header from "./Header";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table'
import Col from 'react-bootstrap/Col'
import { subCategories, units } from './ProjectsNewMaterialModal'
import { config } from '../config'
import { useParams } from 'react-router-dom'
import { ensureString, formatUnit } from "../HelperFunctions";

const isActiveFieldInfo = "Whether the material is shown in the UI to be selected for a store"

export const SUB_CATEGORY_SEPARATOR = '|' // for storing in a single DB field

const defaultFormMaterial = {
	dataSource: "Custom",
	material: "",
	subCategories: "",
	subCategoriesList: [],
	functionalUnit: "",
	massPerFunctionalUnit: 1,
	// embodiedGHGEPerUnit: "",
	// embodiedGHGEUnit: "",
	// GHGEUnitPerFunctionalUnit: "",
	// embodiedGHGE: "", // GHGE per functional unit
	// embodiedEnergy: "",
	// embodiedWater: "",
	isActive: true,
	moreInformationUrl: ""
}

export const loadAllMaterials = (setMaterialListFn) => {
	axios.get(`${config.backendUrl}/epicMaterials`)
		.then(function (response) {
			// split the subCategories field
			const materialsDbList = (response.data || []).map(mat => {
				mat.subCategoriesList = (mat.subCategories || "").split(SUB_CATEGORY_SEPARATOR)
				return mat
			})
			setMaterialListFn(materialsDbList)
		})
		.catch(function (error) {
			console.log(error);
		})
}

export const getMaterialInstanceCount = (storeId, scorecardType, onSuccess, onError) => {
	axios.get(`${config.backendUrl}/materialInstanceCount/${storeId}/${scorecardType}`)
		.then(function (response) {
			const count = response.data
			onSuccess && onSuccess(count)
		})
		.catch(function (response) {
			const error = response && response.data
			console.log(error);
			onError && onError(error)
		});
}

export const deleteMaterialInstance = (materialInstanceId, onSuccess, onError) => {
	axios.delete(`${config.backendUrl}/materialInstances/${materialInstanceId}`)
		.then(function (response) {
			onSuccess && onSuccess(response.data)
		})
		.catch(response => {
			console.error('Error deleting materialInstance', response)
			onError && onError(response && response.data)
		})
}

const cleanMaterialDataForDb = (materialData, storeScorecardType) => {
	const clean = {...materialData}
	delete clean["embodiedGHGE"] // from the SQL join, so not actually in db table

	// Convert string fields to string in case they were entered as numbers,
	// which JS will convert to a number and then the go server will error.
	clean.scorecardType = ensureString(clean.scorecardType)
	clean.materialStatus = ensureString(clean.materialStatus)
	clean.unit = ensureString(clean.unit)
	clean.createdDate = ensureString(clean.createdDate)
	clean.supplierLocation = ensureString(clean.supplierLocation)
	clean.manufacturerLocation = ensureString(clean.manufacturerLocation)
	clean.supplierName = ensureString(clean.supplierName)
	clean.manufacturerName = ensureString(clean.manufacturerName)
	clean.rawMaterial = ensureString(clean.rawMaterial)
	clean.endOfLifeAssessment = ensureString(clean.endOfLifeAssessment)
	clean.category = ensureString(clean.category)
	clean.subCategory = ensureString(clean.subCategory)
	clean.itemType = ensureString(clean.itemType)
	clean.productCertification = ensureString(clean.productCertification)
	clean.materialDescription = ensureString(clean.materialDescription)
	clean.circularityAssessment = ensureString(clean.circularityAssessment)
	clean.primaryTransportMethod = ensureString(clean.primaryTransportMethod)

	clean.scorecardType = storeScorecardType
	return clean
}

export const createMaterialInstance = (matInst, storeScorecardType, onSuccess, onError) => {
	axios.post(`${config.backendUrl}/materialInstance`, cleanMaterialDataForDb(matInst, storeScorecardType))
		.then(function (response) {
			onSuccess && onSuccess(response.data)
		})
		.catch(response => {
			console.error('Error creating materialInstance', response)
			onError && onError(response && response.data)
		})
}

export const updateMaterialInstance = (matInst, storeScorecardType, onSuccess, onError) => {
	axios.put(`${config.backendUrl}/materialInstances/${matInst.id}`, cleanMaterialDataForDb(matInst, storeScorecardType))
		.then(function (response) {
			onSuccess && onSuccess(response.data)
		})
		.catch(response => {
			console.error('Error updating materialInstance', response)
			onError && onError(response && response.data)
		})
}

/** Get all the material instances for a store. 
 * If the scorecardType is not provided, store.scorecardType is 
 * used to filter materials.
 */
export const getMaterialInstancesForStore = (storeId, scorecardType, onSuccess, onError) => {
	const url =
		scorecardType
		? `${config.backendUrl}/materialInstancesByStore/${storeId}/${scorecardType}`
		: `${config.backendUrl}/materialInstancesByStore/${storeId}`

	axios.get(url)
		.then(function (response) {
			const matInstList = response.data
			onSuccess && onSuccess(matInstList)
		})
		.catch(function (error) {
			console.log(error)
			onError && onError(error)
		});
}

const AddNewMaterialModal = ({ show, onHide, isEditMode, material, updateEpicMaterial, createEpicMaterial }) => {

	// const [filteredItemTypes, setFilteredItemTypes] = useState(materialItemTypes);
	const [subCategoryIsChecked, setSubCategoryIsChecked] = useState({});
	const [validated, setValidated] = useState(false);

	const updateSubCategoryFieldIsChecked = (subCategoryName, isChecked) => {
		var subCategoryIsCheckedUpdated = { ...subCategoryIsChecked }
		subCategoryIsCheckedUpdated[subCategoryName] = isChecked
		setSubCategoryIsChecked(subCategoryIsCheckedUpdated)
		// console.log('subCategoryIsCheckedUpdated', subCategoryIsCheckedUpdated)

		return subCategoryIsCheckedUpdated
	}

	// console.log('subCategoryIsChecked', subCategoryIsChecked)

	const [formMaterial, setFormMaterial] = useState(defaultFormMaterial)

	useEffect(() => {
		console.log('AddNewMaterialModal.useEffect', material)
		if (isEditMode === true && material) {
			// some old materials may not have all fields set, so set defaults
			const m = {
				...defaultFormMaterial,
				...material
			}
			setFormMaterial(m)
			var subCategoryIsCheckedUpdated = {}

			const subCats = material.subCategoriesList
			// console.log('subCats', subCats)
			subCats.forEach(sc => subCategoryIsCheckedUpdated[sc] = true)

			// console.log('subCategoryIsCheckedUpdated', subCategoryIsCheckedUpdated)
			setSubCategoryIsChecked(subCategoryIsCheckedUpdated)
		} else {
			setSubCategoryIsChecked({})
			setFormMaterial(defaultFormMaterial)
		}
	}, [isEditMode, material])

	/** ONLY CALL THIS ONCE PER RENDER CYCLE OR IN A ROW */
	const updateFormMaterialField = (fieldName, fieldValue) => {
		// console.log('updateFormMaterialField', fieldName, fieldValue)
		const updatedFormMaterial = { ...formMaterial }
		updatedFormMaterial[fieldName] = fieldValue
		// console.log('updatedFormMaterial', updatedFormMaterial)
		setFormMaterial(updatedFormMaterial)
	}

	const onChange = (e) => {
		const fieldName = e.target.name
		var fieldValue = e.target.value

		const updatedFormMaterial = { ...formMaterial }

		if (e.target.type === "number") {
			fieldValue = Number(fieldValue)
		} else if (fieldName === "subCategories") {
			console.log("subCategories val", fieldValue)
			// } else if (e.target.type == "switch") {
			// 	if (fieldValue === "on") {
			// 		fieldValue = true
			// 	} else if (fieldValue === "off") {
			// 		fieldValue = false
			// 	}
			// }
		} else if (e.target.type === "checkbox") {
			fieldValue = e.target.checked;
		}

		// if (fieldName === "subCategory") {
		// 	// updateMaterialDataField(fieldName, e.target.value)
		// 	//filteredItemTypes
		// 	setFilteredItemTypes(materialItemTypes.filter(item => item.subCategories && item.subCategories.indexOf(e.target.value) != -1) )
		// }

		// updateFormMaterialField(fieldName, fieldValue)
		updatedFormMaterial[fieldName] = fieldValue

		// if (fieldName === "embodiedGHGEPerUnit" || fieldName === "embodiedGHGEUnit" || fieldName === "GHGEUnitPerFunctionalUnit") {
		// 	// formMaterial.embodiedGHGEUnit !== formMaterial.functionalUnit
		// 	updatedFormMaterial["embodiedGHGE"] = updatedFormMaterial.embodiedGHGEPerUnit && updatedFormMaterial.density && updatedFormMaterial.embodiedGHGEPerUnit * updatedFormMaterial.density
		// }

		// console.log('updatedFormMaterial', updatedFormMaterial)
		setFormMaterial(updatedFormMaterial)
	}

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
			setValidated(true);
		}
		else if (form.checkValidity() === true) {
			event.preventDefault();
			event.stopPropagation();
			console.log("Creating DB material", formMaterial)
			if (isEditMode) {
				updateEpicMaterial(formMaterial)
			} else {
				createEpicMaterial(formMaterial)
			}
			setValidated(false);
		}
	};

	const onChangeSubCategory = (subCategory, e) => {
		// var fieldValue = e.target.value
		const isChecked = e.target.checked
		// console.log("onChangeSubCategory", subCategory, isChecked)

		const subCategoryIsCheckedUpdated = updateSubCategoryFieldIsChecked(subCategory.name, isChecked)

		// set the subcategories on the material
		let subCatsList =
			Object.keys(subCategoryIsCheckedUpdated)
				.filter(sc => subCategoryIsCheckedUpdated[sc])
				.map(sc => sc.replaceAll(SUB_CATEGORY_SEPARATOR, '-'))
				.filter(sc => sc.trim() !== "")

		// let subCatsJoined =
		// 	subCatsList
		// 		.join(SUB_CATEGORY_SEPARATOR)

		// console.log('subCatsList', subCatsList)
		// console.log('subCatsJoined', subCatsJoined)
		// updateFormMaterialField('subCategories', subCatsJoined) // this gets set when we save the material
		updateFormMaterialField('subCategoriesList', subCatsList)
	}

	return (
		<Modal
			show={show}
			onHide={onHide}
			size="lg"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>{isEditMode ? "Edit Database Material" : "New Database Material"}</Modal.Title>
			</Modal.Header>
			<Modal.Body>

				<Form className="col-12" autoComplete="off" validated={validated} noValidate onSubmit={handleSubmit}>

					<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
						<Form.Label column="true" md={3}>
							Name
						</Form.Label>
						<Col md={9}>
							<Form.Control
								required
								id="material"
								name="material"
								type="text"
								placeholder="Material name"
								value={formMaterial.material}
								onChange={onChange}>
							</Form.Control>
						</Col>
					</Form.Group>

					{/* <Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
						<Form.Label column="true" md={3}>
							Sub-Category (make checkboxes)
						</Form.Label>
						<Col md={9}>
							<Form.Select
								required
								id="subCategory"
								name="subCategory"
								onChange={onChange}>
								<option selected value="" disabled="{true}">Please Select</option>
								{subCategories.map((m) => (
									<option value={m.name}>{m.name}</option>
								))}
							</Form.Select>
						</Col>
					</Form.Group> */}

					<Form.Group as={Row} className="mb-md-3 d-flex">
						<Form.Label as="legend" column="true" xs={6} md={3}>
							Sub-Categories
						</Form.Label>
						<Col xs={12} md={9}>
							<Row xs={2} md={2}>
								{subCategories.map((sc, index) =>
									<Form.Group key={`subcat_${index}`} as={Row} className="align-items-center">
										<Col column="true" xs={3}>
											<Form.Check
												// custom
												// checked={storeDetailsState.bathroom || null} // if edit mode, load in value, if not, use default
												//required
												className="align-self-center"
												type="switch"
												name="subCategories"
												// id="subCategories"
												checked={subCategoryIsChecked[sc.name] === true}
												onChange={e => onChangeSubCategory(sc, e)}
											/>
										</Col>
										<Form.Label column="true" xs={9}>
											{sc.name}
										</Form.Label>
									</Form.Group>
								)}
							</Row>
						</Col>
					</Form.Group>

					{/* <Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
						<Form.Label column="true" md={3}>
							Item Type (Material)
						</Form.Label>
						<Col md={9}>
							<Form.Select
								required
								id="itemType"
								name="itemType"
								type="text"
								placeholder="Item Type"
								onChange={onChange}>
								<option selected value="" disabled="{true}">Please Select</option>
								{filteredItemTypes.map((m) => (
									<option value={m.material}>{m.material}</option>
								))}
							</Form.Select>
						</Col>
					</Form.Group> */}

					{/* Functional Unit */}
					<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
						<Form.Label column="true" md={3}>
							Functional Unit
						</Form.Label>
						<Col md={3}>
							<Form.Select
								required
								// disabled={true}
								id="functionalUnit"
								name="functionalUnit"
								value={formMaterial.functionalUnit}
								onChange={onChange}>
								<option value="" disabled="{true}">Select unit</option>
								{units.map((m) => (
									<option key={m.name} value={m.name}>{formatUnit(m.name)}</option>
								))}
							</Form.Select>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
						<Form.Label column="true" md={3}>
							GHGE per Functional Unit (A1 - A3)
						</Form.Label>
						<Col md={3}>
							<Form.Control
								required
								id="embodiedGHGE"
								name="embodiedGHGE"
								type="number"
								// min="0"
								// step="0.1"
								value={formMaterial.embodiedGHGE}
								onChange={onChange}>
							</Form.Control>
						</Col>
						<Form.Label column="true" md={4}>
							{`kgCO2e Per ${formatUnit(formMaterial.functionalUnit)}`}
						</Form.Label>
					</Form.Group>

					{formMaterial.functionalUnit !== "kg" ? (
						<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
							<Form.Label column="true" md={3}>
								Mass per Functional Unit
							</Form.Label>
							<Col md={3}>
								<Form.Control
									required
									// disabled={true}
									id="massPerFunctionalUnit"
									name="massPerFunctionalUnit"
									placeholder=""
									value={formMaterial.massPerFunctionalUnit}
									type="number"
									// min="0"
									// step="0.1"
									onChange={onChange}>
								</Form.Control>
							</Col>
							<Form.Label column="true" md={3}>
								{`kg/${formatUnit(formMaterial.functionalUnit)}`}
							</Form.Label>
						</Form.Group>
					): undefined}

					<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
						<Form.Label column="true" md={3}>
							Is Active
						</Form.Label>
						<Col md={4} title={isActiveFieldInfo}>
							<Form.Check
								// custom
								// checked={storeDetailsState.bathroom || null} // if edit mode, load in value, if not, use default
								className="align-self-center"
								type="switch"
								name="isActive"
								id="isActive"
								checked={formMaterial.isActive}
								onChange={onChange}
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
						<Form.Label column="true" md={3}>
							URL
						</Form.Label>
						<Col md={9}>
							<Form.Control
								id="moreInformationUrl"
								name="moreInformationUrl"
								type="text"
								placeholder="Enter web link URL for additional information if applicable"
								value={formMaterial.moreInformationUrl}
								onChange={onChange}>
							</Form.Control>
						</Col>
					</Form.Group>

					<Container className="pt-5">
						<Row className="d-flex align-items-center justify-content-center">
							<Button
								type="submit"
								variant="outline-dark"
								className="ps-2 w-50"
							//onClick={onAddButtonClick}
							>
								<i className="bi bi-plus mx-2"></i>{isEditMode ? "Update Material" : "Add Material"}
							</Button>
						</Row>
						<Row className="d-flex align-items-center justify-content-center pt-4">
							<Button
								size="sm"
								type="button"
								variant="outline-danger"
								onClick={onHide}
								className="pe-2 w-50"
							>
								Cancel
							</Button>
						</Row>
					</Container>
				</Form>

			</Modal.Body>
			{/*<Modal.Footer>
			
			</Modal.Footer>*/}


		</Modal >
	)
}

export const AdminMaterialsPage = (props) => {
	const [materialList, setMaterialList] = useState([])
	const [showAddNewMaterialModal, setShowAddNewMaterialModal] = useState(false)
	const [addNewMaterialModalIsEditMode, setAddNewMaterialModalIsEditMode] = useState(false)
	const [materialToEdit, setMaterialToEdit] = useState({})
	const [dataSourceFilterSelected, setDataSourceFilterSelected] = useState("All")

	const backendUrl = useSelector((state) => state.backendUrl);
	const { materialId } = useParams()

	useEffect(() => loadAllMaterials(materials => {
		setMaterialList(materials)

		// Dev route: show the edit material form on load if the
		// url is: /admin/materials/:materialId/edit
		const materialIdNum = Number(materialId)
		const material = materialId && materials.find(m => m.id === materialIdNum)

		if (material) {
			editMaterialUsingModal(material)
		}
	}), [backendUrl])

	const setSubCategoriesForDb = (epicMaterial) => {
		const epicMaterialDbRow = { ...epicMaterial } // copy object
		epicMaterialDbRow.subCategories = epicMaterialDbRow.subCategoriesList.join(SUB_CATEGORY_SEPARATOR)
		delete epicMaterialDbRow.subCategoriesList
		return epicMaterialDbRow
	}

	const updateEpicMaterial = (epicMaterial) => {
		const epicMaterialDbRow = setSubCategoriesForDb(epicMaterial)

		return axios.put(`${backendUrl}/epicMaterials/${epicMaterialDbRow.id}`, epicMaterialDbRow)
			.then(function (response) {
				console.log('epicMaterial updated', response)
				loadAllMaterials(setMaterialList)
			})
			.catch(function (error) {
				console.log('Error updating epicMaterial', error);
			})
	}

	const updateEpicMaterialForModal = (epicMaterial) => {
		updateEpicMaterial(epicMaterial).then(() => setShowAddNewMaterialModal(false))
	}

	const createEpicMaterial = (epicMaterial) => {
		const epicMaterialDbRow = setSubCategoriesForDb(epicMaterial)

		axios.post(`${backendUrl}/epicMaterial`, epicMaterialDbRow)
			.then(function (response) {
				console.log('epicMaterial created', response)
				// var stateResponse = response.data.id;
				setShowAddNewMaterialModal(false)
				loadAllMaterials(setMaterialList)
			})
			.catch(function (error) {
				console.log('Error creating epicMaterial', error);
			})
	}

	const deleteEpicMaterial = (epicMaterial) => {
		axios.delete(`${backendUrl}/epicMaterials/${epicMaterial.id}`)
			.then(function (response) {
				var data = response.data
				console.log("epicMaterial delete response", data)
				// showToastMsg("EpicMaterial deleted (" + epicMaterial.material + ")")
				loadAllMaterials(setMaterialList)
			})
			.catch(error => {
				console.log('Error deleting epicMaterial', error);
				// showToastMsg("Error deleting epicMaterial (" + epicMaterial.material + "). Please try again.")
			})
	}

	const editMaterialUsingModal = (mat) => {
		setMaterialToEdit(mat)
		setAddNewMaterialModalIsEditMode(true)
		setShowAddNewMaterialModal(true)
	}

	const handleActionClick = (mat, actionName) => {
		if (actionName === "TOGGLE_IS_ACTIVE") {
			var updateMaterial = { ...mat }
			updateMaterial.isActive = !updateMaterial.isActive
			updateEpicMaterial(updateMaterial)
		} else if (actionName === "EDIT") {
			editMaterialUsingModal(mat)
		} else if (actionName === "DELETE") {
			deleteEpicMaterial(mat)
		}
	}

	const renderMaterialRowActions = (mat) => {
		return (
			<Dropdown>
				<Dropdown.Toggle size="sm" variant="outline-dark" id="dropdown-basic">
				</Dropdown.Toggle>

				<Dropdown.Menu>
					{/* <Dropdown.Item onClick={() => handleActionClick(mat, "TOGGLE_IS_ACTIVE")}>Toggle IsActive</Dropdown.Item> */}
					{mat.dataSource === "Custom" ? <Dropdown.Item onClick={() => handleActionClick(mat, "EDIT")}>Edit</Dropdown.Item> : undefined}
					<Dropdown.Item onClick={() => handleActionClick(mat, "TOGGLE_IS_ACTIVE")}>{mat.isActive ? "Deactivate (Hide in UI)" : "Activate (Show in UI)"}</Dropdown.Item>
					{config.isDebugMode && mat.dataSource === "Custom" && <Dropdown.Item onClick={() => handleActionClick(mat, "DELETE")}>Delete</Dropdown.Item>}
				</Dropdown.Menu>
			</Dropdown>
		);
	}

	const handleOnClickAddNewMaterial = () => {
		setAddNewMaterialModalIsEditMode(false)
		setMaterialToEdit({})
		setShowAddNewMaterialModal(true)
	}

	const sourceFilters = [
		{ id: 1, value: "All" },
		{ id: 2, value: "EPIC" },
		{ id: 3, value: "Custom" },
	]

	return (
		<Container>
			<Row className="row h-10">
				<Header />
			</Row>
			<Row className="row h-90 p-2">
				<Row>
					<h3>Materials</h3>
					<Form.Group as={Row} className="mb-3 align-items-center">
						<Form.Label as="legend" column>
							Data Source Filter:
						</Form.Label>
						{sourceFilters.map(sourceFilter =>
							<Col key={sourceFilter.id}>
								<Form.Check
									type="radio"
									label={sourceFilter.value}
									name="sourceFilterRadio"
									id={`sourceFilterRadio${sourceFilter.id}`}
									value={sourceFilter.value}
									checked={dataSourceFilterSelected === sourceFilter.value}
									onChange={e => setDataSourceFilterSelected(e.target.value)}
								/>
							</Col>
						)}
					</Form.Group>
				</Row>
				<Button onClick={handleOnClickAddNewMaterial}>Add New Material</Button>
			</Row>

			<AddNewMaterialModal
				show={showAddNewMaterialModal}
				onHide={() => setShowAddNewMaterialModal(false)}
				isEditMode={addNewMaterialModalIsEditMode}
				createEpicMaterial={createEpicMaterial}
				updateEpicMaterial={updateEpicMaterialForModal}
				material={materialToEdit}
			/>

			<Row className="row h-90 p-2">
				<Table striped hover size="sm">
					<thead>
						<tr>
							<th>#</th>
							<th>Name</th>
							{/* <th>Category</th> */}
							{/* <th>Type</th> */}
							<th title={isActiveFieldInfo}>Active</th>
							<th title="The data source for this record. i.e. from the Epic DB, or created custom">Source</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{materialList
							.filter(mat => {
								if (dataSourceFilterSelected === 'All') {
									return true
								} else {
									return mat.dataSource === dataSourceFilterSelected
								}
							})
							.map((mat, index) =>
							(
								<tr key={mat.id}>
									<td>{index + 1}</td>
									<td>{mat.material}</td>
									{/* <td>{mat.category}</td> */}
									{/* <td>{mat.type}</td> */}
									<td>{mat.isActive ? "Yes" : "No"}</td>
									<td>{mat.dataSource}</td>
									<td>{renderMaterialRowActions(mat)}</td>
								</tr>
							)
							)
						}
					</tbody>
				</Table>
			</Row>
		</Container>
	);
}