import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import { Chart as ChartJS, LinearScale, Tooltip, Legend, Title } from 'chart.js';
// import { Bar } from 'react-chartjs-2';
import { TreemapController, TreemapElement } from "chartjs-chart-treemap";
import { scaleColoursFive, scaleColours, scaleValues } from './GlobalVars'
import HelpModal from './HelpModal';

ChartJS.register(LinearScale, Tooltip, Legend, Title, TreemapController, TreemapElement);

function Treemap(props) {
	const backendUrl = useSelector((state) => state.backendUrl);
	const canvas = useRef(null);
	const [treeData, setTreeData] = useState([]);

	useEffect(() => {
		//get global scores
		axios.get(`${backendUrl}/materialCounts`)
			.then(function (response) {
				//console.log(response.data)

				var tempMaterials = response.data.rawMaterialCounters;

				var treeObject = [
					{ label: "Retained", value: tempMaterials.retm, colour: scaleColoursFive[0] },
					{ label: "Reused", value: tempMaterials.reum, colour: scaleColoursFive[1] },
					{ label: "Recycled", value: tempMaterials.recm, colour: scaleColoursFive[2] },
					{ label: "Part recycled-Part virgin", value: tempMaterials.prpvg, colour: scaleColoursFive[3] },
					{ label: "Virgin Grade", value: tempMaterials.vgm, colour: scaleColoursFive[4] },
				];

				setTreeData(treeObject);
				//console.log(treeData)
			})
			.catch(function (error) {
				console.log(error);
			});


		const getColour = (ctx) => {
			if (ctx.type !== 'data') {
				return 'transparent';
			}
			const value = ctx.raw.v;
			const dataLabel = ctx.raw.g;

			var findLabel = treeData.filter((data) => data.label.includes(dataLabel));
			var labelColour = findLabel[0].colour;

			return labelColour
		}

		const getLabel = (ctx) => {
			const treeValue = ctx.raw.v;
			var treeIndex = ctx.dataset.data[ctx.dataIndex]._data._idx;
			var treeLabel = treeData[treeIndex].label;

			return treeLabel + " : " + treeValue
		}

		const chart = new ChartJS(canvas.current, {
			type: "treemap",
			data: {
				datasets: [
					{
						tree: treeData,
						key: 'value',
						groups: ['label'],
						labels: {
							display: false,
							formatter: (ctx) => ctx.raw.g
						},
						backgroundColor: (ctx) => getColour(ctx),
						spacing: 1.2
					},
				]
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					title: {
						display: false,
					},
					legend: {
						display: false,
					},
					datalabels: false,
					tooltip: {
						callbacks: {
							title: function (item, data) {
								return "Material";
							},
							// label(ctx) {
							// 	//console.log(ctx.raw)
							// 	return ctx.raw.g
							// },
							label: (ctx) => getLabel(ctx),
						}
					}
				}
			}
		});
		return () => chart.destroy();
	}, [canvas, props]);
	return (
		<div style={{ minHeight: 300 + 'px' }}>
			<canvas ref={canvas} />
		</div>
	);
}

function OverviewTreemapChart() {
	return (
		<Treemap />
	);
}

export default OverviewTreemapChart;