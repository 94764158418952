import React, { useEffect, useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import axios from "axios";
import { useSelector } from 'react-redux';
import { formatScore, formatGhge } from '../HelperFunctions'

function OverviewRegionTable(props) {
	const backendUrl = useSelector((state) => state.backendUrl);
	const [ANZStats, setANZstats] = useState({});
	const [EuropeStats, setEuropeStats] = useState({});
	const [EmeaStats, setEmeaStats] = useState({});
	const [AsiaStats, setAsiaStats] = useState({});
	const [AmericasStats, setAmericasStats] = useState({});

	useEffect(() => {

		//get global scores
		axios.get(`${backendUrl}/globalStoreScores`)
			.then(function (response) {

				setANZstats(response.data.regionalScores[0])
				setEuropeStats(response.data.regionalScores[1])
				setEmeaStats(response.data.regionalScores[2])
				setAsiaStats(response.data.regionalScores[3])
				setAmericasStats(response.data.regionalScores[4])

			})
			.catch(function (error) {
				console.log(error);
			});

	}, []);

	const region = [
		{
			id: 1, name: "ANZ", noStores: ANZStats.count,
			//averageScore: ANZStats.averageScore
			averageScore: parseFloat(Math.round((ANZStats.averageScore) * 100) / 100)
		},
		{
			id: 2, name: "Europe", noStores: EuropeStats.count,
			//averageScore: EuropeStats.averageScore
			averageScore: parseFloat(Math.round((EuropeStats.averageScore) * 100) / 100)
		},
		{
			id: 3, name: "EMEA", noStores: EmeaStats.count,
			//averageScore: EmeaStats.averageScore
			averageScore: parseFloat(Math.round((EmeaStats.averageScore) * 100) / 100)
		},
		{
			id: 4, name: "Asia", noStores: AsiaStats.count,
			//averageScore: AsiaStats.averageScore
			averageScore: parseFloat(Math.round((AsiaStats.averageScore) * 100) / 100)
		},
		{
			id: 5, name: "Americas", noStores: AmericasStats.count,
			//averageScore: AmericasStats.averageScore
			averageScore: parseFloat(Math.round((AmericasStats.averageScore) * 100) / 100)
		},
	];

	return (
		<Row className="py-3">
			<Col md={12} className="py-3">
				<Row className="border border-black">
					<Col>
						<Table responsive="md" className="table-borderless">
							<thead>
								<tr>
									<th></th>
									<th className="text-center">No. stores</th>
									<th className="text-center">Average score</th>
								</tr>
							</thead>
							<tbody>
								{region.map((m) => (
									<tr key={m.name} className={`${m.averageScore >= 70 ? "table-success" : ""} ${m.averageScore >= 50 && m.averageScore <= 69 ? "table-warning" : ""} ${m.averageScore <= 49 ? "table-danger" : ""}`}>
										<td><strong>{m.name}</strong></td>
										<td className="text-center">{m.noStores}</td>
										<td className="text-center"><strong>{formatScore(m.averageScore)}</strong></td>
									</tr>
								))}
							</tbody>
						</Table>
					</Col>
				</Row>
			</Col>
		</Row>

	);
}

export default OverviewRegionTable;