import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { authUser } from './AdminUsersPage'

import { setUser, setUserId, setUserLoggedIn } from '../store/actions';

//https://www.freecodecamp.org/news/how-to-persist-a-logged-in-user-in-react/

export const clearLocalStorage = () => {
	localStorage.removeItem("user")
	localStorage.removeItem("userId")
	localStorage.removeItem("userPersistent")
	localStorage.removeItem("userLoggedIn")
}

export const clearSessionStorage = () => {
	sessionStorage.removeItem("user")
	sessionStorage.removeItem("userId")
	sessionStorage.removeItem("userPersistent")
	sessionStorage.removeItem("userLoggedIn")
}

const LoginForm = () => {

	const dispatch = useDispatch();

	const [persistent, setPersistent] = useState("");
	const [errorMsg, setErrorMsg] = useState(" ")
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	useEffect(() => {
		const loggedInLocal = localStorage.getItem("userId"); // persisted across sessions
		const loggedInSession = sessionStorage.getItem("userId"); // only for current session

		// if (config.isDebugMode) {
		// 	console.log("Debug mode, so clearing local and session storage!")
		// 	clearLocalStorage()
		// 	clearSessionStorage()
			
		// 	//warning deathloop below
		// 	//window.location.reload(false);
		// }

		if (loggedInLocal) {
			// check if the session was persisted, if so, copy to session storage

			// globally save the user data so the rest of the app knows it is logged in
			dispatch(setUser(JSON.parse(localStorage.getItem("user"))))
			dispatch(setUserId(localStorage.getItem("userId")));
			dispatch(setUserLoggedIn(localStorage.getItem("userLoggedIn")));
		}
		else if (loggedInSession) {
			dispatch(setUser(JSON.parse(sessionStorage.getItem("user"))))
			dispatch(setUserId(sessionStorage.getItem("userId")));
			dispatch(setUserLoggedIn(sessionStorage.getItem("userLoggedIn")));

			// console.log("foundUserSession");
			// console.log(foundUser);
		}
	}, [ dispatch ]);

	const handleLogin = async e => {
		setErrorMsg("Logging in...")
		e.preventDefault()

		const onSuccess = (user) => {
			setPersistent(persistent)

			dispatch(setUser(user))
			dispatch(setUserId(user.id))
			dispatch(setUserLoggedIn("true"))

			//set persistent login
			if (persistent) {
				localStorage.setItem("user", JSON.stringify(user))
				localStorage.setItem("userId", user.id)
				localStorage.setItem("userPersistent", persistent)
				localStorage.setItem("userLoggedIn", "true")
			} else {
				clearLocalStorage()
			}

			//set session login
			sessionStorage.setItem("user", JSON.stringify(user))
			sessionStorage.setItem("userId", user.id)
			sessionStorage.setItem("userPersistent", persistent)
			sessionStorage.setItem("userLoggedIn", "true")
		}

		const onError = (data) => {
			console.log('data', data)
			setErrorMsg(data.message)
		}

		authUser(email, password, onSuccess, onError)
	}

	return (
		<Container>
			<Row style={{ minHeight: "90vh" }} className="h-100 d-flex justify-content-center align-items-center">
				<Col md={4}>
					<Row>
						<Col>

							<Form onSubmit={handleLogin} autoComplete="off">
								<Row className="h-100 m-0 p-0 border border-dark bg-dark text-light d-flex">
									<Col className="py-2 align-self-center">
										<span className="p-0 m-0">Aesop</span>
									</Col>
								</Row>
								<Form.Floating className="">
									<Form.Control
										id="email"
										name="email"
										type="email"
										placeholder="email"
										value={email}
										className="border border-dark"
										onChange={({ target }) => setEmail(target.value)}
									/>
									<label htmlFor="email">Email</label>
								</Form.Floating>
								<Form.Floating>
									<Form.Control
										id="password"
										name="password"
										type="password"
										value={password}
										className="border border-dark"
										placeholder="enter a password"
										onChange={({ target }) => setPassword(target.value)}
									/>
									<label htmlFor="password">Password</label>
								</Form.Floating>
								<Form.Group>
									<Button className="w-100 border border-dark bg-bronze-200 p-3" variant="" type="submit" size="md">
										Login
									</Button>
								</Form.Group>
								<Form.Group className="my-3 d-flex justify-content-center">
									<Form.Check
										type="switch"
										id="persistentLogin"
										name="persistentLogin"
										label="Keep me logged in"
										onChange={({ target }) => setPersistent(target.checked)}
									/>
								</Form.Group>
							</Form>
						</Col>
					</Row>
					<Row>
						<Col>
							{errorMsg.indexOf('incorrect') !== -1 ? (
								<p className="text-center text-danger">{errorMsg}</p>
							) : (
								<p className="text-center">{errorMsg}</p>
							)}
						</Col>
					</Row>
				</Col>

			</Row>
		</Container>
	)
};

export default LoginForm;