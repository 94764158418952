import React from "react"
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Header from "./Header";
import './../index.css';
import './../style/css/aesop.scss';
import ChartsGlobalStores from './ChartsGlobalStores';
import { AccessControl } from "../AccessControl"

const HomePageContent = () => {
	const user = useSelector((state) => state.user)

	const history = useHistory()
	function handleClickOverview() {
		history.push('/overview')
	}
	function handleClickProjects() {
		history.push('/stores')
	}

	function handleClickNewProject() {
		history.push('/stores/new')
	}

	const btnColWidth = AccessControl.storeCanCreate(user) ? 4 : 6

	return (
		<>
			<Row>
				<ChartsGlobalStores
					parentPage={"home"}
				/>
			</Row>
			<Container>
					<Row>
						<Col md={btnColWidth} className="py-3">
							<Button className="w-100 py-3" variant="outline-dark" onClick={handleClickOverview}>View your overview</Button>
						</Col>
						<Col md={btnColWidth} className="py-3">
							<Button className="w-100 py-3" variant="outline-dark" onClick={handleClickProjects}>View all projects</Button>
						</Col>
					{(AccessControl.storeCanCreate(user)) ? (
							<Col md={btnColWidth} className="py-3">
								<Button className="w-100 py-3" variant="outline-dark" onClick={handleClickNewProject}>Create new project</Button>
							</Col>
						): undefined
					}
				</Row>

				<Row className="py-3">
					<h4 className="border-bottom border-dark">About this tool</h4>
				</Row>
				<Row>
					<Col md={12}>
						<p>The Sustainability Scorecard (Artemis) aims to provide an insight into Store Design's environmental and social practices by collecting information on the materials used for the design of Aesop spaces.</p>
						<p>The scorecard produces two sustainability ratings.</p>
						<p>The circularity score is qualitative and focuses on the following topics:</p>
						<ul>
							<li>Raw Materials type</li>
							<li>Sourcing distance</li>
							<li>End of life scenario</li>
							<li>Certifications</li>
						</ul>
						<p>The embodied carbon emission is a quantitative indicator. It allows to track and reduce the greenhouse gases emissions related to the material supply. It considers the following characteristics:</p>
						<ul>
							<li>Type of material</li>
							<li>Transportation mode and distance </li>
							<li>Quantity used</li>
						</ul>
						<p>This indicator gives a carbon emission result in kilograms of CO2 per square meter, allowing to compare the sustainability performance between the designs and to align our emission reduction with the company’s targets.</p>
					</Col>
				</Row>
			</Container>
		</>
	)
}

function HomePage(props) {
	// const dispatch = useDispatch()

	// const history = useHistory()
	// function handleClick(user) {
	// 	dispatch(setUser(user.name))
	// 	console.log("user:", user)
	// 	console.log("user:", user.role)
	// 	history.push('/' + `${user.role}`)
	// }

	return (
		<Container>
			<Row>
				<Header />

				{/* if there's a user show the message below */}
				<HomePageContent/>
			</Row>
		</Container>
	)
}

export default HomePage