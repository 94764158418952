import { createAction } from '@reduxjs/toolkit';

export const setUser = createAction('SET_USER');
export const setStoreId = createAction('SET_STORE_ID');
export const setMaterialId = createAction('SET_MATERIAL_ID');

export const setEditMode = createAction('SET_EDIT_MODE');

export const setBackendUrl = createAction('SET_BACKEND_URL');



export const setUserDisplayName = createAction('SET_USER_DISPLAY_NAME');
export const setUserId = createAction('SET_USER_ID');
export const setUserLoggedIn = createAction('SET_USER_LOGGED_IN');

export const setEpicMaterialsList = createAction('SET_EPIC_MATERIALS_LIST')

export const setStoreLifecycleModalInfo = createAction('SET_STORE_LIFECYCLE_MODAL_INFO')

