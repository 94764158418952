import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './../index.css';
// import subCategoryMappingsOld from '../data/2023 AESOP - Sub list definition - scorecards_clean.json'
import subCategoryMappings from '../data/20230203 Materials - Epic and Artemis Checks.json'
import { calcTotalGhge, ensureString, formatUnit, onlyUnique } from '../HelperFunctions'
import { sortByFieldAsc } from '../HelperFunctions'
import { createMaterialInstance, updateMaterialInstance } from "./AdminMaterialsPage";

export const certDbButton = (
	<a href="/downloads/20220406-ASSG-Eco-label Database-v3.pdf" download="20220406-ASSG-Eco-label Database-v3.pdf">
		<Button variant="outline-dark">Certification Database</Button>
	</a>
)

// This field is set on the backend, see lookups.go
const categories = [
	{ name: "Core structure & finish" },
	{ name: "Module units" },
	{ name: "Furniture & fittings" },
]

// This is not the full list.
// See artemis checks json file
export const subCategories =
/* 	subCategoryMappings
	.map(scm => scm.SubCategories)
	.flat()
	.filter(onlyUnique)
	.sort()
	.map(n => ({ name: n })) */
[
	{ name: "Flooring" },
	{ name: "Wall" },
	{ name: "Ceiling" },
	{ name: "Facade" },
	{ name: "POS" },
	{ name: "Product shelving" },
	{ name: "BOH" },
	{ name: "Basin" },
	{ name: "Furniture" },
	{ name: "Lighting" },
	{ name: "Fixtures" },
]

// Not used?
export const categoryLookup = [
	{ name: "Flooring", parent: "Core structure & finish" },
	{ name: "Wall", parent: "Core structure & finish" },
	{ name: "Ceiling", parent: "Core structure & finish" },
	{ name: "Facade", parent: "Core structure & finish" },
	{ name: "POS", parent: "Module units" },
	{ name: "Product shelving", parent: "Module units" },
	{ name: "BOH", parent: "Module units" },
	{ name: "Basin", parent: "Module units" },
	{ name: "Furniture", parent: "Furniture & fittings" },
	{ name: "Lighting", parent: "Furniture & fittings" },
	{ name: "Fixtures", parent: "Furniture & fittings" },
]

// export const units = [
// 	{ id: 1, name: "kg" },
// 	{ id: 2, name: "m" },
// 	{ id: 3, name: "m^2" },
// 	{ id: 4, name: "m^3" },
// 	{ id: 5, name: "number" },
// ];

export const units =
	subCategoryMappings
		.map(scm => scm["Functional unit"])
		.flat()
		.filter(onlyUnique)
		.sort()
		.map(n => ({ id: n, name: n }))

const location = [
	{ id: 1, name: "Locally", title: "Local (0 to 1,000km)"},
	{ id: 2, name: "Regionally", title: "Regional (1,000 to 10,000km)"},
	{ id: 3, name: "Globally", title: "Global (10,000km +)"},
]

const rawMaterial = [
	{ id: 1, name: "Retained" },
	{ id: 2, name: "Reused" },
	{ id: 3, name: "Recycled" },
	{ id: 4, name: "Partly Recycled" },
	{ id: 5, name: "Virgin" },
];

const primaryTransport = [
	{ id: 1, name: "Road" },
	{ id: 2, name: "Rail" },
	{ id: 3, name: "Air" },
	{ id: 4, name: "Sea" },
];

export const endOfLifeAssessment = [
	{ id: 1, name: "Not recyclable" },
	{ id: 2, name: "Part recyclable" },
	{ id: 3, name: "Fully recyclable" },
	{ id: 4, name: "Recycling program" },
	{ id: 5, name: "Able to be reused/repurposed" },
	{ id: 6, name: "Finish to remain after decommission" },
	{ id: 7, name: "Takeback Scheme" }
];

const certification = [
	{ id: 1, name: "Aluminum Stewardship Initiative" },
	{ id: 2, name: "Cradle to Cradle" },
	{ id: 3, name: "Declare" },
	{ id: 4, name: "Forest Stewardship Council (FSC) certification" },
	{ id: 5, name: "Global Green Tag" },
	{ id: 6, name: "Green Tick" },
	{ id: 7, name: "Not Certified" },
	{ id: 8, name: "Other" },
	{ id: 9, name: "Programme for the Endorsement of Forest Certification (PEFC)" },
	{ id: 10, name: "Smart Certified" },
	{ id: 11, name: "Retained" }
]

// const materialDataList = subCategoryMappingsOld.map(scm => {
// 	return {
// 		material: scm.Material,
// 		category: scm.Category,
// 		subCategories: scm.SubCategories
// 	}
// })

export const materialDataList = subCategoryMappings.map(scm => {
	return {
		material: scm.Material,
		category: scm.Category,
		subCategories: scm.SubCategories,
		functionalUnit: scm["Functional unit"]
	}
})

let materialDataDefault = {
	subCategory: "",
	itemType: "",
	materialQuantity: "",
	unit: "",
	materialDescription: "",
	rawMaterial: "",
	endOfLifeAssessment: "",
	productCertification: "",
	supplierName: "",
	supplierLocation: "",
	manufacturerName: "",
	manufacturerLocation: "",
	primaryTransportMethod: "",

	// materialTypeId: "",
	// materialStatus: "",
	// createdDate: "",
	// originId: "",
	// storeId: "",
	// category: "",
	// roadTransportKms: "",
	// railTransportKms: "",
	// airTransportKms: "",
	// oceanTransportKms: "",
	// creatorId: "",
}

function ProjectsNewMaterialModal({show, materialInstance, onHide, store, epicMaterials}) {
	const storeId = Number(store.id)
	const isEditMode = !!materialInstance

	const [validated, setValidated] = useState(false);
	const [filteredMaterialDataList, setFilteredMaterialDataList] = useState([])

	const userId = useSelector((state) => state.userId)
	const userLoggedIn = useSelector((state) => state.userLoggedIn)
	const backendUrl = useSelector((state) => state.backendUrl)
	// const storeId = useSelector((state) => state.storeId)
	// const storeIdRead = storeId

	const dbCustomMaterials = epicMaterials.filter(dbMat => dbMat.dataSource === "Custom")
	// console.log("dbCustomMaterials", dbCustomMaterials)

	const newMaterialInstance = {
			...materialDataDefault,
			storeId: storeId,
			scorecardType: store.scorecardType,
			creatorId: parseInt(userId),
		}

	const initialMaterialData = isEditMode ? materialInstance : newMaterialInstance
	// console.log('materialInstance', materialInstance)
	// console.log('initialMaterialData', initialMaterialData)

	const [materialData, setMaterialData] = useState(initialMaterialData)

	/**
	 * Only call this once per cycle since it will not update the state immediately,
	 * and it may end up only performing the last update!!
	 */
	const updateMaterialDataField = (fieldName, fieldVal) => {
		// console.log("updateMaterialDataField", fieldName, "=>", fieldVal)
		let updatedMaterialData = { ...materialData }
		updatedMaterialData[fieldName] = fieldVal
		setMaterialData(updatedMaterialData)
	}

	const updateMaterialDataFields = (updatedFields) => {
		setMaterialData({
			...materialData,
			...updatedFields
		})
	}

	const filterMaterialListBySubCategory = (subCategory) => {
		// console.log("Filter by:", subCategory)

		const dbCustomMaterialsFiltered =
			dbCustomMaterials
			.filter(item => item.subCategoriesList && item.subCategoriesList.indexOf(subCategory) != -1) // filter by subcategory

		const filtered =
			materialDataList
				.filter(item => item.subCategories && item.subCategories.indexOf(subCategory) != -1) // filter by subcategory
				.map(item => {
					// replace with db item
					return epicMaterials.find(dbMat => dbMat.material === item.material)
				}) 
				.filter(item => !!item) // filter undefined
				.filter(item => item.isActive) // filter by DB IsActiveField
				.concat(dbCustomMaterialsFiltered) // Add our custom materials
				.sort(sortByFieldAsc('material'))

		setFilteredMaterialDataList(filtered)
		// console.log('filtered material list', filtered)

		// Don't use: overwrites already set materialData!
		// if the currently selected material (materialTypeId) is not in the filtered list, then clear it
		// if (!filtered.find(mat => mat.material === materialData.itemType)) {
		// 	updateMaterialDataField("materialTypeId", undefined)
		// }
	}

	useEffect(() => {
		console.log('ProjectsNewMaterialModal.useEffect')
		console.log('materialInstance', materialInstance)

		if (materialInstance) {
			setMaterialData(materialInstance)
			filterMaterialListBySubCategory(materialInstance.subCategory)
		} else {
			setMaterialData(newMaterialInstance)
		}
	}, [materialInstance])

	const onChange = (e) => {

		// console.log("material check")
		const fieldName = e.target.name
		// console.log(fieldName, '=', e.target.value);
		// console.dir(fieldName);

		if (fieldName === "subCategory") {
			const subCategory = e.target.value
			updateMaterialDataField(fieldName, subCategory)
			//filteredMaterialDataList

			// console.log('dbCustomMaterials', dbCustomMaterials)

			filterMaterialListBySubCategory(subCategory)
			
			//let testList = filteredMaterialDataList.filter(item => item.subCategories.indexOf(subCategory) != -1)
			//console.log(testList)
		} else if (fieldName === "itemType") {
			// Set the functional unit after the material (item type) is selected
			// find the material
			const materialId = Number(e.target.value)
			const material = filteredMaterialDataList.find(epicMat => epicMat.id === materialId)

			let updatedFields = {}
			updatedFields["materialTypeId"] = materialId
			updatedFields["itemType"] = material.material

			if (material) {
				updatedFields["unit"] = material.functionalUnit
			}

			updateMaterialDataFields(updatedFields)
		} else if (Number(e.target.value)) {
			// console.log("numeric field");
			updateMaterialDataField(fieldName, Number(e.target.value));
			if (fieldName == "primaryTransport") {
				// console.log("primarytransport")
				// console.log(e.target.value);
			}
		} else if (e.target.type == "checkbox") {
			// console.log(e.target.type);
			// console.log(e.target.checked);
			updateMaterialDataField(fieldName, e.target.checked);
		} else if (e.target.type == "radio") {
			// console.log(e.target.type);
			updateMaterialDataField(fieldName, e.target.value);
		} else {
			// console.log("non-numeric field");
			updateMaterialDataField(fieldName, e.target.value);
		}
		
		// console.log('materialData', materialData)
	}
	
	// Create a new material instance
	const saveMaterialData = () => {
		createMaterialInstance(materialData, store.scorecardType, (responseData => {
			onHide()
			setMaterialData(newMaterialInstance) // clear the form
		}))
	}

	// Update existing material instance
	const updateMaterialData = () => {
		updateMaterialInstance(materialData, store.scorecardType, (updatedMatInst => {
			onHide()
			setMaterialData(newMaterialInstance) // clear the form
		}))
	}

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			// console.log("false");
			event.preventDefault();
			event.stopPropagation();
			setValidated(true);
		} else if (form.checkValidity() === true) {
			event.preventDefault();
			event.stopPropagation();
			// console.log("true");
			
			if (isEditMode) {
				updateMaterialData()
			} else {
				saveMaterialData()
			}

			setValidated(false);
		}
	};

	// console.log('materialData.render', materialData);

	// We need the EmbodiedGHGE from the epic material
	const materialId = Number(materialData.materialTypeId)
	const material = filteredMaterialDataList.find(epicMat => epicMat.id === materialId)
	const materialDataForCalc = material && {
		...materialData,
		embodiedGHGE: material.embodiedGHGE
	}
	const totGhgeInfo = material ? calcTotalGhge(materialDataForCalc) : {}

	const materialNameCharWidth = 55

	/**
	 * Render some spaces after the material name.
	 * This is not exact since we are not using a monospace font
	 **/
	const spacesBeforeGhge = (materialName) => {
		const spaceCount = Math.max(materialNameCharWidth - materialName.length, 4)
		return new Array(spaceCount).join(String.fromCharCode(160))
	}

	return (
		<Modal
			show={show}
			onHide={onHide}
			size="lg"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>{isEditMode ? "Edit Material: " + materialData.itemType : "Create New Material"}</Modal.Title>
			</Modal.Header>
			<Modal.Body>

				<Form className="col-12" autoComplete="off" validated={validated} noValidate onSubmit={handleSubmit}>
					<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
						{/* <Form.Label column md={2}>
							Category
						</Form.Label>
						<Col md={4}>
							<Form.Select
								required
								id="category"
								name="category"
								onChange={onChange}>
								<option key="undefined" value="" disabled="{true}">Please Select</option>
								{categories.map((m) => (
									<option value={m.name}>{m.name}</option>
								))}
							</Form.Select>
						</Col> */}
						<Form.Label column md={2}>
							Category
						</Form.Label>
						<Col md={10}>
							<Form.Select
								required
								id="subCategory"
								name="subCategory"
								value={materialData.subCategory || ""}
								onChange={onChange}
								>
								<option key="undefined" value="" disabled="{true}">Please Select</option>
								{subCategories.map((m) => (
									<option key={m.id} value={m.name}>{m.name}</option>
								))}
							</Form.Select>
						</Col>
					</Form.Group>

					{/* This is epicmaterial.material */}
					<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
						<Form.Label column md={2}>
							Material
						</Form.Label>
						<Col md={10}>
							<Form.Select
								required
								id="itemType"
								name="itemType"
								type="text"
								placeholder="Item Type"
								value={materialData.materialTypeId || ""}
								onChange={onChange}
								disabled={filteredMaterialDataList.length === 0}
								>
								<option key="undefined" value="" disabled="{true}">{filteredMaterialDataList.length > 0 ? "Please Select" : "Select a Sub-Category first"}</option>
								{filteredMaterialDataList.map((m) => (
									<option style={{ fontFamily: "monospace" }} key={m.id} value={m.id}>{m.material}{spacesBeforeGhge(m.material)}{m.embodiedGHGE} kgCO2e/{formatUnit(m.functionalUnit)}</option>
								))}
							</Form.Select>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
						<Form.Label column md={2}>
							Quantity
						</Form.Label>
						<Col md={4}>
							<Form.Control
								required
								id="materialQuantity"
								name="materialQuantity"
								type="number"
								min="0"
								step="0.1"
								placeholder="Material Quantity"
								value={materialData.materialQuantity || ""}
								onChange={onChange}
							/>
						</Col>
						<Form.Label column md={2}>{formatUnit(materialData.unit)}</Form.Label>
						<Form.Label column md={4} title={totGhgeInfo.calcInfo} style={{ color: 'grey'}}>
							<span>{totGhgeInfo.formatted} kgCO2e</span>
						</Form.Label>
						
						{/* <Col md={4}>
							<Form.Select
								required
								disabled={true}
								id="unit"
								name="unit"
								value={materialData.unit || ""}
								onChange={onChange}>
								<option key="undefined" value="">-</option>
								{units.map((m) => (
									<option key={m.id} value={m.name}>{formatUnit(m.name)}</option>
								))}
							</Form.Select>
						</Col> */}
					</Form.Group>

					<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
						<Form.Label column md={2}>
							Description
						</Form.Label>
						<Col md={10}>
							<Form.Control
								as="textarea"
								id="materialDescription"
								name="materialDescription"
								type="text"
								placeholder="Description"
								rows={3}
								value={materialData.materialDescription || ""}
								onChange={onChange}
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
						<Form.Label column md={2}>
							Raw Material
						</Form.Label>
						<Col md={4}>
							<Form.Select
								required
								id="rawMaterial"
								name="rawMaterial"
								value={materialData.rawMaterial || ""}
								onChange={onChange}>
								<option key="undefined" value="" disabled="{true}">Please Select</option>
								{rawMaterial.map((m) => (
									<option key={m.id} value={m.name}>{m.name}</option>
								))}
							</Form.Select>
						</Col>
						<Form.Label column md={2}>
							End of Life Scenario
						</Form.Label>
						<Col md={4}>
							<Form.Select
								required
								id="endOfLifeAssessment"
								name="endOfLifeAssessment"
								value={materialData.endOfLifeAssessment || ""}
								onChange={onChange}>
								<option key="undefined" value="" disabled="{true}">Please Select</option>
								{endOfLifeAssessment.map((m) => (
									<option key={m.id} value={m.name}>{m.name}</option>
								))}
							</Form.Select>
						</Col>
					</Form.Group>

					<Row>
						<Col md={12} className="pt-2">
							<h5>Product Certification</h5>
						</Col>
					</Row>

					<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
						<Form.Label column md={4}>
							Select Certification
						</Form.Label>
						<Col md={4}>
							<Form.Select
								id="productCertification"
								name="productCertification"
								value={materialData.productCertification || ""}
								onChange={onChange}
							>
								<option key="undefined" value="" disabled="{true}">Please Select</option>
								{certification.map((m) => (
									<option key={m.id} value={m.name}>{m.name}</option>
								))}
							</Form.Select>
						</Col>
						<Col md={4}>
							{certDbButton}
						</Col>
					</Form.Group>

					<Row>
						<Col md={12} className="pt-2">
							<h5>Sourcing Distance</h5>
						</Col>
					</Row>

					<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
						<Form.Label column md={2}>
							Supplier
						</Form.Label>
						<Col md={4}>
							<Form.Control
								id="supplierName"
								name="supplierName"
								type="text"
								placeholder="Supplier Name"
								value={materialData.supplierName || ""}
								onChange={onChange}
							/>
						</Col>
						<Form.Label column md={2}>
							Supplier Location
						</Form.Label>
						<Col md={4}>
							<Form.Select
								id="supplierLocation"
								name="supplierLocation"
								value={materialData.supplierLocation || ""}
								onChange={onChange}>
								<option key="undefined" value="" disabled="{true}">Please Select</option>
								{location.map((m) => (
									<option key={m.id} value={m.name}>{m.title}</option>
								))}
							</Form.Select>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
						<Form.Label column md={2}>
							Manufacturer
						</Form.Label>
						<Col md={4}>
							<Form.Control
								//required
								id="manufacturerName"
								name="manufacturerName"
								type="text"
								placeholder="Manufacturer Name"
								value={materialData.manufacturerName || ""}
								onChange={onChange}
							/>
						</Col>
						<Form.Label column md={2}>
							Manufacturer Location
						</Form.Label>
						<Col md={4}>
							<Form.Select
								//required
								id="manufacturerLocation"
								name="manufacturerLocation"
								value={materialData.manufacturerLocation || ""}
								onChange={onChange}>
								<option key="undefined" value="" disabled="{true}">Please Select</option>
								{location.map((m) => (
									<option key={m.id} value={m.name}>{m.title}</option>
								))}
							</Form.Select>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
					<Form.Label column md={4}>
							Primary transport
						</Form.Label>
						<Col md={8}>
							<Form.Select
								required
								id="primaryTransportMethod"
								name="primaryTransportMethod"
								value={materialData.primaryTransportMethod || ""}
								onChange={onChange}
							>
								<option key="undefined" value="" disabled="{true}">Please Select</option>
								{primaryTransport.map((m) => (
									<option key={m.id} value={m.name}>{m.name}</option>
								))}
							</Form.Select>
						</Col>
					</Form.Group>

{/* 					<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
						<Form.Label column md={2}>
							Road Transport (km)
						</Form.Label>
						<Col md={4}>
							<Form.Control
								id="roadTransportKms"
								name="roadTransportKms"
								type="number"
								min="0"
								placeholder="Please enter distance"
								onChange={onChange}
							/>
						</Col>
						<Form.Label column md={2}>
							Rail Transport (km)
						</Form.Label>
						<Col md={4}>
							<Form.Control
								id="railTransportKms"
								name="railTransportKms"
								type="number"
								min="0"
								placeholder="Please enter distance"
								onChange={onChange}
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
						<Form.Label column md={2}>
							Air Transport (km)
						</Form.Label>
						<Col md={4}>
							<Form.Control
								id="airTransportKms"
								name="airTransportKms"
								type="number"
								min="0"
								placeholder="Please enter distance"
								onChange={onChange}
							/>
						</Col>
						<Form.Label column md={2}>
							Ocean Transport (km)
						</Form.Label>
						<Col md={4}>
							<Form.Control
								id="oceanTransportKms"
								name="oceanTransportKms"
								type="number"
								min="0"
								placeholder="Please enter distance"
								onChange={onChange}
							/>
						</Col>
					</Form.Group> */}
					<Container className="pt-5">
						<Row className="d-flex align-items-center justify-content-center">
							<Button
								type="submit"
								variant="outline-dark"
								className="ps-2 w-50"
							>
								<i className="bi bi-plus mx-2"></i>{isEditMode ? "Update Material" : "Add material"}
							</Button>
						</Row>
						<Row className="d-flex align-items-center justify-content-center pt-4">
							<Button
								size="sm"
								type="button"
								variant="outline-danger"
								onClick={() =>{ setMaterialData(newMaterialInstance); onHide() }}
								className="pe-2 w-50"
							>
								Cancel
							</Button>
						</Row>
					</Container>
				</Form>

			</Modal.Body>
			{/*<Modal.Footer>
			
			</Modal.Footer>*/}


		</Modal >
	);
}

export default ProjectsNewMaterialModal;