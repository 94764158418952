import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import axios from "axios";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ChartFilledSingleCircle from "./ChartFilledSingleCircle";
import ChartFilledSingleCircleGHG from "./ChartFilledSingleCircleGHG";

import './../index.css';
import { config } from "../config";

const ChartsGlobalStores = ({ parentPage }) => {
	const [globalHighest, setGlobalHighest] = useState({});
	// const [globalLowest, setGlobalLowest] = useState({});
	// const [globalLowestBuffered, setGlobalLowestBuffered] = useState({});
	const [globalAverage, setGlobalAverage] = useState(0);
	const [globalAverageGHGEmissions, setGlobalAverageGHGEmissions] = useState(0);

	useEffect(() => {

		//get global scores
		axios.get(`${config.backendUrl}/globalStoreScores`)
			.then(function (response) {
				//not used, state broken
				//setGlobalStoreScores(response.data);

				//prep av value
				//old rounding to 2 dec. places
				//setGlobalAverage(Math.round((response.data.globalAverage) * 100) / 100);
				//setGlobalAverageGHGEmissions(Math.round((response.data.globalAverageGHGEmissions) * 100) / 100);
				setGlobalAverage(Math.round(response.data.globalAverage));
				setGlobalAverageGHGEmissions(Math.round(response.data.globalAverageGHGEmissions));

				//prep highest val
				var highestVal = response.data.globalHighest;
				//old rounding to 2 dec. places
				//highestVal.totalScore = Math.round((highestVal.totalScore) * 100) / 100;
				//new rounding to whole num
				highestVal.totalScore = Math.round(highestVal.totalScore);
				setGlobalHighest(highestVal);

				//prep lowest val
				var lowestVal = response.data.globalLowest;
				lowestVal.totalScore = Math.round((response.data.globalLowest.totalScore) * 100) / 100;
				// setGlobalLowest(lowestVal);
				// if (lowestVal.totalScore === 0) {
				// 	setGlobalLowestBuffered(1)
				// }
				// else {
				// 	setGlobalLowestBuffered(lowestVal)
				// }

			})
			.catch(function (error) {
				console.log(error);
			});

	}, []);

	return (
		<Container>
			<Row>
				<Col xs={12} md={4} className="m-0 p-1">
					<Row>
						<Col className="d-flex justify-content-center align-items-center">
							<div className="fadeText2s text-center" style={{ position: "absolute" }}>
								<h6>Global Average<br />
									Circularity Score</h6>
								<h4 className="">{globalAverage}</h4>
							</div>
							<ChartFilledSingleCircle
								percentage={globalAverage}
								label="Global Average"
								delay={1000}
							/>
						</Col>
					</Row>
					<Row className="pt-2">
						<Col>
							<h6 className="text-center">&nbsp;</h6>
						</Col>
					</Row>
				</Col>
				<Col xs={12} md={4} className={`${parentPage === "home" ? "d-none d-md-block m-0 p-1" : "m-0 p-1"}`}>
					<Row>
						<Col className="d-flex justify-content-center align-items-center">
							<div className="fadeText2s text-center" style={{ position: "absolute" }}>
								<h6>Highest Global<br />
									Circularity Score</h6>
								<h4 className="">{globalHighest.totalScore}</h4>
							</div>
							<ChartFilledSingleCircle
								percentage={globalHighest.totalScore}
								label="Highest Global Store"
								delay={1000}
							/>
						</Col>
					</Row>
					<Row className="pt-2">
						<Col>
							<p className="text-center text-muted"><small>{globalHighest.storeName}</small></p>
						</Col>
					</Row>
				</Col>

				{/* Global average */}
				<Col xs={12} md={4} className={`${parentPage === "home" ? "d-none d-md-block m-0 p-1" : "m-0 p-1"}`}>
					<ChartFilledSingleCircleGHG
						locationType="Store"
						//ghgValue={globalAverageGHGEmissions}
						ghgValue={globalAverageGHGEmissions}
						label=""
						delay={1000}
						topText={<span>GHG Emissions<br />Impact Average</span>}
						bottomText=" "
					/>
				</Col>
			</Row>
		</Container>
	);
}

export default ChartsGlobalStores;