import React, { useEffect, useState } from "react"
import { useSelector } from 'react-redux'
import axios from 'axios'
import Header from "./Header"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import { config } from "../config"
import { UserRole } from "../AccessControl"
import { Modal } from "react-bootstrap"

const backendUrl = config.backendUrl

export const DbObjStatus =
{
	DB: 'DB',
	CHANGED: 'CHANGED',
	SAVING: 'SAVING',
	SAVED: 'SAVED'
}

export const DbObj =
{
	/** Add the local state fields to the DB object */
	ofDb: dbObj => {
		let localObj = { ...dbObj }
		localObj._objStatus = DbObjStatus.DB
		return localObj
	},

	/** Cleanup the local localObj object to store in the DB */
	toDb: localObj => {
		let dbObj = { ...localObj }
		delete dbObj._objStatus
		return dbObj
	}
}

/// try to authenticate login details
export const authUser = (email, password, onSuccess, onError) => {
	axios.post(`${backendUrl}/users/auth`, { email, password })
		.then(function (response) {
			const user = response.data
			console.log('User login success', user)
			onSuccess(user)
		})
		.catch(function (error) {
			console.log('Error logging in', error)

			if (error && error.response && error.response.data) {
				onError(error.response.data)
			}
		})
}

const createUser = (user, onSuccess) => {
	axios.post(`${backendUrl}/user`, DbObj.toDb(user))
		.then(function (response) {
			console.log('User created', response)
			onSuccess(response.data)
		})
		.catch(function (error) {
			console.log('Error creating User', error);
		})
}

const deleteUser = (user, onSuccess) => {
	axios.delete(`${backendUrl}/users/${user.id}`)
		.then(function (response) {
			// var data = response.data
			// console.log("store delete response", data)
			console.log("User deleted (" + user.email + ")")
			onSuccess(user)
		})
		.catch(() => {
			console.log("Error deleting user (" + user.email + "). Please try again.")
		})
}

export const loadUsers = (gotUsers) => {
	axios.get(`${config.backendUrl}/users`)
		.then(response => response.data)
		.then(users => {
			// console.log(users)
			gotUsers(users)
		})
}

export const AdminUsersPage = (props) => {

	const backendUrl = useSelector((state) => state.backendUrl)
	const [users, setUsers] = useState([])
	const [selectedUser, setSelectedUser] = useState([])
	const [editUserModalIsVisible, setEditUserModalIsVisible] = useState(false)

	const loadUsersAndSetState = () => {
		loadUsers(_users => {
			setUsers(_users.map(DbObj.ofDb))
			// setSelectedUser(DbObj.ofDb(_users[0]))
		})
	}

	useEffect(() => {
		console.log('AdminUsersPage.useEffect')
		loadUsersAndSetState()
	}, [backendUrl])

	/** Update a user in the local state */
	const setUser = (user) => {
		const updatedUsers = users.map(u => {
			if (u.id === user.id) {
				return { ...user }
			} else {
				return u
			}
		})

		console.log('updatedUsers', updatedUsers)
		setUsers(updatedUsers)
	}

	const saveUser = (newOrUpdatedUser, onSuccess) => {
		if (newOrUpdatedUser.id) {
			updateUser(newOrUpdatedUser, onSuccess)
		} else {
			createUser(newOrUpdatedUser, (newUser) => {
				loadUsersAndSetState()
				onSuccess && onSuccess(newUser)
			})
		}
	}

	const updateUser = (user, onSuccess) => {
		console.log('updateUser', user)

		return axios.put(`${backendUrl}/users/${user.id}`, DbObj.toDb(user))
			.then(function (response) {
				const updatedUser = response.data
				console.log('user updated', updatedUser)

				// update the state for one user
				setUser(DbObj.ofDb(updatedUser))
				onSuccess && onSuccess(updatedUser)
			})
			.catch(function (error) {
				console.log('Error updating user', error);
			})
	}

	const handleActionClick = (user, index, actionName) => {
		// console.log('handleActionClick', actionName, user)

		if (actionName === "EDIT") {
			// user._objStatus = DbObjStatus.CHANGED // No need to set since we are using modal now
			setUser(user)
			setSelectedUser(user)
			setEditUserModalIsVisible(true)
		} else if (actionName === "SAVE") {
			saveUser(user)
		} else if (actionName === "CANCEL") {
			user._objStatus = DbObjStatus.DB
			setUser(user)
			loadUsersAndSetState()
		} else if (actionName === "DELETE") {
			deleteUser(user, (deletedUser) => {
				loadUsersAndSetState()
			})
		} else if (actionName === "CLEAR") {
			const updatedUsers = [...users]
			updatedUsers.splice(index, 1)
			setUsers(updatedUsers)
		}

	}

	const renderAction = (user, index, userValidation) => {
		if (user._objStatus === DbObjStatus.DB) {
			// return <button onClick={() => handleActionClick(user, "EDIT")}>Edit</button>
			return undefined
		} else if (user._objStatus === DbObjStatus.CHANGED) {
			return (
				<div style={{display: 'flex', flexDirection: 'row', columnGap: 10 }}>
					<button disabled={user.email === "" || userValidation.isValid !== true} onClick={() => handleActionClick(user, index, index, "SAVE")}>Save</button>
					<button onClick={() => handleActionClick(user, index, "CANCEL")}>Cancel</button>
				</div>
			)
		}
	}

	const onChange = (user, e) => {
		const fieldName = e.target.name
		const fieldValue = e.target.value
		console.log(fieldName, '=', fieldValue)

		// if (fieldName === 'role') {
		user._objStatus = DbObjStatus.CHANGED
		user[fieldName] = fieldValue
		// }

		setUser(user)
	}

	const handleNewUserClicked = () => {
		const newUser = {
			_objStatus: DbObjStatus.CHANGED,
			displayName: "",
			email: "",
			role: UserRole.Viewer
		}

		// const updatedUsers = [
		// 	...users,
		// 	newUser
		// ]
		// setUsers(updatedUsers)

		setSelectedUser(newUser)
		setEditUserModalIsVisible(true)
	}

	/** Check if the user fields are valid for various conditions */
	const validateUser = (user) => {
		const allEmails =
			users
				.filter(u => u.id !== user.id) // exclude current user
				.filter(u => !!u.id) // exclude new draft users
				.map(u => u.email.toLowerCase())

		if (allEmails.includes(user.email.toLowerCase())) {
			return {
				isValid: false,
				error: `A user with email address ${user.email} already exists.`
			}
		} else {
			return {
				isValid: true
			}
		}
	}

	const renderTableRow = (user, index) => {
		var rows = [] // we may return multiple rows

		const userValidation = validateUser(user)

		rows.push(
			<tr key={user.id} className="align-middle">
				<td>{index + 1}</td>
				<td >
					{user._objStatus === DbObjStatus.CHANGED ? (
						<Form.Control
							name="displayName"
							type="text"
							className="bg-white p-1"
							placeholder="Full Name"
							value={user.displayName}
							onChange={(e) => onChange(user, e)}
						/>
					) : <span>{user.displayName}</span>}
				</td>
				<td>
					{/* Don't allow changing admin email */}
					{user._objStatus === DbObjStatus.CHANGED && user.role !== 'ADMIN' ? (
						<Form.Control
							name="email"
							type="email"
							className="bg-white p-1"
							placeholder="Email address"
							value={user.email}
							onChange={(e) => onChange(user, e)}
						/>
					) : <span>{user.email}</span>}
				</td>
				<td>
					{/* Don't allow changing admin role */}
					{user._objStatus === DbObjStatus.CHANGED && user.role !== 'ADMIN' ? (
						<Form.Select
							required
							name="role"
							value={user.role}
							className="bg-white p-1"
							onChange={(e) => onChange(user, e)}>
							<option value="" disabled={true}>---</option>
							<option value={UserRole.Viewer}>Viewer</option>
							<option value={UserRole.Designer}>Designer</option>
							<option value={UserRole.Reviewer}>Reviewer</option>
						</Form.Select>
					) : <span>{user.role}</span>}
				</td>
				<td>
					<Row className="p-0 m0-0">
						<Col xs="6" hidden={user._objStatus !== DbObjStatus.DB}>
							<Dropdown>
								<Dropdown.Toggle size="sm" variant="outline-dark" id="dropdown-basic">
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item onClick={() => handleActionClick(user, index, "EDIT")}>Edit</Dropdown.Item>
									{/* {config.isDebugMode ? <Dropdown.Item onClick={() => handleActionClick(user, index, "DELETE")}>Delete</Dropdown.Item> : undefined} */}
									{!user.id ? <Dropdown.Item onClick={() => handleActionClick(user, index, "CLEAR")}>Clear</Dropdown.Item> : undefined}
								</Dropdown.Menu>
							</Dropdown>
						</Col>
						<Col xs="6">
							{renderAction(user, index, userValidation)}
						</Col>
					</Row>
				</td>
			</tr>
		)

		if (userValidation.isValid === false) {
			rows.push(
				<tr key={`${user.id}-error`}>
					<td colspan="5" className="text-danger" style={{ paddingBottom: 30 }}>{userValidation.error}</td>
				</tr>
			)
		}

		return rows
	}

	const ResetPasswordModal = ({ user, show, handleClose, handleSave }) => {
		const [ userBeingEdited, setUserBeingEdited ] = useState(user)
		const [ showPasswordField, setShowPasswordField ] = useState(!user.id)

		const onChange = (e, fieldName) => {
			// const fieldName = e.target.name
			const fieldValue = e.target.value
			console.log("onChange", fieldName, fieldValue)

			setUserBeingEdited(user1 => {
				const updatedUser = { ...user1 }
				updatedUser[fieldName] = fieldValue
				return updatedUser
			})
		}

		const onSaveClick = () => {
			const userToSave = { ...userBeingEdited }

			if (showPasswordField === false) {
				delete userToSave.password
			}

			handleSave(userToSave)
		}

		return (
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{user.id ? 'Edit User' : 'Create New User'}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label>F&#8204;ull N&#8204;ame:</Form.Label>
							<Form.Control
								autocorrect="off" spellcheck="false" autocomplete="off"
								readonly onfocus="this.removeAttribute('readonly');"
								type="text"
								placeholder="Full Name"
								value={userBeingEdited.displayName}
								onChange={e => onChange(e, "displayName")}
								autoFocus
							/>
						</Form.Group>

						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label>Em&#8204;ail&#8204; Add&#8204;ress:</Form.Label>
							<Form.Control
								autocorrect="off" spellcheck="false" autocomplete="off"
								readonly onfocus="this.removeAttribute('readonly');"
								type="email"
								placeholder="name@example.com"
								value={userBeingEdited.email}
								onChange={e => onChange(e, "email")}
								autoFocus
							/>
						</Form.Group>

						<Form.Group as={Row}>
							<Form.Label column md={6}>Edit Password:</Form.Label>
							<Form.Check
								className="align-self-center col-md-6"
								type="switch"
								name="subCategories"
								checked={showPasswordField}
								onChange={e => setShowPasswordField(e.target.checked === true)}
								/>
						</Form.Group>

						{showPasswordField === true ? (
							<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
								<Form.Label>Pa&#8204;ssw&#8204;ord:</Form.Label>
								<Form.Control
									autocorrect="off" spellcheck="false" autocomplete="off"
									readonly onfocus="this.removeAttribute('readonly');"
									type="password"
									name="we_dont_want_autofill"
									placeholder="Leave blank to keep the same password"
									value={userBeingEdited.password}
									onChange={e => onChange(e, "password")}
									autoFocus
								/>
							</Form.Group>
						) : undefined }
					</Form>

				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-danger" onClick={handleClose}>
						Close
					</Button>
					<Button variant="outline-dark" onClick={onSaveClick}>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		)
	}

	return (
		<Container>
			<Row className="row h-10">
				<Header />
			</Row>
			<Row className="row h-90 p-2">
				<h3>Users</h3>
			</Row>

			<Row className="row h-90 p-2">
				<Table striped hover>
					<thead>
						<tr>
							<th>#</th>
							<th>Name</th>
							<th>Email</th>
							<th>Role</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{users.flatMap(renderTableRow)}
					</tbody>
				</Table>

				<Button
					className="btn-sm w-100"
					variant="outline-dark"
					onClick={() => handleNewUserClicked()}>Add New User</Button>
			</Row>
			<ResetPasswordModal
				show={editUserModalIsVisible}
				user={selectedUser}
				handleClose={() => setEditUserModalIsVisible(false)}
				handleSave={updatedUser => saveUser(updatedUser, () => setEditUserModalIsVisible(false))}
				/>
		</Container>
	)
}