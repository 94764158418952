import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from 'react-chartjs-2';
import { scaleColours, scaleValuesGHGstore } from './GlobalVars'
import { loadAllCalcParams } from "./AdminCalcsPage";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);

const ChartFilledSingleCircle = ({ locationType, ghgValue, label, delay, topText, bottomText }) => {
	const [scaleValuesGHGstore, setScaleValuesGHGstore] = useState({})
	const [scaleColour, setScaleColour] = useState("#dddddd")
	const [scaleText, setScaleText] = useState("")

	if (typeof ghgValue === "string") {
		// console.log("chart getting string")
		ghgValue = 1;
	}
	else {
		ghgValue = parseInt(ghgValue)
	}

	useEffect(() => {
		loadAllCalcParams(calcParams => {
			const prefix =
				locationType === 'Counter'
					? 'RetailCounterEmissions'
					: locationType === 'Store'
						? 'RetailStoreEmissions'
						: undefined

			const emParams = calcParams.filter(c => c.name.indexOf(prefix) === 0)

			let updatedScaleValues = {
				low: emParams.find(c => c.name.indexOf('Low') !== -1)?.currentValue,
				medium: emParams.find(c => c.name.indexOf('Medium') !== -1)?.currentValue,
				high: emParams.find(c => c.name.indexOf('High') !== -1)?.currentValue,
			}

			setScaleValuesGHGstore(updatedScaleValues)
			console.log("got calc scales");

			console.log("ghgValue")
			console.log(ghgValue)
		
			//let colour = "#dddddd";
			//let ghgText = "";
		
			//inverted colour scale for GHG values
			if (ghgValue <= updatedScaleValues.low) {
				//colour = scaleColours.high;
				setScaleColour(scaleColours.high)
				setScaleText("Low")
				console.log(scaleColour)
			}
			else if (ghgValue > updatedScaleValues.low && ghgValue < updatedScaleValues.medium) {
				setScaleColour(scaleColours.medium)
				setScaleText("Medium")
				console.log(scaleColour)
			}
			else if (ghgValue >= updatedScaleValues.medium) {
				setScaleColour(scaleColours.low)
				setScaleText("High")
				console.log(scaleColour)
			}
			else {
				console.log("no colour set")
				console.log(ghgValue, scaleValuesGHGstore)
			}
		})
	}, []);



	const data = {
		labels: [
			label,
			label
		],
		datasets: [
			{
				label: '%',
				data: [ghgValue],
				backgroundColor: [
					scaleColour,
					"#FFFFFF"
				],

				borderWidth: 0,
			},
		],
	};

	const options = {
		height: 150,
		width: 150,
		circumference: 360,
		responsive: false,
		maintainAspectRatio: true,
		cutout: 0,
		animation: {
			easing: "easeInCubic",
			duration: 3000,
			delay: delay
		},
		hover: {
			mode: null
		},
		plugins: {
			title: {
				display: false,
				text: label,
				position: 'bottom'
			},
			legend: {
				display: false,
			},
			tooltip: {
				enabled: false
			},
			datalabels: false

		},
	};

	return (
		<div>
			<Row>
				<Col className="d-flex justify-content-center align-items-center">
					<div className="fadeText2s" style={{ position: "absolute" }}>
						{topText ? (
							<>
								<h6 className="text-center">{topText}</h6>
								<h4 className="text-center">{ghgValue}</h4>
							</>
						) : (
							<>
								<h4 className="text-center">{ghgValue}</h4>
								<h6 className="text-center">{scaleText}</h6>
							</>
						)
						}
					</div>
					<Doughnut options={options} data={data} />
				</Col>
			</Row>
			<Row className="pt-2">
				<Col>
					<h6 className="text-center">{bottomText || "GHG Emissions"}</h6>
					<p className="text-center text-muted"><small>(kgCO2e per sqm)</small></p>
				</Col>
			</Row>
		</div>
	);
};

export default ChartFilledSingleCircle;