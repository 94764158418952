import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from 'react-chartjs-2';
import { singleDonutColour } from './GlobalVars'
import { scaleColours } from './GlobalVars'

ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);

const ChartDonutSingleVal = ({ percentage, label, delay }) => {
	let colour = singleDonutColour;
	let rotateNegative = 0;
	let colourArray = [
		colour, "#DDDDDD"
	];
	let dataArray = [percentage, (100 - percentage)];

	if (percentage < 0) {
		console.log("negative");
		console.log(percentage);
		percentage = (percentage * - 1)
		console.log(percentage);
		rotateNegative = 360 - (((percentage)/100) * 360)
		console.log(rotateNegative)

		dataArray = [(100 - percentage),percentage];
		colourArray = [
			"#DDDDDD", scaleColours.low
		];
	}
	else {
		console.log("positive");
		dataArray = [percentage, (100 - percentage)];
		//percentage = ;
		rotateNegative = 0;
		colourArray = [
			scaleColours.high, "#DDDDDD"
		];
	}

	const data = {
		labels: [label, label],
		datasets: [
			{
				label: '%',
				data: dataArray,
				backgroundColor: colourArray,
				borderColor: [
					'#FFFFFF',
				],
				borderWidth: 2,
			},
		],
	};

	const options = {
		circumference: 360,
		responsive: false,
		maintainAspectRatio: true,
		cutout: 50,
		//rotation: rotateNegative,
		animation: {
			easing: "easeInCubic",
			duration: 3000,
			delay: delay,
			//animateRotate: false
		},
		hover: {
			mode: null
		},
		plugins: {
			title: {
				display: false,
				text: label,
				position: 'bottom'
			},
			legend: {
				display: false,
			},
			tooltip: {
				enabled: false
			},
			datalabels: false

		},
	};

	return (
		<Doughnut options={options} data={data} />
	);
};

export default ChartDonutSingleVal;