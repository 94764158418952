import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './../index.css';
import { Store, updateStore } from "./AdminStoresPage";
import { useSelector } from "react-redux"

function ProjectsAddProjectCommentModal(props) {
	const { store, onSuccess } = props
	const [finalComments, setFinalComments] = useState("")
	const [status, setStatus] = useState("")
	const user = useSelector((state) => state.user)

	const handleProjectCommentSubmit = (e) => {
		e.preventDefault()

		const updatedStore = {
			...store,
			reviewComment: finalComments,
			reviewerId: user.id,
			storeStatus: Store.Status.REVIEWED,
			reviewRequired: 0
		}

		updateStore(updatedStore, () => {
			props.onHide()
			setFinalComments("")
			onSuccess && onSuccess(updatedStore)
		}, err => {
			setStatus(err)
		})
	}

	return (
		<Modal
			{...props}
			size="lg"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>Complete Store Review</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form className="col-12" autoComplete="off" onSubmit={handleProjectCommentSubmit}>
					<Form.Group className="mb-md-12 d-flex align-items-center mb-4">
						<Col md={12}>
							<Form.Label>
								Final Comments
					</Form.Label>
							<Form.Control
								required
								id="projectComment"
								name="projectComment"
								as="textarea"
								rows={3}
								placeholder="Provide closing comments to complete the store review."
								value={finalComments}
								onChange={ e => setFinalComments(e.target.value) }
							/>
						</Col>
					</Form.Group>
					<Container>
						<Row className="d-flex align-items-center justify-content-center">
							<Button
								type="submit"
								variant="outline-dark"
								className="w-50"
							>
								Submit
							</Button>
						</Row>
						<Row className="d-flex align-items-center justify-content-center pt-4">
							<Button
								// size="sm"
								type="button"
								variant="outline-danger"
								onClick={props.onHide}
								className="w-50"
							>
								Cancel
							</Button>
						</Row>
						<Row>
							<p style={{ marginTop: 20 }} className="text-center">{status}</p>
						</Row>
					</Container>
				</Form>
			</Modal.Body>
		</Modal >
	);
}

export default ProjectsAddProjectCommentModal;