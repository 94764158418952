import React, { useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { makeStorePath } from './ProjectSummaryPage'
import { Store, copyStoreMaterialsFromScorecardType, updateStore } from './AdminStoresPage'
import { AccessControl } from "../AccessControl"
import { config } from "../config"
import ProjectsAddProjectCommentModal from "./ProjectsAddProjectCommentModal"
import { setStoreLifecycleModalInfo } from "../store/actions"

const getDropdowns = (user, store) => {
	return [
		{
			name: "MARK_AS_DRAFT",
			title: "Mark as Draft",
			visible: store.storeStatus !== Store.Status.DRAFT && AccessControl.storeCanMarkAsDraft(user)
		},

		{
			name: "SUBMIT_FOR_REVIEW",
			title: "Submit for Review",
			visible: store.storeStatus !== Store.Status.AWAITING_REVIEW && AccessControl.storeCanSubmitForReview(user)
		},

		{
			name: "MARK_AS_REVIEWED",
			title: "Mark as Reviewed",
			visible: store.storeStatus === Store.Status.AWAITING_REVIEW && AccessControl.storeCanMarkAsReviewed(user)
		},

		{
			name: "INFORM_DESIGNER",
			title: "Inform Designer",
			visible: store.storeStatus === Store.Status.REVIEWED && AccessControl.storeCanMarkAsReviewed(user)
		},

		{
			name: "PROGRESS_TO_AS_BUILT",
			visible: store.scorecardType === Store.ScorecardType.CONCEPT && (AccessControl.storeCanProgressToAsBuilt(user, store) || AccessControl.isAdmin(user)),
			disabled: true,
			title: "Progress to As-built"
		},

		{
			name: "PROGRESS_TO_AS_DECOMMISSIONED",
			visible: store.scorecardType === Store.ScorecardType.AS_BUILT && (AccessControl.storeCanProgressToDecommission(user, store) || AccessControl.isAdmin(user)),
			disabled: true,
			title: "Decommission Store"
		},

		{
			kind: 'DIVIDER',
			visible: AccessControl.storeCanChangeDesigner(user)
		},

		// {
		// 	name: "CHANGE_DESIGNER",
		// 	visible: AccessControl.storeCanChangeDesigner(user),
		// 	disabled: true,
		// 	title: "Change Designer"
		// },

		{
			name: "DOWNLOAD_STORE_INFO",
			title: "Download Store Data (CSV)",
			visible: AccessControl.storeCanDownloadData(user)
		},

		{
			name: "DOWNLOAD_STORE_MATERIALS_CURRENT",
			title: "Download Store Materials - Current (CSV)",
			visible: AccessControl.storeCanDownloadData(user)
		},

		{
			name: "DOWNLOAD_STORE_MATERIALS_ALL",
			title: "Download Store Materials - All (CSV)",
			visible: AccessControl.isAdmin(user)
		},
	]
}

export const updateStoreLifecycleModalInfo = (show, title, textLines, buttonTitleOk, onOk) => {
	return setStoreLifecycleModalInfo({
		show,
		title,
		textLines,
		buttonTitleOk,
        buttonTitleCancel: "Cancel",
		onOk
	})
}

export const StoreNavBar = ({ store, setStore, pageName, setPageName }) => {
	console.log('StoreNavBar')
	const user = useSelector((state) => state.user)
	const history = useHistory()
	const [selectProjectCommentModal, setSelectProjectCommentModal] = useState(false)	
	const dropdowns = getDropdowns(user, store)

	const dispatch = useDispatch()

	const storeId = store.id

	const btns = [
		{ name: "VIEW", title: "View", action: "view", visible: AccessControl.storeCanView(user) },
		{ name: "EDIT", title: "Edit", action: "edit", visible: AccessControl.storeCanEdit(user, store) },
		{ name: "MATERIALS", title: "Materials", action: "materials", visible: AccessControl.storeCanView(user) },
		{ name: "REVIEW", title: "Review", action: "review", visible: AccessControl.storeCanViewReviewInfo(user) },
		{ name: "LIFECYCLE", title: "Lifecycle", action: "lifecycle", visible: AccessControl.storeCanViewReviewInfo(user) },
	]

	const handleOnClick = btn => {
		history.push(makeStorePath(btn.action, storeId))
		// setPageName(btn.action)
	}

	const onProjectCommentModalSubmit = () => {
		// save the final comment to the store
	}

	const onProjectCommentModalSuccess = (store) => {
		// Already done by modal. Nothing more to do.
		// store.storeStatus = Store.Status.REVIEWED
		// store.reviewRequired = 0
		// updateStore(store, setStore)

		setStore(store)
	}

	const showModal = (fromSc, toSc, onOk) => {
		dispatch(updateStoreLifecycleModalInfo(true, `Confirm Progress to ${toSc}`, [
			`The store will now be updated for the ${toSc} stage of the store lifecycle.`,
			`- The scorecard type will be set to ${toSc}.`,
			`- The store's ${fromSc} materials will be frozen, and copied to the ${toSc} store.`,
			`- Material updates made to the ${toSc} store will not affect the ${fromSc} store materials.`,
			` `,
			`The designer for the ${toSc} store may be set below`,
			`- If the designer is changed, the previous designer will have read-only access to the store.`,
			`- The previous designer will not be abled to edit the store or materials.`,
			` `,
			` `,
			`Please confirm whether to apply these updates.`
		], "Confirm", (creatorId) => {
			console.log("ok clicked", creatorId)
			onOk && onOk(creatorId)
		}))
	}

	const handleActionClick = (action) => {
		// console.log('store.id', store.id)
		// console.log('store.storeStatus', store.storeStatus)

		if (action === "MARK_AS_DRAFT") {
			store.storeStatus = Store.Status.DRAFT
			store.reviewRequired = 0
			updateStore(store, setStore)
		} else if (action === "SUBMIT_FOR_REVIEW") {
			store.storeStatus = Store.Status.AWAITING_REVIEW
			store.reviewRequired = 1
			updateStore(store, setStore)
		} else if (action === "MARK_AS_REVIEWED") {
			setSelectProjectCommentModal(true)
		} else if (action === "INFORM_DESIGNER") {
			const bodyText = "Hi designer\n\nYour store review has been completed.\n"
			const body = encodeURIComponent(bodyText)
			window.location.href = `mailto:designer@aesop.com?subject=Review%20Complete&body=${body}`
		} else if (action === "DOWNLOAD_STORE_INFO") {
			const btn = document.getElementById("download-store-info-csv")
			btn.click()
		} else if (action === "DOWNLOAD_STORE_MATERIALS_CURRENT") {
			const btn = document.getElementById("download-store-materials-csv-current")
			btn.click()
		} else if (action === "DOWNLOAD_STORE_MATERIALS_ALL") {
			const btn = document.getElementById("download-store-materials-csv-all")
			btn.click()
		} else if (action === "PROGRESS_TO_AS_BUILT") {
			showModal("Concept", "As-Built", (designerId) => {
				const designerIdClean = Number(designerId)
				var updatedStore = { ...store }
				updatedStore = Store.setDesigner(Store.ScorecardType.AS_BUILT, designerIdClean, updatedStore)
				updatedStore.scorecardType = Store.ScorecardType.AS_BUILT

				updateStore(updatedStore, (store) => {
					setStore(store)
					copyStoreMaterialsFromScorecardType(storeId, Store.ScorecardType.CONCEPT, () => {
						history.push(makeStorePath("lifecycle", storeId))
					})
				})
			})
		} else if (action === "PROGRESS_TO_AS_DECOMMISSIONED") {
			showModal("As-Built", "Decommissioned", (designerId) => {
				const designerIdClean = Number(designerId)
				var updatedStore = { ...store }
				updatedStore = Store.setDesigner(Store.ScorecardType.DECOMMISSIONED, designerIdClean, updatedStore)
				updatedStore.scorecardType = Store.ScorecardType.DECOMMISSIONED

				updateStore(updatedStore, (store) => {
					setStore(store)
					copyStoreMaterialsFromScorecardType(storeId, Store.ScorecardType.AS_BUILT, () => {
						history.push(makeStorePath("lifecycle", storeId))
					})
				})
			})
		}
	}

	return (
		<>
			{/* <h2 className="pt-0 pb-3">{store.storeName}</h2> */}
			<Nav fill variant="tabs" defaultActiveKey={pageName}>
				<ProjectsAddProjectCommentModal
					show={selectProjectCommentModal}
					store={store}
					onSuccess={onProjectCommentModalSuccess}
					onHide={() => setSelectProjectCommentModal(false)}
				/>

				{btns
					.filter(btn => btn.visible)
					.map(btn => {
					return (
						<Nav.Item key={btn.name}>
							<Nav.Link disabled={!storeId}
								active={btn.action === pageName}
								variant="outline-dark"
								eventKey={btn.name}
								// href={makeStorePath(btn.action, storeId)} // reloads full page
								onClick={() => handleOnClick(btn)}
							>{btn.title}</Nav.Link>
						</Nav.Item>
					)
				})}

				{dropdowns.filter(dd => dd.visible).length > 0 &&
					<NavDropdown title="" id="nav-dropdown">
						{dropdowns.map((dd, index) => {
							if (dd.kind === 'DIVIDER') {
								return <NavDropdown.Divider key="divider" hidden={!dd.visible} />
							} else {
								return (
									<NavDropdown.Item
										key={dd.name}
										hidden={!dd.visible}
										eventKey={index}
										onClick={() => handleActionClick(dd.name)}>
										{dd.title}
									</NavDropdown.Item>
								)
							}
						})}
					</NavDropdown>}

				{ config.isDebugMode ? <div style={{ backgroundColor: 'blue', color: 'white', fontWeight: 500, padding: 6 }}>{store.storeStatus}</div> : undefined }
			</Nav>
		</>
	)
}