import React, { useEffect, useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import axios from "axios";
import { useSelector } from 'react-redux';
import { formatScore } from '../HelperFunctions'

function OverviewTopDesignersTable(props) {
	const backendUrl = useSelector((state) => state.backendUrl);
	const [topDesigners, setTopDesigners] = useState([]);
	const [topStores, setTopStores] = useState([]);

	useEffect(() => {

		//get global scores
		axios.get(`${backendUrl}/topDesigners/5`)
			.then(function (response) {
				var tempDesigners = response.data;

				while (tempDesigners.length < 5) {
					tempDesigners.push({designer : "-"})
				};

				setTopDesigners(tempDesigners)

			})
			.catch(function (error) {
				console.log(error);
			});

		axios.get(`${backendUrl}/topStoreScores/5`)
			.then(function (response) {
				var tempStores = response.data;
				
				while (tempStores.length < 5) {
					tempStores.push({storeName : "-"})
				};
				setTopStores(tempStores)

			})
			.catch(function (error) {
				console.log(error);
			});

	}, []);

	return (
		<article>
			<Row className="gx-5 py-3">
				<Col md={6} className="py-3">
					<Row className="border border-black">
						<Col>
							<Row className="py-3">
								<Col>
									<h6 className="border-bottom border-dark">Top Designers</h6>
								</Col>
							</Row>
							<Table borderless striped size="sm" className="">
								<thead>
									<tr>
										<th>Name</th>
										<th className="text-center">Designer Score</th>
									</tr>
								</thead>
								<tbody>
									{topDesigners ? (topDesigners.map((user) => (
										<tr>
											<td>{user.designer}</td>
											<td className="text-center">{formatScore(user.averageScore)}</td>
										</tr>
									))) : ""}
								</tbody>
							</Table>
						</Col>
					</Row>
				</Col>

				<Col md={6} className="py-3">
					<Row className="border border-black">
						<Col>

							<Row className="py-3">
								<Col>
									<h6 className="border-bottom border-dark">Stores</h6>
								</Col>
							</Row>
							<Table borderless striped size="sm" className="">
								<thead>
									<tr>
										<th>Name</th>
										<th className="text-center">Store Score</th>
									</tr>
								</thead>
								<tbody>
									{topStores ? (topStores.map((store) => (
										<tr>
											<td>{store.storeName}</td>
											<td className="text-center">{formatScore(store.totalScore)}</td>
										</tr>
									))) : ""}
								</tbody>
							</Table>
						</Col>
					</Row>
				</Col>
			</Row>
		</article>
	);
}

export default OverviewTopDesignersTable;