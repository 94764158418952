import React, { useEffect, useState } from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { Store, copyStoreMaterialsFromScorecardType, getStoreScoreForScorecardType, loadStore, scorecardTypes } from "./AdminStoresPage"
import Table from 'react-bootstrap/Table'
import { createMaterialInstance, deleteMaterialInstance, getMaterialInstanceCount, getMaterialInstancesForStore, updateMaterialInstance } from "./AdminMaterialsPage"
import { formatGhge, formatRelImprov, formatScore } from "../HelperFunctions"
import { getCalcLookupData } from "./AdminCalcsPage"
import { config } from "../config"
import Form from 'react-bootstrap/Form'
import { endOfLifeAssessment } from "./ProjectsNewMaterialModal"
import { Col } from "react-bootstrap"
import { loadMaterialInstancesForScorecardType } from "./ProjectPhasePage"

export const StoreLifecycleInfo = ({ storeId, setStore, materialInstancesByScorecard, setMaterialInstancesByScorecard  }) => {
	const scorecardInfoListInitial = scorecardTypes.map(st => {
		return {
			scorecardType: st.name,
			scorecardTypeTitle: st.title,
			materialInstanceCount: undefined,
			ghgeEmissions: undefined,
			storeTotalScore: undefined
		}
	})
	
	const [scorecardInfoList, setScorecardInfoList] = useState(scorecardInfoListInitial)

	const updateScorecardInfoListItem = (scorecardType, updatedFields) => {
		setScorecardInfoList(currentScorecardInfoList => {
			const updated = currentScorecardInfoList.map(si => {
				if (si.scorecardType === scorecardType) {
					return {
						...si,
						...updatedFields
					}
				} else {
					return si
				}
			})
			
			return updated
		})
	}

	const loadAllScorecardsInfo = () => {
		// get all the scores and material counts
		scorecardTypes.forEach(sci => {
			// console.log("scorecardType", sci)
			const scorecardType = sci.name
			getStoreScoreForScorecardType(storeId, scorecardType, ({ totalScore, ghgeEmissions }) => 
				updateScorecardInfoListItem(scorecardType, { storeTotalScore: totalScore, ghgeEmissions }))

			getMaterialInstanceCount(storeId, scorecardType, count => 
				updateScorecardInfoListItem(scorecardType, { materialInstanceCount: count }))

			loadMaterialInstancesForScorecardType(storeId, scorecardType, setMaterialInstancesByScorecard)
		})
	}

	useEffect(() => {
		// console.log('ProjectPhasePage.useEffect', storeId)

		loadStore(storeId, setStore)
		loadAllScorecardsInfo()
	}, [ storeId ])
	
	const handleClickSubmit = (action, e, scorecardType) => {
		if (action === "COPY") {
			console.log("TODO: copy materials from", scorecardType)
			copyStoreMaterialsFromScorecardType(storeId, scorecardType, () => {
				loadAllScorecardsInfo()
			})
		} else if (action === "DELETE") {
			const matInstForStList = materialInstancesByScorecard[scorecardType]
			console.log("Deleting material instances", scorecardType, matInstForStList)
			matInstForStList.forEach(matInst => deleteMaterialInstance(matInst.id, () => {
				loadAllScorecardsInfo()
			}))
		}
	}

	return (
		<>
			<Row className="py-3">
				<h4 className="border-bottom border-dark">Store Lifecycle Information</h4>
			</Row>
			<Container className="py-3">
				<Row>
					<Table responsive>
						<thead>
							<th>Scorecard Type</th>
							<th>Materials</th>
							<th>Embodied GHGE</th>
							<th>Score</th>
							{config.isDebugMode && <th style={{backgroundColor: "blue", color: "white"}}>Actions</th>}
						</thead>
						<tbody>
							{scorecardInfoList.map(sti => {
								const nextScorcardTypeInfo = Store.getScorecardTypeInfoNext(sti.scorecardType)
								const nextScorcardTypeMaterialInstanceCount = nextScorcardTypeInfo && scorecardInfoList.find(s => s.scorecardType === nextScorcardTypeInfo.name).materialInstanceCount
								
								return (
									<tr key={sti.scorecardType}>
										<td>{sti.scorecardTypeTitle}</td>
										<td>{sti.materialInstanceCount}</td>
										<td>{formatGhge(sti.ghgeEmissions)} {sti.ghgeEmissions !== undefined ? <span style={{ color: 'grey' }}>kgCO2e</span> : ''}</td>
										<td>{formatScore(sti.storeTotalScore)}</td>
										{config.isDebugMode && (
											<td>
												{/* <span>{nextScorcardTypeInfo && nextScorcardTypeInfo.title} = {nextScorcardTypeMaterialInstanceCount}</span> */}
												{nextScorcardTypeInfo && nextScorcardTypeMaterialInstanceCount === 0 ?
												<small>
													<a
														className="btn btn-sm btn-outline btn-outline-primary w-100"
														href="#"
														onClick={(e) => handleClickSubmit("COPY", e, sti.scorecardType)}>
															Copy Materials to {nextScorcardTypeInfo.title}
													</a>
												</small>
												: undefined}

												{config.isDebugMode ? (<small>
													<a
														className="btn btn-sm btn-outline btn-outline-primary w-100"
														href="#"
														onClick={(e) => handleClickSubmit("DELETE", e, sti.scorecardType)}>
														Delete Materials
													</a>
												</small> )
												: undefined}

											</td>
										)}
									</tr>
								)
							})}
						</tbody>
					</Table>
				</Row>
			</Container>
		</>
	)
}