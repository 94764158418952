import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ProjectsAddMaterialCommentModal from "./ProjectsAddMaterialCommentModal";
import { scaleValues, scaleColours, scaleLevels, scaleColoursText, scaleColoursBG, endOfLifeAssessmentDets, singleDonutColour } from './GlobalVars';
import './../index.css';
import { calcTotalGhge, formatGhge, formatScore, formatUnitForDisplay } from '../HelperFunctions'
import { AccessControl } from "../AccessControl"
import { config } from "../config";

const TableGetMaterialsByStore = ({ storeId, userId }) => {
	const user = useSelector((state) => state.user)
	const backendUrl = useSelector((state) => state.backendUrl);
	const [materialsList, setMaterialsList] = useState([]);
	const [selectedMaterialData, setSelectedMaterialData] = useState(null);
	const [selectedMaterialComments, setSelectedMaterialComments] = useState([]);
	const [materialModal, setMaterialModal] = useState(false);
	const [materialId, setMaterialId] = useState(null);

	useEffect(() => {
		axios.get(`${backendUrl}/materialInstancesByStore/${storeId}`)
			.then(function (response) {
				// console.log(response);
				setMaterialsList(response.data)
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);


	function handleClickSubmit(e, matId) {
		e.preventDefault()

		axios.get(`${backendUrl}/allMaterialComments/${matId}`)
			.then(function (response) {
				const setId = () => { setMaterialId(matId) }
				setId();
				console.log(matId)
				setSelectedMaterialData(materialsList.filter((material) => material.id == e.target.id).map(m => m))
				console.log(response);
				setSelectedMaterialComments(response.data)
			})
			.then(() => {
				setMaterialModal(true)
			})
			.catch(function (error) {
				console.log(error);
			})
	}


	function getQuantityColor(material) {
		if (materialsList) {
			const ghgeTotAll = materialsList.map(calcTotalGhge).map(g => g.value)
			const ghgeTotMin = Math.min(...ghgeTotAll)
			const ghgeTotMax = Math.max(...ghgeTotAll)
			const ghgeDiff = ghgeTotMax - ghgeTotMin
			
			//green - 164,180,137 - #a4b489 - hsl 0.23	0.22	0.62
			//red - 239,166,152 - #efa698
			var totGhge = calcTotalGhge(material).value
			const ratio = (totGhge - ghgeTotMin) / ghgeDiff


			const rgbA = scaleColours.high,
				  rgbB = scaleColours.low;

			const r1 = parseInt(rgbA.substring(1, 3), 16);
			const g1 = parseInt(rgbA.substring(3, 5), 16);
			const b1 = parseInt(rgbA.substring(5, 7), 16);

			const r2 = parseInt(rgbB.substring(1, 3), 16);
			const g2 = parseInt(rgbB.substring(3, 5), 16);
			const b2 = parseInt(rgbB.substring(5, 7), 16);

			const r = Math.round(r1 + (r2 - r1) * ratio);
			const g = Math.round(g1 + (g2 - g1) * ratio);
			const b = Math.round(b1 + (b2 - b1) * ratio);

			return `rgba(${r}, ${g}, ${b}, 1.0)`
		}
		else {
			return `rgba(0, 0, 0, 0.0)`
		}
	}

	const renderScorecardType = (scorecardType) => {
		if (config.isDebugMode) {
			return (
				<span
					style={{
						backgroundColor: scorecardType === "CONCEPT" ? "darkorange" : scorecardType === "AS_BUILT" ? "blue" : "red",
						color: "white",
						fontSize: 12,
						padding: 2,
						marginLeft: 10,
					}}>
					{scorecardType}
				</span>
			)
		} else {
			return undefined
		}
	}

	return (
		<Container>
			<Table hover responsive size="sm">
				<thead>
					<tr>
						<th><small>Name</small></th>
						<th><small>Description</small></th>
						<th><small>Category</small></th>
						<th><small>Certification</small></th>
						<th className="text-center"><small>Embodied GHGE<br />(kgCO2e)</small></th>
						<th className="text-center"><small>Quantity</small></th>
						<th className="text-center"><small>Score</small></th>
						<th className="text-center"><small>Actions</small></th>
					</tr>
				</thead>
				<tbody>
					{materialsList ? (materialsList.map((material) => (
						<tr className="align-middle" key={material.id}>
							<td><small>{material.itemType}{renderScorecardType(material.scorecardType)}</small></td>
							<td><small>{material.materialDescription}</small></td>
							<td><small>{material.subCategory}</small></td>
							<td><small>{material.productCertification}</small></td>

							<td title={`= Quantity * Embodied GHGE per unit\n= ${material.materialQuantity} * ${material.embodiedGHGE}`} className="text-center" style={{ backgroundColor: getQuantityColor(material) }}><small>{formatGhge(material.materialQuantity * material.embodiedGHGE)}</small></td>

							<td className="text-center"><small>{formatGhge(material.materialQuantity)}  {formatUnitForDisplay(material.unit)}</small></td>

							<td className={`
								${material.totalScore <= scaleValues.low ? 'text-center ' + scaleColoursBG.high : ""}
								${material.totalScore > scaleValues.low && material.totalScore < scaleValues.medium ? 'text-center ' + scaleColoursBG.medium : ""}
								${material.totalScore >= scaleValues.medium ? 'text-center ' + scaleColoursBG.low : ""}`}>
								<small>{formatScore(material.totalScore)}</small>
							</td>
							<td>
								{AccessControl.materialInstanceCanComment(user) ? (
									<small><a className="btn btn-sm btn-outline btn-outline-primary w-100" href="#" id={material.id} onClick={(e) => handleClickSubmit(e, material.id)}>Comment</a></small>
								) : (
									// {AccessControl.materialInstanceCanViewComments(user) &&
									<small><a className="btn btn-sm btn-outline-primary w-100" href="#" id={material.id} onClick={(e) => handleClickSubmit(e, material.id)}>View</a></small>
								)}
							</td>
						</tr>
					))) : ""}
				</tbody>
			</Table >
			<Row>

			</Row>
			<ProjectsAddMaterialCommentModal
				show={materialModal}
				onHide={() => setMaterialModal(false)}
				selectMaterialCommentModal={selectedMaterialData}
				materialId={materialId}
				comments={selectedMaterialComments}
				userId={userId}
			/>
		</Container >

	);
}

export default TableGetMaterialsByStore;
