import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import { updateStoreLifecycleModalInfo } from "./StoreNavBar"
import { Button, Container, Form, Row } from "react-bootstrap"
import { loadUsers } from "./AdminUsersPage"
import { UserRole } from "../AccessControl"

export const StoreLifecycleModal = ({ show, info, store }) => {

	const [users, setUsers] = useState([])
	const [creatorId, setCreatorId] = useState(store && store.creatorId)

	const dispatch = useDispatch()

	useEffect(() => {
        loadUsers(users => setUsers(users.filter(u => u.role === UserRole.Designer)))
    }, [])
	
	const onHide = () => {
		dispatch(updateStoreLifecycleModalInfo(false))
	}

	const onButtonClick = (action) => {
		if (action === "CANCEL") {
			onHide()
		} else if (action === "OK") {
			onHide()
			info.onOk && info.onOk(creatorId)
		}
	}

	if (show === false) {
		return null
	}

	const handleStoreCreatorChange = (e, store) => {
        const userId = e.target.value
        setCreatorId(userId)
    }

	return (
		<Modal
			show={show}
			onHide={onHide}
			size="lg"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>{info.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>

				{info.textLines.map((line, i) => {
					return (
						<p key={`line-${i}`}>{line}</p>
					)
				})}

				<Form.Label>New Designer:</Form.Label>
				<Form.Select className="bg-white p-1" value={creatorId} onChange={e => handleStoreCreatorChange(e, store)}>
					<option key="empty" value="" disabled={true}>Select a user</option>
					{users.map(u => (
						<option key={u.id} value={u.id}>{u.displayName}</option>
					))}
				</Form.Select>

			</Modal.Body>
			<Modal.Footer>
				<Container className="pt-5">
					<Row className="d-flex align-items-center justify-content-center">
						<Button
							variant="outline-danger"
							onClick={() => onButtonClick("CANCEL")}
							className="pe-2 w-50"
							>
								{info.buttonTitleCancel}
						</Button>
						<Button
							variant="outline-dark"
							className="ps-2 w-50"
							onClick={() => onButtonClick("OK")}
							>
							{info.buttonTitleOk}
						</Button>
					</Row>
				</Container>
			</Modal.Footer>


		</Modal >
	)
}