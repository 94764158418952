import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import { useSelector, useDispatch } from 'react-redux';
import axios from "axios";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from "./Header";
import ProjectInformationBrief from "./ProjectInformationBrief";
import ProjectScores from "./ProjectScores";
import ProjectSummaryCharts from "./ProjectSummaryCharts";
import TableGetMaterialsByStore from "./TableGetMaterialsByStore";
import ProjectSummaryMaterials from "./ProjectSummaryMaterials";
import { scaleValues, scaleColoursCss, scaleColours, endOfLifeAssessmentDets, singleDonutColour } from './GlobalVars'
import { Button } from "react-bootstrap";
import { useHistory, useLocation, useRouteMatch, useParams } from 'react-router-dom';
import { StoreNavBar } from './StoreNavBar'
import { loadStore } from "./AdminStoresPage";
import { AccessControl } from "../AccessControl";
import Table from 'react-bootstrap/Table'
import { StoreLifecycleInfo } from "./StoreLifecycleInfo";

export const storePathView = '/stores/:storeId/view'
export const storePathEdit = '/stores/:storeId/edit'
export const storePathMaterials = '/stores/:storeId/materials'

export const makeStorePathView = (storeId) => {
	return storePathView.replace(':storeId', storeId)
}

export const makeStorePathEdit = (storeId) => {
	return storePathEdit.replace(':storeId', storeId)
}

export const makeStorePathMaterials = (storeId) => {
	return storePathMaterials.replace(':storeId', storeId)
}

export const makeStorePath = (action, storeId) => {
	return `/stores/${storeId}/${action}`
}

function ProjectSummaryPage({ store, setStore, user }) {
	
	const dispatch = useDispatch();
	const history = useHistory();
	const storeId = store.id
	const userId = user.id

	function editStore(storeId) {
		const path = makeStorePathEdit(storeId)
		history.push(path);
	}

	return (
		<>
			<Row className="py-3">
				<h4 className="border-bottom border-dark">Store Details</h4>
			</Row>
			<ProjectInformationBrief
				storeId={storeId}
			/>

			<Row className="py-3">
				<h4 className="border-bottom border-dark">Store Performance</h4>
			</Row>
			<ProjectSummaryCharts
				store={store}
			/>

			<Row className="pt-3 pb-0 mb-0">
				<h4 className="border-bottom border-dark">Circularity Score</h4>
			</Row>
			<Row className="pb-3 mt-0">
				<ProjectScores
					projectScore={store.totalScore}
					userId={userId}
					storeId={storeId}
				/>
			</Row>

			<StoreLifecycleInfo
				storeId={storeId}
				setStore={setStore}
				materialInstancesByScorecard={{}}
				setMaterialInstancesByScorecard={() => undefined} />

			<Row className="pt-3">
				<h4 className="border-bottom border-dark">Materials</h4>
			</Row>
{/* 			<Row className="py-2">
				<ProjectSummaryMaterials
					userId={userId}
					storeId={storeId}
				/>
			</Row> */}
			<Row className="py-2">
				<TableGetMaterialsByStore
					userId={userId}
					storeId={storeId}
				/>
			</Row>
		</>
	)
}

export default ProjectSummaryPage;