const DEBUGMODE = true;

//used on:
//projectSummaryCharts
//chartFilledSingleCircle
const scaleValues = {
	low: 49,
	medium: 70,
	high: 100
};

const scaleValuesGHGstore = {
	low: 75,
	medium: 120,
	high: 150
};

const scaleValuesGHGcounter = {
	low: 70,
	medium: 110,
	high: 130
};

const scaleLevels = {
	low: "Level A",
	medium: "Level B",
	high: "Level C"
};

const scaleColoursText = {
	low: "text-geranium",
	medium: "text-sun",
	high: "text-rind"
};

const scaleColoursBG = {
	low: "bg-geranium-300",
	medium: "bg-sun-300",
	high: "bg-rind-300"
};

const scaleColours = {
	low: "#efa698",
	medium: "#e4c56e",
	high: "#a4b489"
};

const scaleColoursInv = {
	low: "#efa698",
	medium: "#e4c56e",
	high: "#a4b489"
};

const scaleColoursFive = [
	"#859b61", //geranium-400 --- retained
	"#e4c56e", //sun-300 --- reused
	"#D5B49E", //resurrection-400 --- recycled
	"#C6AA76", //rejuvenate-500 --- part-recycled
	"#ea8876", //rind-400 --- virgin
];

const scaleColoursFiveText = [
	"text-geranium-400", //geranium-400 --- retained
	"text-sun-400", //sun-300 --- reused
	"text-resurrection-500", //resurrection-400 --- recycled
	"text-rejuvenate-500", //rejuvenate-500 --- part-recycled
	"text-rind-400", //rind-400 --- virgin
];

const scaleColoursSeven = [
	"#859b61", //geranium-400 --- Fully recyclable
	"#809791", //hwyl-400 --- Part recyclable
	"#90b3bd", //reverence-400 --- Recycling program
	"#D5B49E", //resurrection-500 --- Finish to remain after decommission
	"#C6AA76", //rejuvenate-500 --- Able to be reused/repurposed
	"#e4c56e", //sun-300 --- Takeback Scheme
	"#ea8876", //rind-400 --- Not recyclable
];

const scaleColoursSevenText = [
	"text-geranium-400", //geranium-400 --- Fully recyclable
	"text-hwyl-400", //geranium-300 --- Part recyclable
	"text-reverence-400", //geranium-200 --- Recycling program
	"text-resurrection-500", //--- Finish to remain after decommission
	"text-rejuvenate-500", //rejuvenate-500 --- Able to be reused/repurposed
	"text-sun-300", //sun-300 --- Takeback Scheme
	"text-rind-400", //rind-400 --- Not recyclable
];

const scaleColoursThree = [
	"#859b61", //geranium-400
	"#D5B49E", //resurrection-400
	"#ea8876", //rind-400
];

const scaleColoursThreeText = [
	"text-geranium-400", //geranium-400
	"text-resurrection-500", //resurrection-400
	"text-rind-400", //rind-400
];

//used on:
//projectSummaryCharts
const endOfLifeAssessmentDets = [
	{ id: 1, key: 'notRecyclable', name: "Not recyclable", value: "0" },
	{ id: 2, key: 'partRecyclable', name: "Part recyclable", value: "0" },
	{ id: 3, key: 'fullyRecyclable', name: "Fully recyclable", value: "0" },
	{ id: 4, key: 'recyclingProgram', name: "Recycling program", value: "0" },
	{ id: 5, key: 'reusable', name: "Able to be reused/repurposed", value: "0" },
	{ id: 6, key: 'toRemain', name: "Finish to remain after decommission", value: "0" },
	{ id: 7, key: 'takeBack', name: "Takeback Scheme", value: "0" }
];

const circularityAssessmentDets = [
	{ key: 'notRecyclable', name: "Not recyclable", value: "0"},
	{ key: 'partRecyclable', name: "Part recyclable", value: "0"},
	{ key: 'finishToRemain', name: "Finish to remain", value: "0"},
	{ key: 'recyclable', name: "Recyclable", value: "0"},
	{ key: 'reusable', name: "Reusable", value: "0"},
];

const singleDonutColour = "#466067";

export { DEBUGMODE, scaleValues, scaleValuesGHGstore, scaleValuesGHGcounter, scaleColoursBG, scaleColours, scaleLevels, scaleColoursText, scaleColoursFive, scaleColoursFiveText, scaleColoursSeven, scaleColoursSevenText, scaleColoursThree, scaleColoursThreeText, endOfLifeAssessmentDets, circularityAssessmentDets, singleDonutColour }