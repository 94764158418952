import React, { useEffect, useState } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend, Title } from 'chart.js';
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from 'react-chartjs-2';
import axios from "axios";
import { useSelector } from 'react-redux';
import { scaleColoursThree, scaleColours, scaleValues } from './GlobalVars'

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, Title, ChartDataLabels);

function OverviewBarChart() {

	const backendUrl = useSelector((state) => state.backendUrl);
	const [sourcingCounts, setSourcingCounts] = useState({});
	const [sourcingCountsTotal, setSourcingCountsTotal] = useState(0);

	useEffect(() => {

		//get global scores
		axios.get(`${backendUrl}/materialCounts`)
			.then(function (response) {

				// get counters
				setSourcingCounts(response.data.manufacturerSourcingCounters)
				// get total to calculate percentage for visualizer
				setSourcingCountsTotal(response.data.manufacturerSourcingCounters.globally +
					response.data.manufacturerSourcingCounters.regionally +
					response.data.manufacturerSourcingCounters.locally)

			})
			.catch(function (error) {
				console.log(error);
			});

	}, []);


	const labels = ['%'];
	const data = {
		labels,
		datasets: [
			{
				label: "Globally",
				data: labels.map(() => Math.round((sourcingCounts.globally / sourcingCountsTotal) * 100)),
				backgroundColor: scaleColoursThree[2],
				stack: 'Stack 0',
				borderColor: '#FFFFFF',
				borderWidth: 2,
				barPercentage: 1.0,
				categoryPercentage: 100,
			},
			{
				label: "Regionally",
				data: labels.map(() => Math.round((sourcingCounts.regionally / sourcingCountsTotal) * 100)),
				backgroundColor: scaleColoursThree[1],
				stack: 'Stack 0',
				borderColor: '#FFFFFF',
				borderWidth: 2,
				barPercentage: 1.0,
				categoryPercentage: 100,
			},
			{
				label: "Locally",
				data: labels.map(() => Math.round((sourcingCounts.locally / sourcingCountsTotal) * 100)),
				backgroundColor: scaleColoursThree[0],
				stack: 'Stack 0',
				borderColor: '#FFFFFF',
				borderWidth: 2,
				barPercentage: 1.0,
				categoryPercentage: 100,
			},
		],
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		layout: {
            padding: 0
        },
		scales: {
			x: {
				display: false,
				//offset: false,
			},
			y: {
				display: false,
				offset: false,
			},
			
		},
		plugins: {
			legend: {
				display: false,
				position: 'bottom',
			},
			datalabels: {
				labels: {
					value: {
						color: 'black'
					}
				},
				formatter: (value) => {
					return value + '%';
				}
			},
		},
	};

	return (
		<div style={{ minHeight: 300 + 'px', minWidth: 100 + '%', }}>
			<Bar options={options} data={data} />
		</div>
	);
}

export default OverviewBarChart;