import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

function OverviewFilters(props) {

    const region = [
        { id: 1, name: "Americas" },
        { id: 2, name: "Asia" },
        { id: 3, name: "Australia" },
        { id: 4, name: "Europe" },
    ];

    const storeType = [
        { id: 1, name: "Attached Store" },
        { id: 2, name: "Detached Store" },
        { id: 3, name: "Counter" },
        { id: 4, name: "Pop up" },
    ];

    const size = [
        { id: 1, name: "All" },
        { id: 2, name: "Small (<20m2)" },
        { id: 3, name: "Medium (<50m2)" },
        { id: 4, name: "Large (>50m2)" },
    ];

    return (
        <Container>
            <Row>
                <Col className="">
                    <Stack>
                        <h6>Region</h6>
                        <DropdownButton id="dropdown-basic-button" title="Please Select">
                            {region.map((m) => (
                                <Dropdown.Item href="#/action-1" className="d-flex align-items-center justify-content-center text-center">{m.name}</Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </Stack>
                </Col>
                <Col md={3} className="">
                    <Stack>
                        <h6>Store Type</h6>
                        <DropdownButton id="dropdown-basic-button" title="Please Select">
                            {storeType.map((m) => (
                                <Dropdown.Item href="#/action-1" className="d-flex align-items-center justify-content-center text-center">{m.name}</Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </Stack>
                </Col>
                <Col md={3} className="">
                    <Stack>
                        <h6>City</h6>
                        <DropdownButton id="dropdown-basic-button" title="Please Select">
                        </DropdownButton>
                    </Stack>
                </Col>
                <Col md={3} className="">
                    <Stack>
                        <h6>Size</h6>
                        <DropdownButton id="dropdown-basic-button" title="Please Select">
                            {size.map((m) => (
                                <Dropdown.Item href="#/action-1" className="d-flex align-items-center justify-content-center text-center">{m.name}</Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </Stack>
                </Col>
            </Row>
        </Container >
    );
}

export default OverviewFilters;