import React from "react";
import Modal from "react-bootstrap/Modal";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function HelpModal(props) {
	//const [show, setShow] = useState(false);

	//const handleClose = () => setShow(false);
	//const handleShow = () => setShow(true);
	return (
		<Modal
			{...props}
			size="xl"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>Help</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Tab.Container id="left-tabs-example" defaultActiveKey="sourcing">
					<Row>
						<Col md={4}>
							<Nav variant="pills" className="flex-column">
								<Nav.Item>
									<Nav.Link eventKey="sourcing">Sourcing</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="materials">Raw Materials</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="circularity">Circularity</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="certifications">Certifications</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="scorecard">Scorecard Rating</Nav.Link>
								</Nav.Item>
							</Nav>
						</Col>
						<Col md={8} style={{
							maxHeight: 'calc(60vh)',
							minHeight: 'calc(60vh)',
							overflowY: 'auto'
						}}>
							<Tab.Content className="pt-5 pt-md-0" >
								<Tab.Pane eventKey="sourcing">
									<h5>Sourcing</h5>
									<p>
										We encourage the use of locally sourced items, where possible. Items that have been manufactured overseas e.g. furniture, lighting, fittings may still be perceived as 'highly rated' if:<br />
										<ul>
											<li>The manufacturer offers second life programs or a recycling program</li>
											<li>The item is 100% recoverable e.g. 100% Recyclable or Reusable</li>
										</ul>
									</p>
									<p>
										Locally Items that have been sourced locally within the city/providence<br />
										Regionally Items that have been sourced within the region<br />
										Internationally Items that have been sourced internationally
									</p>
								</Tab.Pane>
								<Tab.Pane eventKey="materials">
									<h5>Raw Materials</h5>
									<p>
										This section is used to capture information about where the material/item originated whether new, recycled, reused or retained.
									</p>
									<Table responsive="md">
										<thead>
											<tr>
												<th>Material grade</th>
												<th>Description</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Virgin Grade</td>
												<td>Refers to items that are new</td>
											</tr>
											<tr>
												<td>Part Recycled, part virgin grade</td>
												<td>Refer to items that have some components that are recycled material and some virgin grade e.g. an item that is newly manufactured using recycled components</td>
											</tr>
											<tr>
												<td>Recycled Material</td>
												<td>Refers to items that are made of 100% recycled material e.g. aluminium</td>
											</tr>
											<tr>
												<td>Reused Material</td>
												<td>Refers to material/items that have been reclaimed from other locations e.g. timber</td>
											</tr>
											<tr>
												<td>Recycled Material</td>
												<td>Refers to material/items that was already present in the current location e.g. plasterboard walls or ceiling</td>
											</tr>
										</tbody>
									</Table>
								</Tab.Pane>
								<Tab.Pane eventKey="circularity">
									<h5>Circularity</h5>
									<p>
										The recoverability of an item/material is required to determine the decommission rating of a location prior to the store build.
									</p>
									<Table responsive="md">
										<thead>
											<tr>
												<th>Material grade</th>
												<th>Description</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Not Recyclable</td>
												<td>The item is unable to be reused, repurposed or recycled</td>
											</tr>
											<tr>
												<td>Part Recyclable</td>
												<td>The item can be partially recycled and partially discarded</td>
											</tr>
											<tr>
												<td>Fully Recyclable</td>
												<td>The item can be recycled as a whole.  This may include an item that is disassembled/separated with all components sent for recycling</td>
											</tr>
											<tr>
												<td>Able to be reused/repurposed</td>
												<td>The item is reused or repurposed into another Aesop location (stores, counters or offices)</td>
											</tr>
											<tr>
												<td>Recycling Program</td>
												<td>The item can be provided to a recycling program and remade into a new product</td>
											</tr>
											<tr>
												<td>Takeback Scheme</td>
												<td>The item can be returned to the manufacturer to responsibly recycled or reused to make new products</td>
											</tr>
										</tbody>
									</Table>
								</Tab.Pane>
								<Tab.Pane eventKey="certifications">
									<h5>Certifications</h5>
									<p>
										Certifications are to be used to assist with procuring items from a manufacturer that is socially and environmentally responsible eg. Has the manufacturer considered the lifecycle of their product? Including ethical sourcing.
									</p>
									<p>
										There are many certifications that can be found globally, and others that are region or country specific. A product certifications database is available to explore in order to aid in the search for more sustainable materials. The database also aims to highlight the most valuable types of product certification and call out others that might be green-washed.
									</p>
								</Tab.Pane>
								<Tab.Pane eventKey="scorecard">
									<h5>Scorecard Rating</h5>
									<p>
										<em>[scorecard description and weighting needed]</em>
									</p>
									<Table responsive="md">
										<thead>
											<tr>
												<th>Rating</th>
												<th>Range</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Low Rating</td>
												<td>0-49</td>
											</tr>
											<tr>
												<td>Medium Rating</td>
												<td>50-69</td>
											</tr>
											<tr>
												<td>High Rating</td>
												<td>70+</td>
											</tr>
										</tbody>
									</Table>
								</Tab.Pane>
							</Tab.Content>
						</Col>
					</Row>
				</Tab.Container>

			</Modal.Body>
{/* 			<Modal.Footer>

			</Modal.Footer> */}
		</Modal>
	);
}

export default HelpModal;
