import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment'
import Header from "./Header";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { setStoreId } from '../store/actions';
import { makeStorePathView } from './ProjectSummaryPage'
import { useTable, useSortBy } from 'react-table'
import { CSVLink } from "react-csv"
import { Store, loadAllStores } from './AdminStoresPage'
import { formatScore, formatGhge } from '../HelperFunctions'
import { AccessControl } from "../AccessControl";

function SortTable({ columns, data }) {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user)
	const userId = useSelector((state) => state.userId);
	const history = useHistory();

	function handleClickSubmit(list) {
		dispatch(setStoreId(list.id)) // is this needed still?
		const path = makeStorePathView(list.id)
		history.push(path);
	}

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable(
		{
			columns,
			data,
		},
		useSortBy
	)

	// We don't want to render all 2000 rows for this example, so cap
	// it at 20 for this use case
	const firstPageRows = rows.slice(0, 200)

	return (
		<>
			<Table {...getTableProps()} className="table-borderless" striped hover>
				<thead>
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => (
								// Add the sorting props to control sorting. For this example
								// we can add them into the header props
								<th className="text-center" {...column.getHeaderProps(column.getSortByToggleProps())}>
									<span>
										<small className="pe-2">
											{column.isSorted
												? column.isSortedDesc
													? <i className="bi bi-sort-alpha-up-alt"></i>
													: <i className="bi bi-sort-alpha-up"></i>
												: <i className="text-gray-300 bi bi-arrow-down-up"></i>}
										</small>
										<br></br>
									</span>
									<span>
										<small className="">
											{column.render('Header')}
											{/* Add a sort direction indicator */}
										</small>
									</span>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{firstPageRows.map(
						(row, i) => {
							prepareRow(row);
							const store = row.original

							return (
								<tr name={row.id} key={row.id} className={`${row.values.storeStatus === Store.Status.REVIEWED ? "table-success" : row.values.storeStatus === Store.Status.AWAITING_REVIEW ? "table-danger" : row.values.storeStatus === Store.Status.DRAFT ? "table-warning" : ""} align-middle`} {...row.getRowProps()}>
									{row.cells.map((cell, cellIndex) => {
										if (cell.column.id === "storeName") {
											return (
												<td key={`cell-${cellIndex}`} className="text-start" {...cell.getCellProps()}>{cell.render('Cell')}</td>
											)
										}
										if (cell.column.id === "openingDate") {
											//console.log(moment(cell.value).year().toString())
											return (
												<td key={`cell-${cellIndex}`} className="text-center" {...cell.getCellProps()}>{moment(cell.value).year().toString()}</td>
											)
										}
										if (cell.column.id === "totalScore") {
											return (
												<td key={`cell-${cellIndex}`} className="text-center" {...cell.getCellProps()}>{formatScore(cell.value)}</td>
											)
										}
										if (cell.column.id === "GHGEmissions") {
											return (
												<td key={`cell-${cellIndex}`} className="text-center" {...cell.getCellProps()}>{formatGhge(cell.value)}</td>
											)
										}
										if (cell.column.id === "reviewRequired") {
											if (store.reviewRequired === 1) {
												return (
													<td key={`cell-${cellIndex}`} className="text-center"><i className="bi bi-exclamation-circle"></i></td>
												)
											}
											else {
												return (
													<td key={`cell-${cellIndex}`} {...cell.getCellProps()}>{cell.render('Cell')}</td>
												)
											}
										}
										if (cell.column.id === "viewedit") {
											//console.log(cell)
											if (AccessControl.storeCanEdit(user, store)) {
												// user can edit
												return (
													<td key={`cell-${cellIndex}`} {...cell.getCellProps()}>
														<Button className="btn-sm w-100" variant="outline-dark" id={store.id} onClick={() => handleClickSubmit(store)}>
															Edit
														</Button>
													</td>
												)
											} else {
												// user can't edit, view or review
												return (
													<td key={`cell-${cellIndex}`} {...cell.getCellProps()}>
														<Button className="btn-sm w-100" variant="outline-dark mx-auto" id={store.id} onClick={() => handleClickSubmit(store)}>
															{AccessControl.getButtonTextAllStoresPage(user, store)}
														</Button>
													</td>
												)
											}

										}
										else {
											return (
												<td key={`cell-${cellIndex}`} className="text-center" {...cell.getCellProps()}>{cell.render('Cell')}</td>
											)
										}
									})}
								</tr>
							)
						}
					)}
				</tbody>
			</Table>
			<br />
			{/* <div>Showing the first 200 results of {rows.length} rows</div> */}
		</>
	)
}

function ProjectsOverview(props) {
	const [projectList, setProjectList] = useState([]);
	const [csvProjects, setCsvProjects] = useState([]);

	const user = useSelector((state) => state.user);

	useEffect(() => {
		loadAllStores((stores) => {
			console.log(stores)
			setProjectList(stores)
			setCsvProjects(stores)
		})
	}, []);


	//Store Name, Year of opening, Country, Region,Status (Concept, As-built, Decommissioned), Type (Store, Counter), Score (0dp), GHG Emissions(0dp) and Review Status (Draft, Reviewed)
	const columns = React.useMemo(
		() => [
			{
				Header: 'Store Name',
				accessor: 'storeName',
			},
			{
				Header: 'Year of Opening',
				accessor: 'openingDate',
			},
			{
				Header: 'Country',
				accessor: 'country',
			},
			{
				Header: 'Region',
				accessor: 'region',
			},
			{
				Header: 'Scorecard Type',
				accessor: 'scorecardType',
			},
			{
				Header: 'Location Type',
				accessor: 'locationType',
			},
			{
				Header: 'Circularity Score',
				accessor: 'totalScore',
			},
			{
				Header: 'GHG Emissions (kgCO2e/sqm)',
				accessor: 'GHGEmissions',
			},
			{
				Header: 'Store Status',
				accessor: 'storeStatus',
			},
			{
				Header: 'Review Required',
				accessor: 'reviewRequired',
			},
			{
				Header: 'Actions',
				accessor: 'viewedit',
			},
		],
		[]
	)

	return (
		<Container>
			<Row className="row h-10">
				<Header />
			</Row>
			<Row className="row h-90 p-2">
				<SortTable columns={columns} data={projectList} />
			</Row>
			<Row hidden={AccessControl.isAdmin(user) !== true}>
				<Col xs={12} md={12} className="text-end d-grid gap-2">
					<CSVLink
						data={csvProjects}
						filename={"Artemis-All-Projects-Export.csv"}
						//className="my-1 btn btn-sm btn-outline-primary"
						className="my-1 link"
						target="_blank"
					>
						Download Projects List (CSV)
					</CSVLink>
				</Col>
			</Row>
		</Container>
	);
}

export default ProjectsOverview;