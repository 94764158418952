import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from 'react-redux';
//import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Header from "./Header";
import Table from 'react-bootstrap/Table';
import './../index.css';

function TableGetAllStores(props) {
	const backendUrl = useSelector((state) => state.backendUrl);
	const [storeList, setStoreList] = useState(null);
	const storeId = useSelector((state) => state.storeId);
	//const storeId = 0;

	var parentPage = 1;

	useEffect(() => {
		axios.get(`${backendUrl}/stores/`)
			.then(function (response) {
				console.log(response);
				setStoreList(response.data)
			})
			.catch(function (error) {
				console.log(error);
			});

	}, []);

	return (
		<Container>
				<Table hover responsive size="sm">
					<thead>
						<tr>
							<th><small>#</small></th>
							<th><small>Store Name</small></th>
							<th><small>Scorecard Type</small></th>
							<th><small>Country</small></th>
							<th><small>Region</small></th>
							<th><small>Location Type</small></th>
							<th><small>Total Score</small></th>
							<th><small>View</small></th>
						</tr>
					</thead>
					<tbody>
						{storeList ? (storeList.map((store) => (
							<tr>
								<td><small>{store.id}</small></td>
								<td><small>{store.storeName}</small></td>
								<td><small>{store.scoreCardType}</small></td>
								<td><small>{store.country}</small></td>
								<td><small>{store.region}</small></td>
								<td><small>{store.locationType}</small></td>
								<td><small>{store.totalScore}</small></td>
								<td><small><a href="#">View/Edit</a></small></td>
								{parentPage == 1 &&
									<p></p>
								}
							</tr>
						))) : ""}
					</tbody>
				</Table >
		</Container >

	);
}

export default TableGetAllStores;
