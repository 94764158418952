import { Store } from "./components/AdminStoresPage"

export const UserRole = {
	Admin: "ADMIN",
	Viewer: "VIEWER",
	Designer: "DESIGNER",
	Reviewer: "REVIEWER",
}

/** To centralise logic for which sections of the app and which actions a user has access to */
export class AccessControl {

	// ----------------------------- Role based permissions ---------------------------------------------

	static isAdmin (user) {
		return user.role === UserRole.Admin
	}

	static isDesigner (user) {
		return user.role === UserRole.Designer
	}

	static isDesignerOrAdmin (user) {
		return user.role === UserRole.Designer || user.role === UserRole.Admin
	}

	static isDesignerOrReviewerOrAdmin (user) {
		return user.role === UserRole.Designer || user.role === UserRole.Reviewer || user.role === UserRole.Admin
	}

	static isReviewer (user) {
		return user.role === UserRole.Reviewer
	}

	static isReviewerOrAdmin (user) {
		return user.role === UserRole.Reviewer || user.role === UserRole.Admin
	}

	// ----------------------------- UI ---------------------------------------------

	static getButtonTextAllStoresPage (user, store) {
		if (AccessControl.storeCanReview(user) && store.storeStatus === Store.Status.AWAITING_REVIEW) {
			return "Review"
		} else {
			return "View"
		}
	}

	// ----------------------------- Action based permissions ---------------------------------------------


	static storeCanView (user) {
		return true // everyone with an account can view the store
	}

	static storeCanCreate (user) {
		return AccessControl.isDesigner(user)
	}

	static storeCanEdit (user, store) {
		const isOwner =	store.creatorId === user.id
		return AccessControl.isAdmin(user) || AccessControl.isDesigner(user) && isOwner
	}

	static storeCanAddMaterial (user, store) {
		return AccessControl.storeCanEdit(user, store) && store.scorecardType !== Store.ScorecardType.DECOMMISSIONED
	}

	static storeCanSubmitForReview (user) {
		return AccessControl.isDesignerOrAdmin(user)
	}

	static storeCanViewReviewInfo (user) {
		return AccessControl.isDesignerOrReviewerOrAdmin(user)
	}

	static storeCanReview (user) {
		return AccessControl.isReviewerOrAdmin(user)
	}

	static storeCanMarkAsDraft (user) {
		return AccessControl.isDesignerOrAdmin(user)
	}

	static storeCanMarkAsReviewed (user) {
		return AccessControl.isReviewerOrAdmin(user)
	}

	static storeCanProgressToAsBuilt (user, store) {
		// Only the concept designer can progress store
		const isCurrentDesigner = Store.getDesignerId(Store.ScorecardType.CONCEPT, store) === user.id
		return AccessControl.isAdmin(user) || (AccessControl.isDesigner(user) && isCurrentDesigner)
	}

	static storeCanProgressToDecommission (user, store) {
		// Only the as-built designer can progress store
		const isCurrentDesigner = Store.getDesignerId(Store.ScorecardType.AS_BUILT, store) === user.id
		return AccessControl.isAdmin(user) || (AccessControl.isDesigner(user) && isCurrentDesigner)
	}

	static storeCanChangeDesigner (user) {
		return AccessControl.isDesigner(user)
	}

	static storeCanDownloadData (user) {
		return AccessControl.isDesignerOrAdmin(user)
	}
	
	// static materialInstanceCanView (user) {
	// 	return AccessControl.isDesignerOrAdmin(user)
	// }

	static materialInstanceCanComment (user) {
		return AccessControl.isReviewer(user)
	}

	static materialInstanceCanViewComments (user) {
		return AccessControl.isDesignerOrReviewerOrAdmin(user)
	}
}