import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from "axios";
import Header from "./Header";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import OverviewRegionTable from "./OverviewRegionTable";
import OverviewTopDesignersStoresTables from "./OverviewTopDesignersStoresTables";
import OverviewDonutChart from "./OverviewDonutChart";
import OverviewBarChart from "./OverviewBarChart";
import OverviewFilters from "./OverviewFilters";
import OverviewTreemapChart from "./OverviewTreemapChart";
import ChartFilledSingleCircle from "./ChartFilledSingleCircle";
import ChartFilledSingleCircleGHG from "./ChartFilledSingleCircleGHG";
import ChartsGlobalStores from "./ChartsGlobalStores";
import { scaleColoursFiveText, scaleColoursSevenText, scaleColoursThreeText } from './GlobalVars'
import './../index.css';
import { formatScore } from '../HelperFunctions'
import { AccessControl } from "../AccessControl";

const renderMaterialScores = (materials) => {
	return (
		<Table borderless striped size="sm" className="">
			<thead>
				<tr>
					<th>Name</th>
					<th className="text-center">Embodied GHGE</th>
					<th className="text-center">Total Uses</th>
				</tr>
			</thead>
			<tbody>
				{materials ? (materials.map((material) => (
					<tr key={material.id} name={material.id}>
						<td>{material.material}</td>
						{/* <td className="text-center">{material.subCategory}</td> */}
						<td className="text-center">{material.embodiedGHGE}</td>
						<td className="text-center">{material.count}</td>
					</tr>
				))) :
					<tr>
						<td></td>
						<td></td>
					</tr>
				}
			</tbody>
		</Table>
	)
}

function UserOverview(props) {
	const backendUrl = useSelector((state) => state.backendUrl);
	const user = useSelector((state) => state.user)
	const userId = useSelector((state) => state.userId)

	const [materialsLow, setMaterialsLow] = useState(null);
	const [materialsHigh, setMaterialsHigh] = useState(null);
	const [userProjects, setuserProjects] = useState(null);
	const [userMaterials, setUserMaterials] = useState(null);
	const [userScoreAverage, setUserScoreAverage] = useState({});
	const [userScoreHigh, setUserScoreHigh] = useState({});
	const [userGHGAverage, setUserGHGAverage] = useState({});

	useEffect(() => {

		//get global material high/low
		axios.get(`${backendUrl}/materialScores/9`)
			.then(function (response) {
				var tempHigh = response.data.highestRatedMaterials
				var tempLow = response.data.lowestRatedMaterials
				/* 				while (tempHigh.length < 9) {
									tempHigh.push({itemType : "-"})
								};
				
								while (tempLow.length < 9) {
									tempHigh.push({itemType : "-"})
								}; */

				setMaterialsHigh(tempHigh);
				setMaterialsLow(tempLow);
			})
			.catch(function (error) {
				console.log(error);
			});

		//get user materials
		axios.get(`${backendUrl}/topMaterialsByArchitect/${userId}`)
			.then(function (response) {
				setUserMaterials(response.data);
			})
			.catch(function (error) {
				console.log(error);
			});

		//get user stores
		axios.get(`${backendUrl}/storesByArchitectSummary/${userId}`)
			.then(function (response) {
				setuserProjects(response.data.sort((a, b) => (a.totalScore < b.totalScore) ? 1 : -1).slice(0, 5));
			})
			.catch(function (error) {
				console.log(error);
			});

		//get user stores scores
		axios.get(`${backendUrl}/scoresByArchitect/${userId}`)
			.then(function (response) {
				//set user highest
				var highestVal = {};
				highestVal.score = Math.round((response.data.highestStoreScore) * 100) / 100;
				setUserScoreHigh(highestVal);

				//set user average
				var averageVal = {};
				averageVal.score = Math.round((response.data.averageStoreScore) * 100) / 100;
				setUserScoreAverage(averageVal);

				//set user GHG average
				var ghgAverageVal = {};
				ghgAverageVal.score = Math.round((response.data.averageGHGEmissions) * 100) / 100;
				setUserGHGAverage(ghgAverageVal);

			})
			.catch(function (error) {
				console.log(error);
			});

	}, []);

	return (
		<Container>
			<Row>
				<Header />
			</Row>

			<article>
				{AccessControl.isDesigner(user) &&
					<Container className="m-0 p-0">
						<Row className="py-3">
							<Col>
								<h4 className="border-bottom border-dark">My Performance</h4>
							</Col>
						</Row>
						<Row className="">
							<Col xs={4} md={4}>
								<Row className="p-1">
									<Col className="d-flex justify-content-center align-items-center">
										<div className="fadeText2s text-center" style={{ position: "absolute" }}>
											<h6 className="mb-0">My Average<br />
												Circularity Score</h6>
											<h2 className="mt-0 text-center">{formatScore(userScoreAverage.score)}</h2>
										</div>
										<ChartFilledSingleCircle
											percentage={userScoreAverage.score}
											label="Embodied"
											delay={1000}
										/>
									</Col>
								</Row>
							</Col>
							<Col xs={4} md={4}>
								<Row className="p-1">
									<Col className="d-flex justify-content-center align-items-center">
										<div className="fadeText2s text-center" style={{ position: "absolute" }}>
											<h6>My Highest<br />
												Circularity Score</h6>
											<h2 className="mt-0 text-center">{formatScore(userScoreHigh.score)}</h2>
										</div>
										<ChartFilledSingleCircle
											percentage={userScoreHigh.score}
											label="Embodied"
											delay={1000}
										/>
									</Col>
								</Row>
							</Col>
							<Col xs={4} md={4}>
								<ChartFilledSingleCircleGHG
									locationType="Store"
									//ghgValue={globalAverageGHGEmissions}
									ghgValue={userGHGAverage.score}
									label=""
									delay={1000}
									topText={<span>My GHG Emissions<br />Impact Average</span>}
									bottomText=" "
								/>
{/* 
								<Row className="p-1">
									<Col className="d-flex justify-content-center align-items-center">
										<div className="fadeText2s text-center" style={{ position: "absolute" }}>
											<h6>My GHG Emissions<br />
												Impact Average</h6>
											<h2 className="mt-0 text-center">{formatScore(userGHGAverage.score)}</h2>
										</div>
										<ChartFilledSingleCircleGHG
											percentage={userGHGAverage.score}
											label="Embodied"
											delay={1000}
										/>
									</Col>
								</Row>
								<Row className="pt-2">
									<Col>
										<p className="text-center text-muted"><small>(kgCO2e per sqm)</small></p>
									</Col>
								</Row> */}
							</Col>
						</Row>
					</Container>
				}
			</article>

			<article>
				<Row className="py-3">
					<Col>
						<h4 className="border-bottom border-dark">Global Sustainability</h4>
					</Col>
				</Row>
			</article>

			<article>
				<Row className="py-3">
					<Col md={12}>
						<Row className="py-3">
							<ChartsGlobalStores />
						</Row>
					</Col>
				</Row>
			</article>

			<article>
				{AccessControl.isReviewer(user) &&
					<OverviewTopDesignersStoresTables />
				}
			</article>

			<article>
				<Row className="py-3">
					<Col>
						<OverviewRegionTable />
					</Col>
				</Row>
			</article>

			<article>
				<Row className="py-3 h-100 gx-5">
					<Col xs={12} md={4}>
						<Row>
							<Col className="text-center pt-3">
								<h6>Raw Materials</h6>
							</Col>
						</Row>
						<Row>
							<Col className="h-100">
								<OverviewTreemapChart />
							</Col>
						</Row>
						<Row>
							<Col className="text-center pt-2">
								<ul className="list-inline">
									<li className="list-inline-item"><small><i className={`${scaleColoursFiveText[0]} bi bi-square-fill pe-2`} ></i>Retained</small></li>
									<li className="list-inline-item"><small><i className={`${scaleColoursFiveText[1]} bi bi-square-fill pe-2`} ></i>Reused</small></li>
									<li className="list-inline-item"><small><i className={`${scaleColoursFiveText[2]} bi bi-square-fill pe-2`} ></i>Recycled</small></li>
									<li className="list-inline-item"><small><i className={`${scaleColoursFiveText[3]} bi bi-square-fill pe-2`} ></i>Part recycled-Part virgin</small></li>
									<li className="list-inline-item"><small><i className={`${scaleColoursFiveText[4]} bi bi-square-fill pe-2`} ></i>Virgin Grade</small></li>
								</ul>
							</Col>
						</Row>
					</Col>
					<Col xs={12} md={4}>
						<Row>
							<Col className="text-center pt-3">
								<h6>End of Life</h6>
							</Col>
						</Row>
						<Row>
							<Col className="h-100">
								<OverviewDonutChart />
							</Col>
						</Row>
						<Row>
							<Col className="text-center pt-2">
								<ul className="list-inline">
									<li className="list-inline-item"><small><i className={`${scaleColoursSevenText[0]} bi bi-square-fill pe-2`} ></i>Fully recyclable</small></li>
									<li className="list-inline-item"><small><i className={`${scaleColoursSevenText[1]} bi bi-square-fill pe-2`} ></i>Part recyclable</small></li>
									<li className="list-inline-item"><small><i className={`${scaleColoursSevenText[2]} bi bi-square-fill pe-2`} ></i>Recycling program</small></li>
									<li className="list-inline-item"><small><i className={`${scaleColoursSevenText[3]} bi bi-square-fill pe-2`} ></i>Finish to remain after decommission</small></li>
									<li className="list-inline-item"><small><i className={`${scaleColoursSevenText[4]} bi bi-square-fill pe-2`} ></i>Able to be reused/repurposed</small></li>
									<li className="list-inline-item"><small><i className={`${scaleColoursSevenText[5]} bi bi-square-fill pe-2`} ></i>Takeback Scheme</small></li>
									<li className="list-inline-item"><small><i className={`${scaleColoursSevenText[6]} bi bi-square-fill pe-2`} ></i>Not recyclable</small></li>
								</ul>
							</Col>
						</Row>
					</Col>
					<Col xs={12} md={4}>
						<Row>
							<Col className="text-center pt-3">
								<h6>Manufacturer Sourcing</h6>
							</Col>
						</Row>
						<Row>
							<Col className="w-100 h-100">
								<OverviewBarChart />
							</Col>
						</Row>
						<Row>
							<Col className="text-center pt-2">
								<ul className="list-inline">
									<li className="list-inline-item"><small><i className={`${scaleColoursThreeText[0]} bi bi-square-fill pe-2`} ></i>Locally</small></li>
									<li className="list-inline-item"><small><i className={`${scaleColoursThreeText[1]} bi bi-square-fill pe-2`} ></i>Regionally</small></li>
									<li className="list-inline-item"><small><i className={`${scaleColoursThreeText[2]} bi bi-square-fill pe-2`} ></i>Globally</small></li>
								</ul>
							</Col>
						</Row>
					</Col>
				</Row>
			</article>

			<article>
				<Row className="gx-5 py-3">
					<Col md={6} className="py-3">
						<Row className="border border-black">
							<Col>
								<Row className="py-3">
									<Col>
										<h6 className="border-bottom border-dark">Highest GHG Emission Impacts</h6>
									</Col>
								</Row>
								{renderMaterialScores(materialsHigh)}
							</Col>
						</Row>
					</Col>

					<Col md={6} className="py-3">
						<Row className="border border-black">
							<Col>
								<Row className="py-3">
									<Col>
										<h6 className="border-bottom border-dark">Lowest GHG Emission Impacts</h6>
									</Col>
								</Row>
								{renderMaterialScores(materialsLow)}
							</Col>
						</Row>
					</Col>
				</Row>
			</article>

		</Container>
	);
}

export default UserOverview;