import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import dayjs from 'dayjs';
import './../index.css';
import { loadUsers } from './AdminUsersPage'
import { AccessControl } from "../AccessControl";

function ProjectsAddMaterialCommentModal(props) {
	const user = useSelector((state) => state.user);
	const backendUrl = useSelector((state) => state.backendUrl);
	const [userList, setUserList] = useState([]);

	useEffect(() => {
		loadUsers(users => {
			// console.log(users)
			setUserList(...userList, users)
		})

	}, []);

	const getAuthor = (reviewerId) => {
		const index = userList.findIndex(user => user.id === reviewerId);
		if (index > -1) {
			return userList[index].email
		}
		else {
			return 'not authored'
		}
	}

	let commentData = {
		materialInstanceId: Number(props.materialId),
		reviewerId: Number(props.userId),
		content: null,
	}


	const onChange = (e) => {
		commentData[e.target.name] = e.target.value;
	}

	const handleMaterialCommentSubmit = (event) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		axios.post(`${backendUrl}/comment`, commentData)
			.then(function (response) {
				console.log(response);
			})
			.then(() =>{
				props.onHide();
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<Modal
			{...props}
			size="lg"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>{AccessControl.isReviewer(user) ? "Create new comment" : "View comments"}</Modal.Title>
			</Modal.Header>
			{props.selectMaterialCommentModal && props.selectMaterialCommentModal.map((m) =>
				<Modal.Body>
					<Row>
						<Table responsive>
							<tbody>
								<tr>
									<td className="text-muted">Item Type</td>
									<td>{m.itemType}</td>
									<td className="text-muted">Description</td>
									<td>{m.materialDescription}</td>
								</tr>
								<tr>
									<td className="text-muted">Category</td>
									<td>{m.category}</td>
									<td className="text-muted">Sub-category</td>
									<td>{m.subCategory}</td>
								</tr>
								<tr>
									<td className="text-muted">Certification</td>
									<td>{m.productCertification}</td>
									<td className="text-muted">Total Score</td>
									<td>{m.totalScore}</td>
								</tr>
							</tbody>
						</Table>
					</Row>

					<div>
						{/* if there are no comments, show the message, if there are, render the table */}
						{props.comments.length === 0 && <p>No comments have been made on this material yet...</p>}
						{props.comments.length > 0 &&
							<Table className="table-borderless">
								<thead>
									<tr>
										<th>Comment</th>
										<th>Author</th>
										<th className="d-flex align-items-center justify-content-end">Date</th>
									</tr>
								</thead>
								<tbody>
									{props.comments.map((m) => (
										<tr>
											<td>{m.content}</td>
											<td>{getAuthor(m.reviewerId)}</td>
											<td className="d-flex align-items-center justify-content-end">{dayjs(`${m.commentDate}`).format('HH:mm:ss YYYY-MM-DD')}</td>
										</tr>
									))}
								</tbody>
							</Table>
						}
					</div>

					{AccessControl.materialInstanceCanComment(user) ? (
						<Form className="col-12" autoComplete="off" onSubmit={handleMaterialCommentSubmit}>
							<Form.Group className="mb-md-12 d-flex align-items-center mb-4">
								<Col md={12}>
									<Form.Label>
										New Comment
							</Form.Label>
									<Form.Control
										required
										id={m.id}
										name="content"
										as="textarea"
										rows={3}
										placeholder="Type here..."
										onChange={onChange}
									/>
								</Col>
							</Form.Group>
							<Container>
								<Row className="d-flex align-items-center justify-content-center">
									<Button
										type="submit"
										variant="outline-dark"
										className="ps-2 w-50"
									// onClick={props.onHide}
									>
										Save
								</Button>
								</Row>
								<Row className="d-flex align-items-center justify-content-center pt-4">
									<Button
										size="sm"
										type="button"
										variant="outline-danger"
										onClick={props.onHide}
										className="pe-2 w-50"
									>
										Cancel
									</Button>
								</Row>
							</Container>
						</Form>
					) : undefined}
				</Modal.Body>
			)}
		</Modal >
	);
}

export default ProjectsAddMaterialCommentModal;