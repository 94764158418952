import React, { useEffect, useState } from "react"
import { useSelector } from 'react-redux'
import { useParams, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { CSVLink, CSVDownload } from "react-csv";
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Store, loadStore, updateStore } from './AdminStoresPage'
import { AccessControl } from "../AccessControl"
import { config } from "../config"
import ProjectsAddProjectCommentModal from "./ProjectsAddProjectCommentModal"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Header from "./Header"
import Spinner from 'react-bootstrap/Spinner'
import { StoreNavBar } from "./StoreNavBar"
import ProjectSummaryPage from './ProjectSummaryPage'
import ProjectCreateNew from "./ProjectCreateNew"
import ProjectsAddMaterial from "./ProjectsAddMaterial"
import ProjectReviewPage from "./ProjectReviewPage"
import ProjectPhasePage from "./ProjectPhasePage"
import { StoreLifecycleModal } from "./StoreLifecycleModal"
import { getMaterialInstancesForStore } from "./AdminMaterialsPage"

/** A container component to render the store navbar and child pages (tab content) */
export const StoreContainerPage = () => {

	const { storeId } = useParams()
	const [store, setStore] = useState(undefined)
	const [pageName, setPageName] = useState("view")

	const [csvProject, setCsvProject] = useState([])
	const [projectFileName, setProjectFilename] = useState(null)

	const [csvMaterialsCurrent, setCsvMaterialsCurrent] = useState([])
	const [csvMaterialsAll, setCsvMaterialsAll] = useState([])

	const user = useSelector((state) => state.user)
	const storeLifecycleModalInfo = useSelector((state) => state.storeLifecycleModalInfo)
	const history = useHistory()
	const [selectProjectCommentModal, setSelectProjectCommentModal] = useState(false)

	const location = useLocation()
	const routeMatch = useRouteMatch("/stores/:storeId")

	useEffect(() => {
		// console.log('StoreContainerPage.useEffect storeId')
		loadStore(storeId, store => {
			setStore(store)

			//parse data into CSV
			setProjectFilename(store.storeName)
			var tempString = JSON.stringify(store)

			var tempReparsed = "[" + tempString + "]"

			// console.log(JSON.parse(tempReparsed))
			setCsvProject(JSON.parse(tempReparsed))

			getMaterialInstancesForStore(storeId, "", setCsvMaterialsCurrent)
			getMaterialInstancesForStore(storeId, "ALL", setCsvMaterialsAll)
		})
	}, [ storeId ])

	useEffect(() => {
		// console.log('StoreContainerPage.useEffect pageName')
		// console.log("location", location)
		const parts = location.pathname.split('/')
		const pageNameNew = parts[parts.length - 1]
		setPageName(pageNameNew)
		// console.log('pageNameNew', pageNameNew)
	}, [ location ])

	const renderPage = () => {
		if (pageName === 'view') {
			return <ProjectSummaryPage store={store} setStore={setStore} user={user} />
		} else if (pageName === 'edit') {
			return <ProjectCreateNew store={store} setStore={setStore} user={user} />
		} else if (pageName === 'materials') {
			return <ProjectsAddMaterial store={store} setStore={setStore} user={user} />
		} else if (pageName === 'review') {
			return <ProjectReviewPage store={store} setStore={setStore} user={user} />
		} else if (pageName === 'lifecycle') {
			return <ProjectPhasePage store={store} setStore={setStore} user={user} />
		}
	}

	const getContent = () => {

		if (!store) {
			return (
				<Row>
					<Col>
						<div>
							<Spinner animation="grow" />
							<p>Loading</p>
						</div>
					</Col>
				</Row>
			)
		} else {
			return (
				<>
					<h1>{store.storeName}</h1>
					<StoreNavBar store={store} setStore={setStore} pageName={pageName} setPageName={setPageName} />
					{renderPage()}
				</>
			)
		}
	}

	return (
		<Container>
			<Row>
				<Header />
			</Row>

			<StoreLifecycleModal show={storeLifecycleModalInfo.show} info={storeLifecycleModalInfo} store={store} />
			
			{getContent()}

						
			{/* Download using the store navbar dropdown instead */}
			<Row hidden={true}>
				<Col xs={12} md={6} className="d-grid gap-2">
					<CSVLink
						id="download-store-info-csv"
						data={csvProject}
						filename={"Artemis-" + projectFileName + "-Details-Export.csv"}
						className="my-1 btn btn btn-sm btn-outline-primary"
						target="_blank"
					>
						Download Project Data (CSV)
					</CSVLink>
				</Col>
				<Col xs={12} md={6} className="d-grid gap-2">
					{csvMaterialsCurrent && Object.keys(csvMaterialsCurrent).length &&
						//don't show material download if materials list is null
						//check function for 0 output on render
						<CSVLink
							id="download-store-materials-csv-current"
							//disabled={!csvMaterials}
							data={csvMaterialsCurrent}
							filename={"Artemis-" + projectFileName + "-Materials-Current-Export.csv"}
							className="my-1 btn btn-sm btn-outline-primary"
							target="_blank"
						>
							Download Material Data - Current (CSV)
						</CSVLink>

					}
				</Col>

				<Col xs={12} md={6} className="d-grid gap-2">
					{csvMaterialsAll && Object.keys(csvMaterialsAll).length &&
						//don't show material download if materials list is null
						//check function for 0 output on render
						<CSVLink
							id="download-store-materials-csv-all"
							//disabled={!csvMaterials}
							data={csvMaterialsAll}
							filename={"Artemis-" + projectFileName + "-Materials-All-Export.csv"}
							className="my-1 btn btn-sm btn-outline-primary"
							target="_blank"
						>
							Download Material Data - All (CSV)
						</CSVLink>

					}
				</Col>
			</Row>
		</Container>
	)
}