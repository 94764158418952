import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from 'react-redux';
//import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import './../index.css';

function TableCertifications(props) {
	const backendUrl = useSelector((state) => state.backendUrl);
	const [certsList, setCertsList] = useState(null);

	var parentPage = 1;

	useEffect(() => {
		axios.get(`${backendUrl}/certifications`)
			.then(function (response) {
				console.log(response);
				setCertsList(response.data)
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	return (
		<Container>
				<Table hover responsive size="sm">
					<thead>
						<tr>
							<th><small>#</small></th>
							<th><small>ecoLabel</small></th>
							<th><small>description</small></th>
							<th><small>materialCategory</small></th>
							<th><small>region</small></th>
							<th><small>country</small></th>
							<th><small>value</small></th>
							<th><small>prompt</small></th>
							<th><small>link</small></th>
						</tr>
					</thead>
					<tbody>
						{certsList ? (certsList.map((cert) => (
							<tr>
								<td><small>{cert.id}</small></td>
								<td><small>{cert.ecoLabel}</small></td>
								<td><small>{cert.description}</small></td>
								<td><small>{cert.materialCategory}</small></td>
								<td><small>{cert.region}</small></td>
								<td><small>{cert.country}</small></td>
								<td><small>{cert.value}</small></td>
								<td><small>{cert.prompt}</small></td>
								<td><small><a href={cert.link}>Link</a></small></td>
								{parentPage == 1 &&
									<td><small><a href="#">Select this Certification</a></small></td>
								}
							</tr>
						))) : ""}
					</tbody>
				</Table >
		</Container >

	);
}

export default TableCertifications;
