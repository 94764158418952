import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Accordion from 'react-bootstrap/Accordion'
import ProjectsAddProjectCommentModal from "./ProjectsAddProjectCommentModal";
import './../index.css';
import { AccessControl } from "../AccessControl";
import { Store, loadStore } from "./AdminStoresPage";
import { getMaterialInstancesForStore } from "./AdminMaterialsPage";

function ProjectInformationBrief({ storeId }) {
	const backendUrl = useSelector((state) => state.backendUrl);

	const [storeDetails, setStoreDetails] = useState({});
	const [selectProjectCommentModal, setSelectProjectCommentModal] = useState(false);

	const [archEmail, setArchEmail] = useState(null);
	const [pmEmail, setPmEmail] = useState(null);

	useEffect(() => {

		loadStore(storeId, store => {
			setStoreDetails(store);

			setArchEmail(store.architect)
			setPmEmail(store.projectManager)
		})

	}, []);

	function handleClickProjectSubmit() {
		setSelectProjectCommentModal(true);
	}

	return (
		<Container className="py-3">
			<Row>
				<Table responsive>
					<tbody>
						<tr>
							<td className="col-3 text-muted">Store name</td>
							<td className="col-3">{storeDetails.storeName}</td>
							<td className="col-3 text-muted">Region</td>
							<td className="col-3">{storeDetails.region}</td>
						</tr>
						<tr>
							<td className="text-muted">Scorecard Type</td>
							<td>{Store.getScorecardTypeTitle(storeDetails.scorecardType)}</td>
							<td className="text-muted">Country</td>
							<td>{storeDetails.country}</td>
						</tr>
						<tr>
							<td className="text-muted">Location Type</td>
							<td>{storeDetails.locationType}</td>
							<td className="text-muted">Total Sqm</td>
							<td>{storeDetails.totalSqm}</td>
						</tr>
						<tr>
							<td className="text-muted">Street Address</td>
							<td>{storeDetails.streetAddress}</td>
							<td className="text-muted">City</td>
							<td>{storeDetails.city}</td>
						</tr>
						<tr>
							<td className="text-muted">ROIC CAPEX</td>
							<td>{storeDetails.currency} {storeDetails.roicCapex}</td>
							<td className="text-muted">Retail Sqm</td>
							<td>{storeDetails.retailSqm}</td>
						</tr>
						<tr>
							<td className="text-muted">Architect</td>
							<td>{archEmail}</td>
							<td className="text-muted">Project Manager</td>
							<td>{pmEmail}</td>
						</tr>
						{/* {AccessControl.isReviewer(user) &&
							<tr>
								<td className="text-muted">Status</td>
								<td>Review required</td>
								<td colSpan={2}><a href="#" id={storeId} onClick={handleClickProjectSubmit}>Click here to review this project</a></td>
							</tr>
						}
						<tr>
							<td className="text-muted">Review Comment</td>
							<td>{storeDetails.reviewComment}</td>
						</tr> */}
					</tbody>
				</Table>
			</Row>

			<ProjectsAddProjectCommentModal
				show={selectProjectCommentModal}
				onHide={() => setSelectProjectCommentModal(false)}
				selectProjectCommentModal={selectProjectCommentModal}
			/>
		</Container>
	);
}

export default ProjectInformationBrief;